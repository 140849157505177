#default-list.labels-manager {
    background-color: green !important;
    .content {
        padding: 0;
    }
}

.content .center .content-card {
    md-content {
        padding: 0;
    }
}
