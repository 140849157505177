.launch-video {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 24px;
  width: 100%;
  max-width: 664px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    width: 100%;

    &__title {
      color: #262626;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
    }

    .md-button {
      padding: 0 !important;
      margin: 0 !important;
      display: flex;
      align-items: center;

      min-width: 0 !important;
      min-height: 0 !important;

      md-icon {
        color: #135AC0;
      }
    }
  }

  &__message {
    width: 100%;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
  }

  &__video {
    display: flex;
    align-items: center;
    justify-content: center;

    iframe {
      border-radius: 4px;
    }
  }
}
