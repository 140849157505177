.customers-import-automatic-dialog {
    width: 520px;

    .fs-16 {
        font-size: 16px;
    }

}

.customers-import-automatic-progress {
  padding: 32px !important;
}
