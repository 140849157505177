.vehicles-usage-dialog__forms-tab {
  &__main-container {
    display: grid;
    grid-gap: 24px;
    padding: 30px 22px;
    margin-bottom: 100px;

    &__form {
      h2 {
        margin-bottom: 24px;
      }

      .answered__form {
        border: 1px solid #D4DEF1;
        border-radius: 4px;

        &:not(.answered__form:nth-child(1)) {
          margin-top: 12px;
        }

        &__no-answers {
          padding: 10px 16px;
          color: #757575;
          font-size: 14px;
        }

        &__info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .icon:nth-child(1) {
            margin: 16.75px 14.25px 41.25px 18.25px;
          }

          .icon {
            md-icon {
              color: #135AC0;
            }
          }
        }

        &__name {
          flex: 1;
          flex-direction: column;
          justify-content: start;
          align-items: start;

          p {
            margin: 0;
            margin-bottom: 9px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
          }

          &__description {
            span {
              font-size: 13px;
              font-weight: 400;
              color: #757575;

              &:first-of-type {
                margin-right: 48px;
              }
            }
          }
        }

        &__button  {
          @extend .button-without-hover;

          span {
            font-size: 14px;
            color: #135AC0 !important;
            text-transform: none !important;
          }
        }

        &__answer {
          position: relative;
          display: flex;
          align-items: center;
          padding: 16px 14px;

          border: 1px solid #D4DEF1;
          background-color: #FFFFFF;
          border-radius: 4px;

          &:not(:last-of-type) {
            margin-bottom: 12px;
          }

          &__infos {
            display: flex;
            flex-direction: column;
            width: 25%;

            &__title {
              margin: 0px;
              font-size: 13px;
              font-weight: 400;
              color: #757575;
            }

            &__subtitle {
              margin: 0px;
              font-size: 14px;
              font-weight: 400;
              color: #262626;
            }
          }

          .md-button {
            position: absolute;
            top: 50%;
            right: 14px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
