.tickets-dialog {
  .slug-container {
    width: 100%;

    &__start-url {
      max-height: 100%;
      box-sizing: border-box;
      padding-top: 7px;
    }

    md-input-container {
      flex: 1 1 100%;
    }
  }

  .tickets-sidenav__block {
    padding: 10px 26px;

    div{
      align-items: center;
    }

    label {
      font-weight: 500;
      font-size: 16px;
    }

    small {
      display: block;
      color: #9e9e9e;
      font-size: 12px;
      font-weight: 500;
    }

    text-area {
      min-height: 50px;
    }

    md-select {
      margin: 0;
    }
  }

  .tickets-sidenav__block__item {
    max-height: 30px;
  }

  md-input-container.md-button-right {
    padding-right: 85px !important;

    .md-icon-button {
      position: absolute;
      top: 0px;
      margin: 0;
      right: 35px;
      left: auto;
    }
  }

  &__loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}
