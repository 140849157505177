#renewal {
    min-width: 400px;

    .grey {
        color: #9e9e9e;
    }

    .self-align-end {
        align-self: flex-end;
    }

    .fs-10 {
        font-size: 10px;
    }

    .blue {
        color: #0957c3;
    }

    .green-teal {
        color: #12b886;
    }

    .w-50-percent {
        width: 50%;
    }

    .fw-500 {
        font-weight: 500;
    }

    md-divider {
        margin: 24px 0;
    }

    md-checkbox.md-checked .md-icon {
        background-color: #0957c3;
    }

    .plan-sidenav__description {
        max-height: calc(100% - 75px);
        overflow-x: auto;
    }
}
