.routes {
  &__header {
    span:first-child {
      font-weight: 500;
      font-size: 14px;
      color: #1A1A1A;
    }
    span:last-child {
      font-weight: 600;
      font-size: 12px;
      color: #0055FF;
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: calc(100vh - 250px);

    padding: 20px;
    margin-top: 22px;
    border-radius: 8px;
    border: 1px solid #D4DEF1;

    &__addresses {
      margin-right: 16px;
      &__item {
        p {
          font-size: 14px;
          font-weight: 500;
          color: #666666;
        }

        &__body {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid #D4DEF1;
          }

          &__address {
            margin-left: 12px;
            p {
              font-size: 14px;
              font-weight: 500;
              margin: 0;
            }

            p:last-child {
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              margin: 4px 0;
            }
          }
        }
      }

      &__separator {
        width: 100%;
        height: 1px;
        background-color: #D4DEF1;
        margin: 20px 0;
      }
    }

    &__map {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: 1px solid #D4DEF1;

      #routeMap {
        border-radius: 8px;
      }
    }
  }
}
