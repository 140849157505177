md-dialog {

  &.compose-dialog {

    .customer-autocomplete-input-text {
      label.fc-input__input--with-prefix__label {
        left: 2.3% !important;
      }
    }

    .md-autocomplete-input-item input:not(.invalid) {
      border: 1px solid rgb(212, 222, 241) !important;
    }

    .address-line,
    .contact-info,
    .notes {
      white-space: normal;
    }

    max-width: 720px;
    width: 720px;

    md-list {

      md-list-item {

        .md-list-item-text {

          p {
            font-weight: 400 !important;
            font-size: 12px !important;
            max-width: 720px;
            word-break: break-all;
          }
        }
      }
    }

    md-dialog-content {
      display: block;
      position: relative;
    }

    md-toolbar {

      .title {
        font-size: 17px;
      }
    }

    md-dialog-actions {
      position: relative;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

  md-dialog {

    &.compose-dialog {
      width: 80%;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {

  md-dialog {

    &.compose-dialog {
      width: 90%;
    }
  }
}

#ticket-select-customer {
  .ticket-select-customer-item {
    padding-top: 12px;
    padding-bottom: 12px;

    p, h3 {
      margin-bottom: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
