.filter-item {

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    margin-left: 10px !important;
  }

  .ticket-on-negotiation-color {
    color: #439BE6;

    svg {
      path {
        fill: #439BE6;
      }
    }
  }
}