.module-sidenav {
  min-width: 365px;
  width: initial !important;
  max-width: initial !important;
  transition: min-width 500ms;

  .md-toolbar-tools {
    .menu-title {
        font-size: 16px;
        text-align: center;
    }
  }

  &__content, &__description {
    position: absolute;
    top: 64px;
    bottom: 75px;
    left: 0px;
    right: 0px;
    z-index: 60;
    background-color: white;
  }

  &__content {
    padding: 12px 0px;
    width: 100%;

    .no-config {
      padding: 0px 10px;
      text-align: center;
      font-size: 14px;
    }

    md-input-container {
      width: 100%;
    }

    &__switch {
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 12px;

      md-switch {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }

    &__select {

      md-select-header {
        position: relative;
        width: auto;
        display: flex;

        input[type="search"] {
          box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
          padding-left: 10.667px;
          height: 48px;
          width: 100%;
        }
      }

      md-select-footer {
        padding-left: 10.667px;
        height: 48px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__label {
      margin-left: 16px;
      font-size: 12px;
      color: rgba(0,0,0,0.54);
      -webkit-font-smoothing: antialiased;
      padding-left: 3px;
      font-weight: 500;
      opacity: 0.8;

      & + md-list-item {

        md-input-container {
          margin-top: 0 !important;
        }
      }
    }
  }

  &__description {
    padding: 12px;
    width: 100%;

    &__title {
      margin-bottom: 15px;

      h2 {
        margin: 0;
        color: rgba(0, 0, 0, 0.7);
        font-weight: 500;
      }

      .badge {

        &.paid {
          background-color: #008672;
          color: #ffffff;
        }

        &.free {
          background-color: #e8ad47;
          color: #000000;
        }
      }
    }

    ul {
      list-style: disc;
      padding-inline-start: 15px;
    }

    .module-video {

      &__container {
        position: relative;
        width: 100%;
        max-width: 335px;
        height: 185px;

        &__background {
          width: 100%;
          height: 100%;
          border-radius: 3px;
        }

        &__filter {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: #00194a;
          opacity: 0.2;
          border-radius: 3px;
        }

        &__play-button {
          position: absolute;
          left: calc(50% - 21px);
          top: calc(50% - 21px);
          width: 42px;
          height: 42px;
        }
      }
    }

    .badge {
      font-weight: 400 !important;
    }
  }

  &__button-container {
    position: absolute;
    bottom: 0px;
    padding: 0px 15px;
    width: 100%;
    height: 75px;
    border-top: thin solid #D4DEF1;
    background-color: #fff;

    .disable-button {
      display: flex;
      height: 40px;
      padding: 8px 16px;
      align-items: center;
      gap: 8px;

      color: #135AC0;
      border-radius: 4px;
      border: 1px solid #D4DEF1;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
    }
  }
}
