#notifications {
    position: relative;

    .notifications-content-bg {
        min-height: calc(100vh - 190px);
        background-color: rgb(245, 245, 245);
    }

    .content {
        height: calc(100vh - 155px);

        md-sidenav {
            // box-shadow: $whiteframe-shadow-1dp;
            padding: 0;
        }

        .w-750i {
            width: 750px !important;
        }

        .main {
            max-height: calc(100vh - 233px);
            width: 100%;
            border: thin solid rgba(0, 0, 0, 0.14);
            border-radius: 5px;

            .contacts-list {
                padding: 0;

                .md-subheader {
                    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
                    border-bottom: thin solid rgba(0, 0, 0, 0.14);

                    ._md-subheader-inner {
                        padding: 16px;
                    }

                    .contacts-count {
                        padding-left: 4px;
                    }

                    .list-title {
                        margin-right: 4px;
                    }
                }

                .link-button {
                    cursor: pointer;
                    color: #4187c3;
                }

                .contact-item {
                    right: 0;
                    left: 0;
                    width: 100%;
                    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
                    background: white;
                    transition: transform 200ms ease-out;
                    will-change: transform;

                    &.ng-leave {
                        transform: translateY(0);
                        display: none;

                        &.ng-leave-active {
                            transform: translateY(-100%);
                        }
                    }

                    &.ng-hide {
                        display: none;
                    }

                    &.ng-hide-add-active,
                    &.ng-hide-remove-active {
                        display: block;
                        transition-property: all;
                    }
                }
            }
        }
    }

}