.omie-configuration-dialog {
  max-width: 640px;

  .fs-18 {
    font-size: 18px;
  }

  .fs-16 {
    font-size: 16px;
  }
  
  .fs-17 {
    font-size: 17px;
  }

  .ul-steps {
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  .label {
    font-size: 16px;
  }

  .input {
    font-size: 16px;
  }

  md-input-container {
    margin-bottom: 0;
  }

}