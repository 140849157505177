md-dialog.form-auth-employee{
  max-width: 560px;

  .strength-meter{
    &:before {
      left: 20%;
    }
    &:after {
      right: 20%;
    }

    &:before, &:after {
      width: 20%;
    }
  }
}

.show {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.input-password-employee {
  width: 89% !important;
  font-family: 'password';
}
