.equipment-dialog {
  md-dialog-content {
    padding: 0;
  }

  md-tabs {
    min-height: 100%;
  }

  .has-images {
    height: auto;
  }

  md-tab-content.md-no-scroll {
    overflow: visible;
  }

  md-tab-content {
    overflow: visible;
  }

  .sidebar-dialog__hint {
    height: 72px;
  }

  .alert-icon {
    height: 20px;
    width: 20px;
  }

  .image-container{
    display: flex !important;
    flex-direction: row !important;
  }

  .insert-picture {
    display: flex;
    flex-direction: column;
  }

  .image-input {
    width: 120px;
    height: 120px;
  }

  .insert-picture-button {
    margin-left: 14px;
    border: solid 1px #D4DEF1;
    color: #135AC0;
    font-weight: 500;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 188px;
    height: 48px;
  }

  .sidebar-dialog__header__title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__labels {
    margin: 0;

    &__label-span {
      border-radius: 30px !important;
    }

    &__remove-label-icon {
      color: #fff !important;
      font-size: 16px !important;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: #c9c9c9 !important;
      }
    }
  }

  info-component-provider {
    & > div {
      margin-bottom: 30px;
    }
  }

  banner-component-provider {
    & > div {
      margin-bottom: 30px;
    }
  }
}

.invalid-form-badge svg {
  padding-bottom: 3px;
  color: rgb(234, 47, 101);
}

.fc-input__input {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 48px !important;
  background: none;
}

.qrcode__button-center {
  height: 100% !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 -6px !important;
}

.qrcode-container {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 12px;
  gap: 10px;
  margin: 0 !important;

  width: 399px;
  height: 48px;

  background: #FFFFFF;
  color: #135AC0;
  border: 1px solid #D4DEF1;
  border-radius: 4px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  z-index: 0;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
}

.numeric-input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.qrcode-modal-container {

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 8px;

  width: 414px;
  height: 324px;

  background: #FFFFFF;

  border: 2px dashed #D4DEF1;
  border-radius: 8px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  z-index: 1;
  margin: 0 auto 30px;

  #equipment-qrcode-download {
    width: 266px;
    height: 265px;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  p {
    margin-top: -2px;
    height: 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #9E9E9E;

    flex: none;
    order: 1;
    flex-grow: 0;
  }
}


.drop-box {
  min-height: 30px;
  height: auto;
  padding: 20px;
  background: #f8f8f8;
  border: 5px dashed #ddd;
  text-align: center;
  color: #acacac;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    border: 5px dashed rgb(196, 196, 196);
    color: #6e6e6e;

    transition: all 0.3s ease;
  }

  .name-file-text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100px;
    font-size: 10px;
    text-overflow: ellipsis;
  }
}

.equipments__wrapper-images-upload {
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: hidden;

  div {
    align-items: flex-end;
    justify-content: flex-start;
    height: 70px;
    display: flex;
    position: relative;
    width: 120px;
    margin-bottom: 10px;
    margin-left: 10px;

    .file-image-thumbnail{
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      cursor: pointer;
    }

    md-icon {
      position: absolute;
      right: 8px;
      top: 2px;
      z-index: 99;

      &:hover {
        svg path {
          fill: #bd0e0e;
        }
      }
    }

    img {
      border: thin solid #d5d5d5;
      border-radius: 5px;
      height: 60px;
      width: 100px;
      background-color: gray;
      object-fit: cover;
    }
  }
}

md-autocomplete-parent-scope {
  .alert-km-distance {
    float: right;
    font-size: 11.5px;
    color: #000;
  }
}

.download-link {
  display: none;
}

.custom-field {
  display: flex;
  flex-direction: column;

  md-input-container {
    .fix-datepicker-label {
      right: 0px !important;
      left: unset !important;
      width: 100% !important;
    }
  }

  .text-center {
    text-align: center;
  }
}

.equipment-divider {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 16px;
}

.attachments-limit {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-top: 48px;
}

.attachment-name {
  font-weight: 500;
  font-size: 16px;
}

.attachment .equipments-attachment-delete-dialog {
  width: 0;
  overflow: hidden;
  position: absolute;
  height: 130px;
  right: 0;
  z-index: 10;
  border-radius: 3px;
  background-color: #fafafa;
  border: 1px solid transparent;
  white-space: nowrap;
  transition: width 0.5s ease-in-out;

  &.visible {
    border-color: #d3ddf2;
    width: 73%;
    @media (max-width: 825px) {
      width: 100%;
    }
  }
}

.remove-equipment-attachment {
  margin-left: 7%;
}

.default-warning {
  color: red;
  display: inline-block;
  font-size: 12px;
  margin-top: 2px;
}

.attachment-time {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
}

.attachment-content-metadata{
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.mt-22 {
  margin-top: 22px;
}

.custom-field__date {
  font-size: 12px;
}
