.vehicles-usage-dialog__general-tab {
  &__input-groups {
    display: flex;
    grid-gap: 12px;

    &__title {
      color: #757575;
      font-size: 13px;
      line-height: 15px;
      margin-top: 0;
    }
  }

  &__main-container {
    display: grid;
    grid-gap: 25px;

    padding: 14px 22px;
    margin-bottom: 100px;

    h2:first-of-type {
      margin-top: 14px;
    }

    &__attachment-container {
      grid-gap: 12px;

      &__inputs {
        grid-gap: 20px;
      }
    }
  }
}
