/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/datatables-responsive/css/responsive.dataTables.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/field-control-theme.scss";
@import "core/scss/global.scss";
@import "core/scss/components/fc-backdrop.scss";
@import "core/scss/components/fc-button.scss";
@import "core/scss/components/fc-clone-button.scss";
@import "core/scss/components/fc-container.scss";
@import "core/scss/components/fc-datepicker.scss";
@import "core/scss/components/fc-fixed-tooltip.scss";
@import "core/scss/components/fc-input.scss";
@import "core/scss/components/fc-select-multiple.scss";
@import "core/scss/components/fc-skeleton.scss";
@import "core/scss/components/fc-tutorial-dialog.scss";
@import "core/scss/components/fc-warning-container.scss";
@import "core/scss/components/launch-video.scss";
@import "core/scss/components/limit-badge.scss";
@import "core/scss/components/message-infobar.scss";
@import "core/scss/components/octadesk.scss";
@import "core/scss/components/redirect-screen.scss";
@import "core/scss/components/sidebar-dialog.scss";
@import "core/scss/components/video-tutorial-dialog.scss";
@import "core/integrations/omie/configuration/omie-configuration-dialog.scss";
@import "core/directives/fc-autocomplete/template/fc-autocomplete.scss";
@import "core/directives/fc-box/template/fc-box.scss";
@import "core/directives/fc-color-picker/template/color-picker.scss";
@import "core/directives/fc-dialog-error/template/fc-dialog-error.scss";
@import "core/directives/fc-error/template/fc-error.scss";
@import "core/directives/fc-search/template/fc-search.scss";
@import "core/directives/fc-image-input/template/fc-image-input.scss";
@import "core/directives/fc-select/template/fc-select.scss";
@import "core/directives/fc-spreadsheet-reader/template/spreadsheet-reader.scss";
@import "core/directives/fc-subscription-module/template/fc-subscription-module.scss";
@import "core/directives/fc-textarea/template/fc-textarea.scss";
@import "core/directives/fc-spreadsheet-result/template/spreadsheet-result.scss";
@import "core/directives/fc-toast/template/fc-toast.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-11/template-11.scss";
@import "core/directives/ms-card/templates/template-13/template-13.scss";
@import "core/directives/ms-card/templates/template-14/template-14.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-card/templates/template-12/template-12.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/directives/ms-stepper/templates/vertical/vertical.scss";
@import "core/integrations/core/customers/dialogs/prompt/customers-prompt.scss";
@import "core/integrations/core/customers/dialogs/export/customers-export-dialog.scss";
@import "core/integrations/core/tasks/dialogs/export/conta-azul/conta-azul-tasks-export-dialog.scss";
@import "core/integrations/core/customers/dialogs/import/automatic/customers-import-automatic-dialog.scss";
@import "core/integrations/core/customers/dialogs/import/manual/customers-import-manual-dialog.scss";
@import "core/integrations/core/tasks/dialogs/export/omie/errors/omie-erros-dialog.scss";
@import "core/integrations/core/tasks/dialogs/export/omie/export/omie-tasks-export-dialog.scss";
@import "core/integrations/core/customers/dialogs/import/automatic/confirm/customers-import-automatic-confirm-dialog.scss";
@import "core/directives/fc-card/fc-card.scss";
@import "core/directives/fc-date-input/fc-date-input.scss";
@import "core/directives/fc-export-alert/fc-export-alert.scss";
@import "core/directives/fc-time-input/fc-time-input.scss";
@import "core/directives/fc-modal/fc-modal.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/fc-smart-rewrite/fc-smart-rewrite.directive.scss";
@import "core/directives/ms-info-bar/ms-info-bar.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "main/absences/absences.scss";
@import "main/audit-logs/audit-logs.scss";
@import "main/auth/auth.scss";
@import "main/bootstrap/bootstrap.scss";
@import "main/control-panel/control-panel.scss";
@import "main/customers/customers.scss";
@import "main/calendar/calendar.scss";
@import "main/employees/employees.scss";
@import "main/deadline-contracts/deadline-contracts.scss";
@import "main/deadline-violations-reasons/deadline-violations-reasons.scss";
@import "main/devices/devices.scss";
@import "main/equipment-types/equipment-types.scss";
@import "main/equipments/equipments.scss";
@import "main/exports/exports.scss";
@import "main/modules/modules.scss";
@import "main/materials/materials.scss";
@import "main/notifications/notifications.scss";
@import "main/product-types/product-types.scss";
@import "main/recurrences/recurrences.scss";
@import "main/products/products.scss";
@import "main/products-services/products-services.scss";
@import "main/quotations/quotations.scss";
@import "main/routes/routes.scss";
@import "main/services/services.scss";
@import "main/tickets/tickets.scss";
@import "main/subscription/subscription.scss";
@import "main/settings/settings.scss";
@import "main/tasks/tasks.scss";
@import "main/work-services/work-services.scss";
@import "main/audit-logs/dialogs/log-dialog.scss";
@import "main/auth/reset-password/reset-password.scss";
@import "main/customers/dialogs/customer-dialog.scss";
@import "main/employees/dialogs/employee-dialog.scss";
@import "main/equipment-types/dialogs/equipment-types-dialog.scss";
@import "main/equipments/dialogs/equipments-dialog.scss";
@import "main/errors/404/error-404.scss";
@import "main/errors/desktop-required/desktop-required-error.scss";
@import "main/errors/download-app-required/download-app-required.scss";
@import "main/materials/dialogs/materials-dialog.scss";
@import "main/locations/dialogs/location-dialog.scss";
@import "main/notifications/sidenavs/main-sidenav.scss";
@import "main/plans-types/dialogs/plans-types-dialog.scss";
@import "main/recurrences/dialog/recurrence-dialog.scss";
@import "main/products/dialogs/products-dialog.scss";
@import "main/quotations/dialogs/quotation-dialog.scss";
@import "main/services/dialogs/services-dialog.scss";
@import "main/tickets/sidenavs/filters-sidenav.scss";
@import "main/settings/sidenavs/sidenav.scss";
@import "main/vehicles-usage/dialogs/vehicle-usage-dialog.scss";
@import "main/vehicles/dialogs/vehicle-dialog.scss";
@import "main/tasks/dialogs/order-dialog.scss";
@import "main/audit-logs/sidenavs/filters/filters-sidenav.scss";
@import "main/control-panel/dialogs/route-optimizer/route-optimizer-dialog.scss";
@import "main/control-panel/views/list/list.scss";
@import "main/control-panel/views/main/main.scss";
@import "main/control-panel/views/map/map.scss";
@import "main/control-panel/views/gantt/gantt-custom.scss";
@import "main/control-panel/views/gantt/gantt.scss";
@import "main/customers/dialogs/omie-manual-import/omie-manual-import-dialog.scss";
@import "main/employees/dialogs/activate/employee-dialog.scss";
@import "main/employees/dialogs/credentials/employee-credentials-dialog.scss";
@import "main/employees/dialogs/inactivate/employee-dialog.scss";
@import "main/employees/dialogs/password/employee-password-dialog.scss";
@import "main/employees/sidenavs/filters/filters-sidenav.scss";
@import "main/deadline-contracts/sidenavs/filters/filters-sidenav.scss";
@import "main/devices/sidenavs/filters/filters-sidenav.scss";
@import "main/deadline-violations-reasons/sidenavs/filters/filters-sidenav.scss";
@import "main/equipment-types/sidenavs/filters/filters-sidenav.scss";
@import "main/equipments/sidenavs/filters/filters-sidenav.scss";
@import "main/exports/sidenavs/filters/filters-sidenav.scss";
@import "main/modules/sidenavs/customers-area/customers-area.scss";
@import "main/modules/sidenavs/print-configuration/print-configuration.scss";
@import "main/modules/sidenavs/route-optimizer/route-optimizer.scss";
@import "main/modules/sidenavs/smart-rewrite/smart-rewrite.scss";
@import "main/modules/sidenavs/tickets/tickets.scss";
@import "main/materials/sidenavs/filters/filters-sidenav.scss";
@import "main/locations/dialogs/map/map-dialog.scss";
@import "main/notifications/sidenavs/notifications-sidenav/notifications-sidenav.scss";
@import "main/plans/sidenavs/filters/filters-sidenav.scss";
@import "main/plans/dialogs/import-spreadsheet/import-spreadsheet-plans-dialog.scss";
@import "main/plans/dialogs/plans/plans-dialog.scss";
@import "main/plans/dialogs/unprocessable-spreadsheet-prompt/unprocessable-spreadsheet-prompt.scss";
@import "main/plans-types/sidenavs/filters/filters-sidenav.scss";
@import "main/product-types/dialogs/product-types/product-types-dialog.scss";
@import "main/product-types/sidenavs/filters/filters-sidenav.scss";
@import "main/products/sidenavs/filters/filters-sidenav.scss";
@import "main/products/dialogs/omie-manual-import/omie-manual-import-dialog.scss";
@import "main/products-services/dialogs/omie-manual-import/omie-manual-import-dialog.scss";
@import "main/products-services/dialogs/products-services/products-services-dialog.scss";
@import "main/products-services/sidenavs/filters/filters-sidenav.scss";
@import "main/quotations/sidenavs/filters/filters-sidenav.scss";
@import "main/services/sidenavs/filters/filters-sidenav.scss";
@import "main/services/dialogs/alert/archive-service-alert.scss";
@import "main/tickets/dialogs/choose-location/choose-location.scss";
@import "main/tickets/dialogs/europ-assistance-accept-service/europ-assistance-accept-service.scss";
@import "main/tickets/dialogs/select-customer/select-customer.scss";
@import "main/tickets/views/outlook/outlook-view.scss";
@import "main/subscription/sidenavs/storage-upgrade/storage-upgrade.scss";
@import "main/subscription/sidenavs/due-date/due-date.scss";
@import "main/subscription/sidenavs/employees-upgrade/employees-upgrade.scss";
@import "main/settings/contents/account-info/account-info.scss";
@import "main/settings/contents/labels-manager/labels-manager.scss";
@import "main/subscription/sidenavs/renewal/renewal.scss";
@import "main/tasks/dialogs/confirm-delete-product-service-dialog/confirm-delete-product-service-dialog.scss";
@import "main/tasks/dialogs/link-products-dialog/link-products-dialog.scss";
@import "main/tasks/dialogs/link-services-dialog/link-services-dialog.scss";
@import "main/tasks/dialogs/return-order-products-dialog/return-order-products-dialog.scss";
@import "main/tasks/dialogs/search-answered-equipments-forms/search-answered-equipments-forms.scss";
@import "main/tasks/dialogs/storage-movements-dialog/storage-movements-dialog.scss";
@import "main/tasks/dialogs/update-pendency-assignee-modal/update-pendency-assignee-modal.scss";
@import "main/tasks/dialogs/create-or-edit-pendency-dialog/create-or-edit-pendency-dialog.scss";
@import "main/tasks/sidenavs/filters/filter.scss";
@import "main/work-services/dialogs/omie-manual-import/omie-manual-import-dialog.scss";
@import "main/work-services/sidenavs/filters/filters-sidenav.scss";
@import "main/work-services/dialogs/work-services/services-dialog.scss";
@import "main/customers/dialogs/tabs/attachments/attachments-tab.scss";
@import "main/customers/dialogs/tabs/contacts/contacts-tab.scss";
@import "main/customers/dialogs/tabs/general/general-tab.scss";
@import "main/customers/dialogs/tabs/order-report-template/order-report-template-tab.scss";
@import "main/equipments/dialogs/tabs/historic/historic-tab.scss";
@import "main/equipments/dialogs/tabs/tasks/tasks-tab.scss";
@import "main/locations/dialogs/tabs/attachments/attachments-tab.scss";
@import "main/locations/dialogs/tabs/general/general-tab.scss";
@import "main/locations/dialogs/tabs/tasks/tasks-tab.scss";
@import "main/locations/dialogs/tabs/contacts/contacts-tab.scss";
@import "main/locations/dialogs/tabs/equipments/equipments-tab.scss";
@import "main/locations/dialogs/tabs/location-links/location-links-tab.scss";
@import "main/recurrences/dialog/tabs/links/links-tab.scss";
@import "main/products/dialogs/tabs/general/general-tab.scss";
@import "main/products/dialogs/tabs/storage/storage-tab.scss";
@import "main/quotations/dialogs/tabs/attachments/attachments.scss";
@import "main/quotations/dialogs/tabs/general/general-tab.scss";
@import "main/quotations/dialogs/tabs/products-services/products-services-tab.scss";
@import "main/tickets/views/outlook/accept-scheduling-suggestion-dialog/accept-scheduling-suggestion-dialog-view.scss";
@import "main/tickets/views/outlook/detail/detail.scss";
@import "main/tickets/views/outlook/list/list.scss";
@import "main/tickets/views/outlook/new-scheduling-suggestion-dialog/new-scheduling-suggestion-dialog-view.scss";
@import "main/settings/contents/account-info/dialogs/resize-logo-dialog.scss";
@import "main/settings/dialogs/webhooks/create/create-webhook-dialog.scss";
@import "main/settings/dialogs/webhooks/response-details/webhook-response-details-dialog.scss";
@import "main/vehicles-usage/dialogs/tabs/forms/forms-tab.scss";
@import "main/vehicles-usage/dialogs/tabs/general/general-tab.scss";
@import "main/vehicles/dialogs/tabs/forms/vehicle-forms-tab.scss";
@import "main/vehicles/dialogs/tabs/general/general-tab.scss";
@import "main/tasks/dialogs/tabs/attachments/attachments-tab.scss";
@import "main/tasks/dialogs/tabs/comments/comments-tab.scss";
@import "main/tasks/dialogs/tabs/deadlines/deadlines-tab.scss";
@import "main/tasks/dialogs/tabs/forms/forms-tab.scss";
@import "main/tasks/dialogs/tabs/historic/historic-tab.scss";
@import "main/tasks/dialogs/tabs/links/links-tab.scss";
@import "main/tasks/dialogs/tabs/materials/materials-tab.scss";
@import "main/tasks/dialogs/tabs/pendencies/pendencies-tab.scss";
@import "main/tasks/dialogs/tabs/products/products-tab.scss";
@import "main/tasks/dialogs/tabs/quotations/quotations-tab.scss";
@import "main/tasks/dialogs/tabs/routes/routes-tab.scss";
@import "main/tasks/dialogs/tabs/services/services-tab.scss";
@import "main/tasks/dialogs/tabs/storages-movements/storages-movements-tab.scss";
@import "main/control-panel/dialogs/route-optimizer/templates/fc-modal-invalid-geo/fc-modal-invalid-geo.scss";
@import "main/control-panel/views/list/views/classic/classic-view.scss";
@import "main/customers/dialogs/tabs/locations/edit/locations-tab.scss";
@import "main/customers/dialogs/tabs/locations/new/locations-tab.scss";
@import "main/control-panel/views/list/views/classic/list/list.scss";
// endinjector