.location-dialog__equipments-tab {
  &__main-container {
    padding: 14px 22px;
    margin-bottom: 20px;

    &__search-bar {
      margin-bottom: 20px;

      .fc-input {
        width: 95%;

        &__input--search {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        &__button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
          width: 42px;

          md-icon {
            color: #135ac0;
          }
        }
      }

      &__hint {
        color: #7f7f7f;
        font-size: 12px;
      }
    }

    &__equipments {
      &__title {
        margin-top: 0;
      }

      &__image {
        height: 100%;
        width: 100%;
        object-fit: contain;
        max-width: initial;
        border: thin solid rgba(0, 0, 0, 0.12);
      }

      &__item {
        position: relative;
        padding: 18px 0;

        &--selected {
          color: #9e9e9e;
        }

        strong {
          font-weight: 500;
        }
      }

      &__select-all {
        padding-right: 12px;
      }

      &__navigation {
        padding-right: 0;

        &__info {
          font-size: 14px;
          color: #757575;
        }

        &__button {
          width: 10%;
          height: 36px;
          background-color: transparent !important;
          border: 1.8px solid #9e9e9e;
          color: #135ac0;

          &:first-of-type {
            margin: 0 8px;
          }

          &:hover {
            background-color: #f8f8f8 !important;
            border-color: #838383;
            color: #0d4ca3;
          }

          &:disabled {
            background-color: transparent !important;
            color: #707070 !important;

            &:hover {
              background-color: transparent !important;
              color: #707070 !important;
            }
          }
        }
      }
    }
  }

  info-component-provider {
    & > div {
      margin-bottom: 24px;
    }
  }
}
