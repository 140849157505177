.fc-modal {
  margin: auto;
  position: relative;
  top: 35%;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 4px;
  animation: appearingAnimation 200ms ease-in-out both;
  width: 480px;

  &__large {
    width: 780px;
    min-height: 500px;
    padding: 27px 25px;

    .fc-modal__content {
      margin: 18px 0;
    }
  }

  &__small {
    width: 480px;
    padding: 24px 20px;

    .fc-modal__content {
      margin: 25px 0;
    }
  }

  &__padding-small {
    padding: 32px !important;
  }

  &__icon {
    width: 100%;
    height: 42px;
    margin-bottom: 42px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 20px;

    .fc-modal__icon {
      width: 12px;
      height: 12px;
    }

    md-icon {
      cursor: pointer;
      margin: 0;

      &:not(.title-icon) svg path {
        fill: #757575;
      }

      &.title-icon {
        cursor: default;
        height: 20px;
        width: 20px;
        margin-right: 6px;
      }
    }
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2b2b2b;
  }

  &__content {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #757575;

    &__blue_bold {
      b {
        color: #105BFB !important;
      }
    }
  }

  &__buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__buttons-container-flex {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
  }

  &__button {
    width: initial;
    text-transform: uppercase;
    height: 40px;

    &--left-button {
      background-color: #fff;
      color: #135AC0 !important;

      &:hover {
        background-color: #e0e0e0 !important;
        color: #114da1 !important;
      }
    }
  }

  .omie-account-select {
    margin-bottom: 26px !important;
  }

  .red-left-button {
    background-color: #fff;
    color: #FC3868 !important;

    &:hover {
      background-color: #e0e0e0 !important;
      color: #d41c4a !important;
    }
  }

  .full-size-button {
    width: 100% !important;
  }

  &__scrollable-content {
    border: 1px solid #D4DEF1;
    border-radius: 7px;
    width: 100%;
    margin-bottom: 24px;
    height: fit-content;
    max-height: 220px;
    overflow-y: auto;

    &__name {
      padding: 14px 16px;
      color: #262626;
      font-weight: 400;
      font-size: 14px;
      border-bottom: 1px solid #D4DEF1;

    }
  }
}
