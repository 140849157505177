.fc-autocomplete {
  position: relative;

  &__autocomplete {
    position: relative;
    height: max-content;

    &:not(.disabled):hover {
      .fc-clear-button {
        border-left: 1.6px solid #777777;
      }
    }

    &__search-icon {
      position: absolute;
      top: 25%;
      left: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 14px;
        width: 14px;
      }
    }

    &__clear-button {
      height: 48px;
      width: 48px;

      cursor: pointer;
      border-left: 1px solid #e0e0e0;
      background: url('../assets/icons/icon-clear-autocomplete.svg') no-repeat;
      background-position: center;

      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__dropdown {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-top: none;
    border-radius: 4px !important;
    width: 100%;
    max-height: 129px;
    overflow-y: scroll;
    z-index: 10;

    &__item {
      padding: 12px 14px;
      list-style-type: none;
      color: #3d3d3d;
      font-size: 14px;
      transition: color 200ms, background-color 200ms;

      &.activated {
        background-color: #e0e0e0;
        color: #000;
      }

      &:hover {
        background-color: #e0e0e0;
        color: #000;
      }
    }
  }
}
