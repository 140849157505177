.tab-equipment-types-forms {
  md-input-container.md-input-focused:not([md-no-float])
    .md-select-placeholder
    span:first-child {
    -webkit-transform: none;
    transform: none;
  }

  .form-list {
    &__row {
      padding: 0;

      .md-list-item-inner {
        padding: 0;
      }
    }
  }

  .allow-all-forms {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .container-teste {
    display: flex;
  }

  .switches {
    margin-left: 40px;
  }

  .form-name {
    max-width: 130px;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .questions-count {
    margin-left: 10px;
    margin-top: 1.3rem;
  }

  .text-gray {
    color: rgba(0, 0, 0, 0.35);
    animation: none;
  }
}


.filter-employees {
  md-list {
    md-list-item {
      .w-100-percent {
        width: 100%;
      }
    }
  }
}

.input-name{

  &__required-message{
    font-size: 12px !important;
    color: #e20909 !important;
    position: absolute;
    bottom: 0px;
  }
}

.tutorial-video {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin-left: 20px;
  gap: 10px;
  border-radius: 24px;
  text-transform: none;
}