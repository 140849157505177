md-dialog-content .container-attachments {
  min-height: min-content !important;
}

.warning {
  background: #ebf9fd;
  color: #757575;
  font-size: 13px;
  height: 48px;
  left: 0;
  position: absolute;
  right: 0;
  top: 6px;

  md-icon {
    height: 18px;
    margin: 0;
    margin-right: 4px;
  }
}

.drop-box {
  align-items: center;
  background-color: white;
  border: 2px dashed #d7d7d7;
  border-radius: 6px;
  cursor: default !important;
  margin: 54px 0 16px 0;
  transition: border 0.3s;

  &.has-images {
    flex-direction: column-reverse;

    .upload-warn {
      width: 100%;

      &__sentence-container {
        display: flex;
        padding: 0;
        margin-top: 20px;
        width: 100%;

        &__actions-buttons-container {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;

          button {
            margin: 0 !important;
          }
        }
      }
    }
  }

  .upload-warn {
    height: 54;
    width: 60%;
    user-select: none;

    &__sentence-container {
      padding: 8px 16px;

      &__phrase {
        align-self: center;
        color: #1257c3;
        font-size: 16px;
        font-weight: 500;
        margin-top: 18px;

        transition: color 0.3s;
      }

      md-icon {
        margin: 0 4px;

        svg path {
          fill: #1257c3;
        }
      }

      &__actions-buttons-container {

        &__phrase {
          color: #363636;
          margin-right: 8px;
        }

        button {
          border: transparent;
          border-radius: 6px;
          margin: 18px 0 0 0;

          span {
            text-transform: none;
            transition: color 0.3s;
          }

          &.insert-attachments {
            border: 1px solid #d6d6d6;
            padding: 2px 16px;

            span {
              color: #4e4e4e;
              font-weight: 400;
            }

            md-icon {
              margin: 0;

              svg {
                width: 18px;
                height: 18px;

                path {
                  fill: #4e4e4e !important;
                  transition: fill 0.3s;
                }
              }
            }

            &:hover {
              span {
                color: #363636;
              }

              md-icon svg path {
                fill: #363636 !important;
              }
            }
          }

          &.send-attachments {
            background-color: rgb(9,87,195);
            padding: 2.1px 25px;

            span {
              color: white;
              font-weight: bold;
            }

            &:hover {
              background-color: darken(rgb(9, 87, 195), 10%) !important;
            }
          }
        }
      }
    }
  }

  &:hover {
    border: 2px dashed #a8a8a8;

    .upload-warn__sentence-container {
      md-icon svg path {
        fill: darken(#1257c3, 10%);
      }

      &__phrase {
        color: darken(#1257c3, 10%);
      }
    }
  }
}

.container-attachments {
  md-progress-circular {
    display: inline-block;
    margin-bottom: 10px;
  }

  .wrapper-images-upload {
    flex-wrap: wrap;
    max-width: 100%;
    overflow-x: hidden;

    div {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      height: 70px;
      margin-bottom: 10px;
      margin-left: 10px;
      position: relative;
      width: 120px;

      .file-image-thumbnail{
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover {
        cursor: pointer;
      }

      md-icon {
        position: absolute;
        right: 8px;
        top: 2px;
        z-index: 99;

        &:hover {
          svg path {
            fill: #bd0e0e;
          }
        }
      }

      img {
        border: thin solid #d5d5d5;
        border-radius: 5px;
        height: 60px;
        width: 100px;
        background-color: gray;
        object-fit: cover;
      }
    }
  }

  md-divider {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 16px;
  }

  .attachments-limit {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-top: 48px;
  }
}

.attachments {
  .attachment {
    position: relative;
    margin-bottom: 16px;

    &-preview {
      background-color: #eef0f2;
      width: 160px;
      height: 128px;
      background-size: cover;
      border-radius: 5px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      margin-right: 24px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);

      md-icon {
        margin-top: 38%;
        margin-left: 38%;
      }
    }

    &-video {
      position: relative;
      cursor: pointer;

      .icon {
        left: -7%;
        position: absolute;
        top: -5%;
        color: white !important;
      }

      .loader {
        left: 33%;
        position: absolute;
        top: 35%;
      }

      &::before {
        background-size: cover;
        content: "";
        display: block;
        height: 44px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 38px;
      }
    }

    &-content {
      z-index: 10;

      &__metadata {
        align-items: start;
        justify-content: space-between;

        .mt-22 {
          margin-top: 22px;
        }
      }

      &-url,
      &-name {
        font-weight: 500;
        font-size: 16px;
      }

      &-time {
        color: rgba(0, 0, 0, 0.6);
        font-size: 13px;
        font-weight: 400;
        padding: 12px 0;
      }

      &-edit {
        display: flex;
        flex-direction: row;
        width: 100% !important;
        border-bottom: 1px solid #D4DEF1;

        input {
          width: 80%;
          border: none;
        }
      }

      &__icon-check {
        color: #135AC0;
      }

      &__icon-close {
        color: #FC3868;
      }
    }

    &-rename {
      color: #135ac0;
      cursor: pointer;
      font-size: 15px;
      max-width: max-content;
      font-weight: 500;

      &:hover {
        color: #083d89;
      }
    }

    &-processing {
      margin-top: 15px;
      color: #135ac0;
      font-size: 15px;
      max-width: max-content;
      font-weight: 500;
      align-items: baseline;
      display: flex;
      gap: 5px;
      opacity: 1;
    }

    &.archived {
      .archived-text {
        display: block;
        margin-top: 12px;

        md-icon,
        span {
          color: rgba(173, 181, 189, 1);
        }

        span {
          font-size: 15px;
          font-weight: 500;
        }
      }

      &-preview {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
          background-color: rgba(173, 181, 189, 0.5);
        }
      }

      &-content {
        &-url,
        &-name,
        &-time,
        .add-task {
          color: rgba(173, 181, 189, 1) !important;
        }
      }
    }
  }

  .attachment-archive-dialog,
  .attachment-delete-dialog {
    max-width: 50%;

    div,
    span {
      max-width: 100%;
    }
  }
}

.hover-button:hover {
  background-color: darken(rgb(9, 87, 195), 10%) !important;
}
