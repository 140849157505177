.smart-rewrite-dialog {
  .data-container {
    width: 100%;
    border-radius: 8px;
  }
}

.tablist-full-height {
  height: 92%;
}

.smart-rewrite-tabs {
  padding: 0 !important;

  .general-body {
    padding: 16px !important;
    margin-top: 10px !important;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .info-div {
      width: 100% !important;
      padding: 16px !important;
      background-color: #F1F5FC !important;
      border-radius: 8px !important;
      display: flex !important;
      flex-direction: row;
      gap: 12px !important;
      align-items: center !important;

      .info-data {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;

        a {
          cursor: pointer;
        }
      }
    }

    .container-information {
      border: 1px solid #D4DEF1;
      width: 100% !important;
      padding: 16px !important;
      border-radius: 8px !important;
      display: flex !important;
      justify-content: space-between;
      flex-direction: row;
      gap: 12px !important;
      align-items: center !important;

      .bolding-text {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
      }

      .graying-text {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        color: #757575;
      }

      .bullet-pointer {
        font-size: 12px !important;
        font-family: Roboto;
        font-weight: 600;
        background-color: #135AC0;
        border-radius: 100px;
        padding: 4px 8px;
        color: white !important;
      }

      .blue-pointer-bg {
        background-color: #135AC0;
      }

      .yellow-pointer-bg {
        background-color: #E2772E;
      }

      .danger-pointer-bg {
        background-color: #FC3868;
      }
    }

    .container-reinformation {
      font-size: 12px !important;
      border: 1px solid #D4DEF1;
      width: 100% !important;
      padding: 16px !important;
      border-radius: 8px !important;
      display: flex !important;
      justify-content: space-between;
      flex-direction: row;
      gap: 12px !important;
      align-items: center !important;

      .bolding-text {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
      }

      .graying-text {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        color: #757575;
      }

      .hire-button {
        cursor: pointer;
        background-color: white;
        border-radius: 6px;
        padding: 12px 16px;
        color:#135AC0 !important;
        border: 1px solid #D4DEF1;
      }

      .hire-text {
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .config-container {
      height: 90px !important;
      border: 1px solid #D4DEF1;
      width: 100% !important;
      padding: 16px !important;
      border-radius: 8px !important;
      display: flex !important;
      flex-direction: row;
      gap: 12px !important;
      align-items: center !important;

      .config-text {
        color: #9E9E9E;
        font-family: Roboto;
        font-size: 14px;
      }
    }
  }

  .no-history-body {
    padding: 24px;
    height: 100%;

    .no-history-info {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      height: 80px;
      border: 1px solid #D4DEF1;
      border-radius: 4px;

      .no-history-text {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        color: #9E9E9E;
      }
    }
  }


  .history-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    height: 100%;

    .history-partition {
      display: flex;
      flex-direction: column-reverse;
      gap: 12px;
    }

    .history-repeater {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .button-divider {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .load-more-button {
        cursor: pointer;
        background-color: white;
        border-radius: 6px;
        padding: 12px 16px;
        color:#135AC0 !important;
        border: 1px solid #D4DEF1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        .hire-text {
          font-family: Roboto;
          font-weight: 500;
          font-size: 14px;
        }
      }

    }

    .title-text {
      margin: 0;
      margin-bottom: 12px !important;
      font-family: Roboto;
      font-size: 16px;
      color: #757575;
    }

    .history-container {
      display: flex;
      flex-direction: row;
      gap: 12px;

      .history-div {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
      }

      .history-icon {
        width: 40px;
        height: 40px;
        background-color: #F2F5FC;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-sizing {
          width: 18px !important;
          height: 18px !important;
          min-width: 18px !important;
          min-height: 18px !important;
        }
      }

      .history-bar {
        width: 1px;
        padding-top: 12px;
        background-color: #D4DEF1;
        height: 60px;
      }

      .history-data {
        width: 600px;
        padding: 18px 20px;
        border: 1px solid #D4DEF1;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 112px;

        .history-item-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-family: Roboto;

          .history-created-at {
            font-size: 13px;
            color: #757575;
          }
        }

        .history-item-creator {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          margin-top: -14px;
          color: #757575;

          .history-creator-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 18px;
            width: 18px;
            min-width: 18px !important;
            min-height: 18px !important;
            margin-right: -4px !important;
            margin-left: 0px;
          }

          .creator-text {
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            margin-top: 1.5px !important;
            color: #757575;
          }
        }

        .history-item-value {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          margin-top: -4px;
          color: #757575;

          .bolded-text {
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }
  }

  .primary-coloring {
    color: #135AC0 !important;
  }

  .no-margin-paragraph {
    margin: 0;
  }
}


.footer-data {
  border: none !important;
  font-family: Roboto !important;
  font-size: 14px;

  .hidden {
    display: none !important;
  }

  .footer-info {
    margin-top: 4px;
  }
}
