.w-100-percent {
  width: 100% !important;
}

.container-client-name {
  display: flex;
  align-items: center;
}

.is-archived {
  text-decoration: line-through;
  color: grey;
  position: absolute;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.is-archived-text {
  color: grey;
  font-weight: bold;
  position: absolute;
  margin-left: 69%;
}

.warranty-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 !important;
  font-size: 8px !important;
}
