.fc-button {
  padding: 0px 28px;
  height: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 6px;
  border: 0.98381px solid #D4DEF1 !important;
  background-color: #135ac0;
  color: #fff;
  transition: background-color 250ms, color 250ms;
  white-space: nowrap;

  &--transition-none {
    transition: none !important;
  }

  &--actions {
    text-transform: uppercase;
    height: 40px;
    width: 74px;
    padding: 8px 16px 8px 16px;

    &__continue {
      width: auto;
      text-transform: uppercase;
      height: 40px;
      padding: 8px 16px 8px 16px;
    }
  }

  &--large {
    padding: 0px 23px;
    height: 45px;
  }

  &:hover {
    background-color: #084599 !important;
    color: #e7e6e6;
  }

  &--back {
    background-color: #fff;
    color: #135ac0 !important;

    &:hover {
      background-color: #dfdfdf !important;
      color: #0f52af;
    }
  }

  &:disabled {
    background-color: #d7d7d7 !important;
    color: #707070 !important;

    &:hover {
      background-color: #d7d7d7 !important;
      color: #707070 !important;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
    margin: 0;
    margin-left: 4px;

    svg {
      height: 14px;
      width: 14px;
    }
  }
}
