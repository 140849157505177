.filter-events {
  md-list {
    md-list-item {
      .w-100-percent {
        width: 100%;
      }
    }
  }

  .filter-property-name {
    padding-left: 10px;
    height: 25px;
    color: rgba(0,0,0,0.54);
  }
}

.w-100-percent {
  width: 100% !important;
}
