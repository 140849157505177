.filter-employees {
  md-list {
    md-list-item {
      .w-100-percent {
        width: 100%;
      }
    }
  }
}

.table-text {
  word-break: break-word;
}

.equipments-page {
  .equipments-feedback {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #D4DEF1;
    margin-bottom: 16px;
    margin-top: 6px;
    margin-left: 1px;
    border-radius: 8px;
    background-color: transparent !important;
  }
}

.status-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  border-radius: 12px;

  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  text-align: center;

  &__dot {
    margin-right: 6px;
  }
}
