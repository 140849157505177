.message-infobar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__message{
    margin-left: 20px;
  }

  &__button{
    padding: 0px 15px;
    height: 38px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    border: 0.98381px solid #D4DEF1 !important;
    background-color: #135ac0;
    color: black;
    background-color: white;
    transition: 250ms;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      background-color: #2969C6 !important;
      color: white !important;
      opacity: 0.5;
    }
  }

}