.filter-recurrences {
    md-list {
        md-list-item {
            .w-100-percent {
                width: 100%;
            }
        }
    }

    .bg-grey {
        background-color: #ccc;
    }

    .icon-account-remove {
        position: relative;
        bottom: -3px;
    }
}

.recurrence-notes {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100px;
    text-overflow: ellipsis;
}

.recurrence-description{
    word-wrap: break-word;
    text-overflow: clip;
}