.storages-movements-tab {
  height: 850px;

  &.tab-min-height {
    height: 700px;
  }

  &.tab-medium-height {
    height: 750px;
  }

  &__content {
    padding: 24px 0 0 0 !important;

    &#storages-movements-tab-content {
      height: 100% !important;
    }

    .fc-input__input--search {
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      border: 1px solid #D4DEF1;
    }

    &__search-bar {
      width: 100%;

      input {
        padding: 0 34px !important;
        border-radius: 4px;
      }

      label {
        left: 34px;
      }

      md-icon {
        position: absolute;
        left: 9px;
        top: 12px;
        color: #135AC0;
        font-size: 18px !important;
      }
    }

    &__link-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      background-color: #135AC0 !important;
      border-radius: 4px;
      margin: 0 0 0 12px;
      width: 180px;
      height: 48px;
      text-transform: none !important;

      font-size: 14px !important;
      color: #ffffff !important;
      font-weight: 500;
    }

    &__title {
      font-weight: 500;
      font-size: 16px;
      color: #262626;
      margin: 24px 0;
    }

    &__table {
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #D4DEF1;

      &__content {
        width: 100%;
        border-collapse: collapse;

        &__header {
          height: 39px;
          border-bottom: 1px solid #D4DEF1;

          &__row {
            th {
              color: #262626;
              font-size: 14px;
              font-weight: 500;
              padding: 16px 0;

              &:first-child {
                padding: 16px;
                width: 110px;
              }

              &:nth-child(2) {
                width: 150px;
              }

              &:nth-child(3) {
                width: 276px;
              }

              &:nth-child(4) {
                width: 105px;
              }

              &:nth-child(5) {
                width: 140px;
              }

              &:last-child {
                width: 40px;
              }
            }
          }
        }

        &__items {
          &__row {
            overflow: hidden;
            color: #262626;
            text-overflow: ellipsis;
            font-family: Roboto;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.13px;
            border-bottom: 1px solid #D4DEF1;

            &:last-child {
              border-bottom: 0;
            }

            td {
              position: relative;
              padding: 16px 0;

              &:first-child {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 16px;
              }

              &:nth-child(3) {
                max-width: 276px;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
              }

              &:nth-child(5) {
                max-width: 140px;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
              }


              &:last-child {
                padding: 16px;
              }

              md-icon {
                color: #135AC0 !important;
                font-size: 24px !important;
                width: 24px !important;
                height: 24px !important;
                cursor: pointer;
              }
            }
          }
        }
      }

      &__navigation {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        padding: 12px 16px;
        border-top: 1px solid #D4DEF1;

        &__text {
          color: #757575;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0.13px;
        }

        &__button-previous,
        &__button-next {
          padding: 10px 12px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
        }

        &__button-previous {
          border: 1px solid #F2F2F2;
          color: #262626;
        }

        &__button-next {
          border: 1px solid #D4DEF1;
          color: #135AC0;
        }

        &__button-previous:disabled,&__button-next:disabled {
          cursor: not-allowed;
          border: 1px solid #F2F2F2;
          color: #757575;
        }
      }
    }

    &__loading {
      margin: 18rem 0;
      gap: 2rem;
    }

    &__not-found {
      display: grid;
      justify-content: center;
      justify-items: center;

      color: #757575;
      border: 1px solid #D4DEF1;
      border-radius: 8px;
      padding: 40px 0px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
