.print-configuration-dialog {
  .print-configuration-sidenav__title {
    font-size: 19px;
    font-weight: 500;
    margin: 20px 0;
    padding-left: 26px;
  }

  .print-configuration-sidenav__block {
    padding: 10px 26px;

    & > div{
      align-items: center;
    }

    & > label {
      font-weight: 500;
      font-size: 16px;
    }

    & > small {
      display: block;
      color: #9e9e9e;
      font-size: 12px;
      font-weight: 500;
    }

    md-select {
      margin: 0;
    }
  }

  .print-configuration-sidenav__block__item {
    max-height: 30px;
  }

  .margin-zero {
    margin: 0;
  }

  .md-char-counter {
    color: #9e9e9e;
    font-size: 10px
  }

  &__loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
