.fc-export-alert {
  position: fixed;
  bottom: 46px;
  left: 30px;
  padding: 22px 52px;
  border-radius: 4px;
  background-color: #FFFFFF;
  border: thin solid #D4DEF1;
  width: 378px;

  &__header {
    margin-top: 0px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    color: #262626;
  }

  &__body {
    font-weight: 400;
    font-size: 13px;
    color: #757575;

    & > p > span:last-child { color: #135AC0; }
  }

  &__export-button {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    border: thin solid #D4DEF1;
    border-radius: 4px;
    color: #135AC0;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &__dont-see-anymore {
    font-weight: 400;
    font-size: 13px;
    color: #757575 !important;
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
  }

  &__close {
    position: absolute;
    top: 18px;
    right: 20px;
    width: 24px !important;
    height: 24px !important;
    padding: 0px !important;
    margin: 0px 0px !important;
    min-height: 24px !important;

    md-icon {
      color: #9E9E9E !important;
    }
  }

  &__alert {
    position: absolute;
    top: 21.6px;
    left: 22px;
    width: 20px;
  }
}
