.deadline-violations-reasons-dialog {
  .fc-warning-container {
    padding: 20px 20px !important;
  }

  .warning-icon {
    color: #135ac0;
  }

  .selected-color{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-left: 10px;
    right: 34px;
    bottom: 10px;
    position: absolute;
    z-index: -1;
  }

  .button-container {
    cursor: pointer;
  }

  .input-color-label{
    left: 12px !important;
  }

  .input-color-container {
    position: relative;
    overflow: hidden;
    width: 40px;
    height: 40px;
    border: solid 2px #ddd;
    border-radius: 40px;
  }

  .input-color {
    input[type="color"]::-webkit-color-swatch {
      border: none;
      border-radius: 50%;
    }
    input[type="color"] {
      width: 80%;
    }
  }

}
