.import-customers {
  .fc-input__input--search {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border: 1px solid #d4def1;
  }

  &__search-bar {
    margin-bottom: 16px;
    width: 100%;

    input {
      padding: 0 34px !important;
      border-radius: 4px;
    }

    label {
      left: 34px;
    }

    md-icon {
      position: absolute;
      left: 9px;
      top: 12px;
      color: #135ac0;
      font-size: 18px !important;
    }
  }

  &__header {
    margin-bottom: 24px;

    &__title {
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 25px;
    }

    &__select-message {
      font-style: italic;
      font-weight: 400;
      font-size: 13px;
      color: #757575;
      margin-left: 16px;
    }
  }

  &__customer {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    gap: 4px 4px;

    span {
      color: #757575;
    }

    &__title {
      color: #000 !important;
    }
  }

  &__import {
    md-icon {
      margin: 0 16px;
    }

    &__action {
      color: #135ac0;
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__table-navigation {
    gap: 8px 8px;
    margin-top: 20px;

    &__info {
      font-size: 13px;
      font-weight: 400;
      color: #757575;
      margin-left: 10px;
    }

    &__button-previous,
    &__button-next {
      font-weight: 500;
      font-size: 14px;
      padding: 9px;
      border: 1px solid #d4def1;
      border-radius: 4px;
    }

    &__button-previous {
      color: #262626;
    }
    &__button-previous:disabled,
    &__button-next:disabled {
      color: #757575;
    }

    &__button-next {
      color: #135ac0;
    }
  }

  &__item {
    position: relative;
    padding: 16px 0;
    margin-bottom: 16px;
    border: 1px solid #d4def1;
    border-radius: 4px;

    &--selected {
      color: #9e9e9e;
    }

    strong {
      font-weight: 500;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    max-width: initial;
    border: 0.53px solid #d4def1;
    border-radius: 2.1px;
  }

  &__select {
    padding-left: 10px;
  }
}
