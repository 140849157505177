#timeline-location {
  position: relative;
  height: auto;

  .ms-timeline-loader {
    display: none !important;

    .spinner {
      display: none !important;
    }
}

  &:before {
      display: block;
      position: absolute;
      z-index: 10;
      content: "";
      width: 2px;
      background: rgba(0, 0, 0, 0.08);
      top: 0;
      left: 50%;
      bottom: 0;
      margin-left: -1px;
  }



  .ms-timeline-item {
      overflow: hidden;
      position: relative;
      z-index: 20;
      width: 100%;
      margin: 0 auto;
      max-width: 1200px;

      .timeline-card {
          order: 1;

          ms-card {
              padding-right: 84px;
          }
      }

      .timeline-point {
          position: absolute;
          width: 48px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          border-radius: 50%;
          left: 50%;
          margin: 16px 0 0 -28px;
          order: 2;

          md-icon {
              color: #FFFFFF;
          }
      }

      .timeline-detail {
          flex: 50% 0 0;
          padding-top: 28px;
          order: 3;

          .time {
              font-size: 15px;
              padding-left: 68px;
          }

          .event {
              color: rgba(0, 0, 0, 0.54);
              padding-left: 68px;
          }
      }

      &:nth-child(odd) {

          .timeline-card {
              flex-direction: row-reverse;
          }
      }

      &:nth-child(even) {

          .timeline-card {
              order: 3;
              padding-right: 0;
          }

          .timeline-detail {
              order: 1;
              padding-left: 0;
              text-align: right;

              .time,
              .event {
                  padding-right: 68px;
              }
          }
      }

      &:last-child {
          padding-bottom: 0;
      }
  }

  /* Animations */
  .ms-timeline-item {

      &.animate {

          .timeline-card {
              animation: slide-in-left 0.4s;
          }

          .timeline-point {
              animation: slide-in-bottom 0.4s;
          }

          .timeline-detail {
              animation: slide-in-right 0.4s;
          }
      }

      &:nth-child(even) {

          &.animate {

              .timeline-card {
                  animation: slide-in-right 0.4s;
              }

              .timeline-detail {
                  animation: slide-in-left 0.4s;
              }
          }
      }
  }

  // Timeline Left
  &.timeline-left {

      .ms-timeline-item {

          .timeline-card {
              order: 1;

              ms-card {
                  padding-right: 68px;
                  padding-left: 0;
                  margin-left: auto;
              }
          }

          .timeline-detail {
              order: 3;
              text-align: left;
          }

          &.animate {

              .timeline-card {
                  animation: slide-in-left 0.4s;
              }

              .timeline-detail {
                  animation: slide-in-right 0.4s;
              }
          }
      }
  }

  // Timeline Right
  &.timeline-right {

      .ms-timeline-item {

          .timeline-card {
              order: 3;

              ms-card {
                  padding-left: 68px;
                  padding-right: 0;
                  margin-right: auto;
              }
          }

          .timeline-detail {
              order: 1;
              text-align: right;

              .time,
              .event {
                  padding-right: 68px;
                  padding-left: 0;
              }
          }

          &.animate {

              .timeline-card {
                  animation: slide-in-right 0.4s;
              }

              .timeline-detail {
                  animation: slide-in-left 0.4s;
              }
          }
      }
  }

  &.timeline-location {

      .ms-timeline-item {

          .timeline-point {
              order: 1;
              position: relative;
              left: auto;
              margin: 0px 15px 16px 15px;
              max-width: 54px;
              max-height: 54px;
          }

          .timeline-card {
              order: 2;
              max-width: 750px;
              width: 100%;
          }

          &.animate {

              .timeline-card {
                  animation: slide-in-left 0.4s;
              }

              .timeline-detail {
                  animation: slide-in-right 0.4s;
              }
          }
      }
  }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-md) {

  #timeline,
  #timeline.timeline-left,
  #timeline.timeline-right {

      .ms-timeline-item {

          .timeline-card {
              order: 3;

              ms-card {
                  padding: 0;
              }
          }

          .timeline-point {
              order: 1;
              position: relative;
              left: auto;
              margin: 0 0 16px 0;
              padding: 0;
          }

          .timeline-detail {
              order: 2;
              padding: 0;
              margin-bottom: 16px;

              .time,
              .event {
                  padding-right: 0;
                  padding-left: 0;
                  text-align: center;
              }
          }

          &:nth-child(even) {

              .timeline-card {
                  order: 3;

                  ms-card {
                      padding: 0;
                  }
              }

              .timeline-point {
                  order: 1;
                  position: relative;
                  left: auto;
                  margin: 0 0 16px 0;
                  padding: 0;
              }

              .timeline-detail {
                  order: 2;
                  padding: 0;
                  margin-bottom: 16px;

                  .time,
                  .event {
                      padding-right: 0;
                      padding-left: 0;
                      text-align: center;
                  }
              }
          }
      }
  }
}


.timeline-location .ms-timeline-item .timeline-card .ms-card {
    padding: 8px 0px 0px 0px !important;
    width: 100% !important;
    display: block;
    flex-direction: row;

    .card {
        font-size: 14px;
        border: 1px solid#D4DEF1;
        border-radius: 6px;

        .header {
            padding-top: 16px;
            padding-left: 16px;

            .avatar {
                .image {
                    display: block;
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                }
            }

            .info {
                width: 100%;

                .created-at {
                    text-align: right;
                    color: #757575;
                }
            }
        }

        .media {
            .image {
                width: 100%;
            }
        }

        .text {
            color: #757575;
        }
    }
}

.timeline-location .ms-timeline-item {
    background-color: #fff;
    padding: 15px 0px 0px 10px;

    .timeline-start {
        background-color: #fff;

        .timeline-point {
            order: 1;
            margin: 10px 15px 16px !important;
        }

        .timeline-line {
            border-left: 1px solid #D4DEF1;
            height: 100%;
            position: absolute;
            margin-left: 40px;
            z-index: -1;
        }
    }

    .timeline-card {
        border-radius: 6px !important;
    }

    @media screen and (max-width: 1320px) {
        .timeline-start {
            display: none;
        }

        .ms-card {
            margin-left: 13px;
        }
    }
}

.timeline-location .ms-timeline-item:first-child {
    padding-top: 15px;
}

.timeline-location::before {
    background-color: #fff;
}

.ms-card-location .ms-card-content-wrapper {
    max-width: 750px;
    box-shadow: none;
}

.to-task-list {
    margin: 15px 22px 0 22px;
    border: solid 1px #D4DEF1;
    border-radius: 4px;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &__title {
        color: #135AC0;
        font-weight: 500;
        margin-left: 15px;
        font-size: 16px;
        padding: 12px 0px 12px 0px;
    }

    &__button md-icon {
        color: #135AC0;
    }
}

.subtitle {
    margin-top: 3px;
    font-weight: 500;
}

.created-at {
    margin-right: 16px;
}
