.customer-dialog-template-tab {
  padding: 14px 22px;

  &__empty-state {
    margin-top: 24px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid #E6E6E6;

    span {
      color: #666;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__item {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    height: 60px;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    strong {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #000;
      font-size: 14px;
      font-weight: 500;
    }

    span {
      color: #999;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__label {
    padding: 4px 8px 4px 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 9999px;
    font-size: 12px;
    font-weight: 500;
    height: 20px;

    &--active {
      border: 1px solid #D6F5EB;
      background: #EAFAF5;
      color: #29A37A;
    }

    &--inactive {
      border: 1px solid #FCC;
      background: #FFE5E5;
      color: #F00;
    }

    &--archived {
      background-color: rgba(96, 125, 139, 0.1);
      color: rgb(96, 125, 139);
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  &__unlink {
    color: #FF0000;
  }

  &__edit {
    color: #135AC0;
  }

  &__button {
    margin: 0px;
    border: solid 1px #D4DEF1;
    color: #135AC0;
    font-weight: 500;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    height: 48px;
  }
}