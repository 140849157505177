.carded-sidenav {
  md-list {
    .md-subheader:first-child {
      padding-top: 0px;
    }

    .md-subheader {
      padding-top: 20px;
      font-weight: 900;
    }

    .list-items {
      padding-left: 20px;
      margin-top: -10px;
      width: 197px;

      md-list-item:hover {
        background: #e1e1e147;
        cursor: pointer;
      }

      .active {
        background: #e1e1e173;

        p {
          font-weight: 500;
        }
      }
    }
  }
}
