.fc-textarea {
  padding-bottom: 20px;
  position: relative;
  display: grid;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #cccccc;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    height: auto;
  }

  &:hover {
    .fc-textarea__textarea:not(.disabled):not(.invalid) {
      border: 1px solid #777777;
    }
  }

  &::after {
    content: attr(data-replicated-value) " ";
    max-height: 200px;
    white-space: pre-wrap;
    visibility: hidden;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    color: #000;
    border-radius: 4px;
    padding: 14px 12px;
    grid-area: 1 / 1 / 2 / 2;
    line-height: normal;
    overflow: hidden;
  }

  &__textarea {
    max-height: 200px;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    background: #ffffff;
    color: #000;
    border-radius: 4px;
    padding: 14px 12px;
    resize: none;
    overflow: auto !important;
    grid-area: 1 / 1 / 2 / 2;

    &--change-border-color {
      textarea {
        border-color: #D4DEF1 !important;
      }
    }

    &:focus {
      padding: 13.4px 11.4px;
      border: 1px solid #777777;
    }

    &:not(:placeholder-shown),
    &:focus {
      & + .fc-textarea__label {
        top: 0;
        left: 6px;
        font-size: 12px;
        padding: 0 5px;
      }
    }

    &:required + .fc-textarea__label {
      &::after {
        content: '*';
      }
    }

    &.disabled {
      color: #757575 !important;
    }

    &.invalid {
      border-color: #e20909 !important;

      & + .fc-textarea__label {
        color: #c20606 !important;
      }
    }
  }

  &__limit {
    position: absolute;
    bottom: 0px;
    right: 0;
    background-color: white;
    color: #757575;
    font-size: 12px;
    text-align: right;
  }

  &__label {
    position: absolute;
    top: 18px;
    left: 12px;
    color: #757575;
    background-color: #fff;
    border-radius: 4px;
    font-size: 14px;
    transform: translateY(-50%);
    transition: top 250ms, left 250ms, font-size 250ms, transform 250ms;
    pointer-events: none;
  }

  fc-error {
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
  }
}
