.vehicle-dialog__general-tab {
  &__input-groups {
    display: flex;
    grid-gap: 12px;
  }

  .select-disabled {
    background-color: red;
  }

  .download-link {
    display: none;
  }

  .fc-warning-container{
    height: 72px;
  }

  .warning-icon {
    height: 20px;
    width: 20px;
  }

  .image-container{
    display: flex !important;
    flex-direction: row !important;
  }

  .insert-picture {
    display: flex;
    flex-direction: column;
  }

  .image-input {
    width: 120px;
    height: 120px;
  }

  .insert-picture-button {
    margin-left: 14px;
    border: solid 1px #D4DEF1;
    color: rgb(9,87,195);
    font-weight: 500;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 188px;
    height: 48px;
  }

  &__main-container {
    display: grid;
    grid-gap: 25px;

    padding: 0 22px 14px 22px;
    margin-bottom: 100px;

    .mt-25 {
      margin-top: 25px !important;
    }

    &__vehicle-status {
      margin-top: 10px;

      &__bullet {
        display: block;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-right: 8px;

        &.available {
          background-color: #2BB98B;
        }

        &.unavailable {
          background-color: #a1a0a0;
        }
      }

    }
  }
}
