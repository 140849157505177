.authentication {
  height: 100%;
  overflow: hidden;

  .logo {
    color: #ffffff;
    height: 128px;
    line-height: 128px;
    margin-bottom: 32px;
    width: 128px;
  }

  &-intro {
    background: url('../assets/images/backgrounds/login-bg-3.png') 0 50% no-repeat;
    background-color: rgba(0, 0, 0, 0.65);
    background-size: cover;
    position: relative;

    .darkned {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;

      padding: 128px 80px;
      background-color: rgba($color: #000, $alpha: 0.3);
    }

    .title {
      color: white;
      font-size: 42px;
      font-weight: 300;
    }

    .description {
      color: white;
      font-size: 14px;
      max-width: 600px;
      padding-top: 8px;
    }
  }

  &-form-wrapper {
    background: #ffffff;
    display: flex;
    height: 100%;
    max-width: 51%;
    min-width: 416px;
    width: 51%;

    &-form {
      max-width: 480px;
      width: 100%;
      padding: 40px 48px;
      margin: auto;

      .logo {
        margin: 10px auto;
      }
      .title {
        font-size: 21px;
      }
      .description {
        padding-top: 8px;
      }

      .hint {
        margin-top: 32px;
      }

      .err-message-container {
        min-height: 40px;
        display: grid;
        place-items: center;

        .err-message {
          display: block;
          text-align: center;
          width: 100%;
        }
      }

      .google-row {
        .display-none {
          display: none;
        }

        min-height: 62px;
        height: 62px;
        max-height: 62px;
      }

      form {
        padding-top: 22px;
        text-align: left;
        width: 100%;

        .use-another-email {
          display: flex;
          justify-content: flex-end;
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 16px;
          text-align: right;
          color: #5c5c5c;
          cursor: pointer;
        }

        .use-another-email:hover {
          text-decoration: underline;
        }

        .md-input {
          border-radius: 3px;
          border: thin solid #d3ddf2;
          height: 60px;
          padding: 0 23px;
        }

        md-input-container {
          margin: 8px 0;
          position: relative;

          .md-icon-button {
            position: absolute;
            top: 12px;
            right: 8px;
          }

          input {
            &[disabled='disabled'] {
              color: gray;
            }
          }
        }

        md-input-container.md-input-focused .md-input,
        md-input-container .md-input.ng-invalid.ng-dirty,
        md-input-container.md-input-resized .md-input {
          border-width: thin;
        }

        md-checkbox {
          margin: 0;
        }

        .remember-forgot-password {
          font-size: 13px;
          margin-top: 16px;
          margin-bottom: 16px;
          display: flex;
          flex-direction: row;
          min-height: 20px;
          justify-content: space-between;

          .use-another-account {
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
          }

          .forgot-password {
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
          }
        }

        .submit-button {
          display: block;
          padding: 0;
          margin: 0;
          width: 100%;
        }

        .collapsed-input {
          height: 88px;
          max-height: 0px;
          transition: max-height 0.5s;
          overflow: hidden;

          &:not(.collapsed-input--opened) {
            padding: 0;
            margin: 0;
          }

          &--opened {
            max-height: 88px;
          }
        }
      }

      .separator {
        color: rgba(0, 0, 0, 0.54);
        font-size: 15px;
        font-weight: 600;
        margin: 24px auto 16px auto;
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 100px;

        .text {
          display: inline-block;
          padding: 0 8px;
          position: relative;
          vertical-align: baseline;
          z-index: 9999;

          &:before,
          &:after {
            border-top: thin solid rgba(0, 0, 0, 0.12);
            content: '';
            display: block;
            position: absolute;
            top: 10px;
            width: 30px;
          }

          &:before {
            right: 100%;
          }
          &:after {
            left: 100%;
          }
        }
      }

      .register {
        margin: 20px auto 5px auto;
        width: 250px;
        font-weight: 500;

        .text {
          margin-right: 8px;
        }
      }

      .links {
        .link {
          @media only screen and (max-width: $layout-breakpoint-sm) {
          }
        }
      }

      @media only screen and (max-width: $layout-breakpoint-sm) {
        text-align: center;
        padding: 24px;
      }
    }

    @media only screen and (max-width: $layout-breakpoint-sm) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }

  .google-button {
    border: thin solid #d3ddf2;
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    line-height: inherit;
    padding: 0 30px;
    text-transform: inherit;
    transition: background 250ms ease-in-out, border 250ms ease-in-out, color 250ms ease-in-out;
    width: 100%;

    .icon {
      border-right: thin solid #d3ddf2;
      height: 60px;
      line-height: 60px;
      margin: 0 30px 0 0;
      padding: 0 30px 0 0;
      transition: border 250ms ease-in-out;
      width: inherit;

      svg {
        display: block;
        margin-top: 20px;
      }

      @media only screen and (max-width: 959px) {
        padding-right: 5%;
      }
    }

    span {
      margin: 0 auto;
    }

    &:not([disabled]):hover {
      background-color: #d3ddf2 !important;
      border-color: darken(#d3ddf2, 10%);
      color: #0957c3;

      .icon {
        border-color: darken(#d3ddf2, 10%);
      }
    }

    @media only screen and (max-width: 959px) {
      padding: 0 5%;
    }
  }

  .submit-login {
    height: 60px;
  }
}
