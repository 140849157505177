.webhook-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;

  &__general {
    width: 100%;
    text-align: start;
    font-weight: 500;
  }

  &__input {
    width: 100%;

    .fc-input {
      &__input {
        padding-right: 8px !important;
      }

      &__toggle-password {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    &__limit {
      position: absolute;
      right: 0;
      background-color: white;
      color: #757575;
      font-size: 12px;
      text-align: right;
    }
  }

  &__radio-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__toggle-all {
      width: 100%;
      align-items: center;
      display: flex;
      border: 1px solid #d4def1;
      padding: 16px;
      border-radius: 4px;

      md-radio-button {
        margin-bottom: 0px !important;
      }
    }

    &__options {
      width: 100%;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border: 1px solid #d4def1;
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 0px !important;

      md-radio-button {
        margin-bottom: 0px !important;
      }

      &__list {
        padding: 16px;

        &__option {
          width: 100%;
          display: flex;
          padding: 8px;

          md-checkbox {
            margin-bottom: 0 !important;
          }

          &__content {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &__name {
              font-weight: 500;
            }

            &__description {
              color: #757575;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.close-button {
  height: 100% !important;
}
