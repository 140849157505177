.ms-stepper {

    .ms-stepper-vertical {
        position: relative;
        background: #FFFFFF;
        box-shadow: $whiteframe-shadow-1dp;

        .ms-stepper-vertical-line {
            display: block;
            position: absolute;
            width: 1px;
            top: 32px;
            bottom: 32px;
            left: 43px;
            border-left: 1px solid rgba(0, 0, 0, 0.12);
        }

        .ms-stepper-steps {
            padding: 8px 0;

            .ms-stepper-step {
                display: block;

                .ms-stepper-navigation-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;
                    width: 100%;
                    height: 72px;
                    min-height: 72px;
                    max-height: 72px;
                    line-height: 24px;
                    margin: 0;
                    padding: 0 24px;
                    border-radius: 0;
                    cursor: pointer;
                    font-size: 14px;
                    text-transform: none;

                    &:first-child {

                        &:before {
                            display: none;
                        }
                    }

                    &:last-child {

                        &:after {
                            display: none;
                        }
                    }

                    &:hover {

                        &:not(.disabled) {

                            .step {
                                background-color: rgb(236, 236, 236);
                                border-color: rgb(236, 236, 236);
                            }
                        }
                    }

                    &.current {

                        .title {
                            font-weight: 600;
                            color: rgba(0, 0, 0, 0.87);
                        }
                    }

                    &.disabled {
                        cursor: default;

                        .title {
                            color: rgba(0, 0, 0, 0.27);
                        }

                        .subtitle {
                            color: rgba(0, 0, 0, 0.27);
                        }
                    }

                    &.optional {
                        padding: 16px 24px;
                    }

                    .step {
                        width: 40px;
                        min-width: 40px;
                        height: 40px;
                        margin: 0 16px 0 0;
                        border-radius: 50%;
                        background: #FFFFFF;
                        border: 8px solid #FFFFFF;
                        color: #FFFFFF;
                        transition: border-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

                        .icon {
                            font-weight: bold;
                            line-height: 28px !important;
                        }
                    }

                    .title {
                        padding-right: 16px;
                        color: rgba(0, 0, 0, 0.54);
                    }

                    .subtitle {
                        font-size: 12px;
                        padding-right: 16px;
                        color: rgba(0, 0, 0, 0.54);
                        line-height: 1;
                    }
                }

                .ms-stepper-step-content {
                    padding: 0 24px 16px 64px;
                }

                .ms-stepper-controls {
                    padding: 0 16px 16px 56px;
                }
            }
        }
    }
}