.integration-dialog {
  width: 600px;

  .fs-17 {
    font-size: 17px;
  }

  .fs-16 {
    font-size: 16px;
  }

  .md-list-item-text {
    vertical-align: middle;
  }

  .md-list-item-text p {
    display: flex;
    align-items: center;
  }

  .md-list-item-text p i {
    margin-right: 4px;
  }

  md-dialog-content {
    height: 100%;
  }

  md-dialog-actions {
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03);
  }

  .main-loader {
    width: 100%;
    height: 100%;
    min-height: 330px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
  }

  .main-loading-description {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-top: 30px;
    color: #464646;
  }
}