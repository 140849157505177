#mail {
  .center {
    .content-card {
      &.outlook {
        .content-wrapper {
          background: #fafafa;

          .thread-list {
            padding-bottom: 109px !important;
            background: #fafafa;
            position: relative;
            padding: 0;
            border-right: 1px solid rgba(0, 0, 0, 0.12);

            .no-threads {
              background: #fafafa;
              width: 100%;
              text-align: center;
              padding: 32px;
              font-size: 20px;
            }

            .loading-threads {
              background: #fafafa;
              width: 100%;
              text-align: center;
              padding: 32px;
              font-size: 20px;
            }

            .thread {
              background: #fafafa;
              position: relative;
              padding: 16px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.12);
              cursor: pointer;

              &.unread {
                background: #ffffff;

                .info {
                  .name,
                  .subject {
                    font-weight: 700;
                  }

                  .message {
                    .labels {
                      background: #ffffff;
                    }
                  }
                }
              }

              &.selected {
                background: #fff8e1;

                .info {
                  .message {
                    .labels {
                      background: #fff8e1;
                    }
                  }
                }
              }

              &.current-thread {
                background: #e3f2fd;

                .info {
                  .message {
                    .labels {
                      background: #e3f2fd;
                    }
                  }
                }
              }

              .info {
                overflow: hidden;
                width: 0;
                margin: 0 16px;

                .name {
                  font-size: 15px;
                  font-weight: 500;

                  .avatar {
                    min-width: 32px;
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                  }

                  .has-attachment {
                    margin-left: 8px;
                    transform: rotate(90deg);
                  }
                }

                .date-time-status-container {
                  gap: 7px;
                  display: grid;
                  align-items: center;

                  .time {
                    text-align: center;
                    font-size: 13px;
                    line-height: 15px;
                    color: #000000;
                  }

                  &__on-negotiation, &__expired {
                    border-radius: 4px;
                    padding: 0px 8px;
                    text-align: center;

                    &__text {
                      font-size: 12px;
                      line-height: 19px;
                    }
                  }

                  &__on-negotiation {
                    background: #439BE6;
    
                    &__text {
                      color: #FFFFFF;
                    }
                  }

                  &__expired {
                    background-color: #9E9E9E !important;

                    &__text {
                      color: rgba(0, 0, 0, 0.87);
                    }
                  }
                }

                .message {
                  position: relative;
                  color: rgba(0, 0, 0, 0.54);
                }
              }
            }
          }
        }
      }
    }
  }
}

.tickets-list {
  .tickets-list-item {
    &__check {
      &--blocked {
        cursor: not-allowed;
      }
    }
  }
}

.pb-8 {
  padding-bottom: 8px;
}
