#account-info {

  .autocomplete-address {
    margin-bottom: 24px;
  }

  .clear-address {
    padding: 0 !important;
    margin-top: 20px !important;
  }

  .no-text-decoration {
    text-decoration: none;
  }

  .text-grey {
    color: grey;
  }

  .save-fixed-button {
    position: fixed;
    top: 90%;
    right: 6%;
  }

  .avatar-image {
    position: relative;
    width: 200px;
    height: 200px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }

    md-icon {
      position: absolute;
      bottom: 10px;
      right: 10px;
      color: white;
      opacity: 0;
      transition: opacity 250ms;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: black;
      opacity: 0;
      transition: opacity 250ms;
    }

    &:hover {

      md-icon {
        opacity: 1;
      }

      &::before {
        opacity: 0.5;
      }
    }
  }

  .geocoder-progress-container {
    max-width: 40px;
    margin: 0 16px;
  }
}
