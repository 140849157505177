.filter-sidenav {
  .filter-text {
    padding: 0px 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    color: #757575;
    font-weight: 400;
  }

  .btn {
    width: 266px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }

  .equipment-name {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .filter-property-name {
    padding-left: 10px;
    height: 25px;
    color: rgba(0, 0, 0, 0.54);
  }

  .pl-10 {
    padding-left: 10px !important;
  }

  .input-border {
    border: 1px solid #ccc !important;
  }

  .w-100-percent {
    width: 100% !important;
  }

  .bg-grey {
    background: #ccc;
  }

  .mt-3 {
    margin-top: 3px;
  }

  .star-color {
    color: #ffb508 !important;
  }

  .ml-15n {
    margin-left: -15px;
  }

  .mt-6 {
    margin-top: 6px;
  }

  .mt-25n {
    margin-top: -25px;
  }

  .mt-40n {
    margin-top: -40px;
  }

  .custom-filter-input-name {
    margin-left: 17px;
    padding-left: 10px;
    height: 35px;
    border: 1px solid #ccc;
  }

  .hide-radio-button {
    .md-container.md-ink-ripple {
      display: none;
    }
  }

  md-checkbox {
    margin-left: 12px;
  }

  .status-indicator {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 8px;
    min-width: 8px;
    min-height: 8px;
  }

  .dropdown-menu {
    max-height: initial !important;
  }

  .stars-row{
    max-width: 140px !important;
    display: flex;
    align-items: center;
  }

  .rating-name{
    padding-top: 3px;
  }
}

.text-left {
  text-align: left !important;
}

.mr-n10 {
  margin-right: -10px !important;
}

.header-searchbox {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0;
}

.select-header {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  padding-left: 16px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  width: auto;
}

md-input-container {
  width: 100%;
}
