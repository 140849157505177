.recurrence-dialog {
  md-toolbar {
    .mt-5n {
      margin-top: -5px;
    }

    span.title {
      margin-top: 10px !important;
    }
  }

  .no-margin-bottom {
    margin-bottom: 0px !important;
  }

  .md-dialog-content-recurrences {
    min-height: 300px;
  }

  .table-navigation {
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 0;

    &__button {
      border-radius: 4px !important;
    }

    &__info {
      color: #7f7f7f;
    }
  }

  .occurrences-table-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 2;

    md-progress-circular {
      position: absolute;
      left: 50%;
      top: 230px;
      transform: translate(-50%, -50%);
    }
  }

  form {
    md-dialog-content {
      overflow: auto;
      md-tab-content {
        padding: 24px;
      }

      md-tabs {
        .mt-27 {
          margin-top: 27px;
        }

        .ml-18 {
          margin-left: 18px;
        }

        .ml-21 {
          margin-left: 21px;
        }

        .mr-3 {
          margin-right: 3px;
        }

        .fs-18 {
          font-size: 18px;
        }

        .pb-28 {
          padding-bottom: 28px;
        }

        .h-7 {
          height: 7px;
        }

        .switch-button {
          margin-top: 2px;
          margin-bottom: 7px;
        }

        .employee-input-margin {
          margin: 0px 0px 6px 0px;
        }

        .color-grey {
          color: #585858;
        }
      }
    }

  }

  #occurrences-table {
    height: 100%;

    .md-datepicker-input-container {
      width: 100px;
    }

    .actions-column {
      max-width: 70px;
      text-align: center;
    }

    thead tr th {
      min-width: 85px !important;
    }

    md-datepicker {
      padding-right: 0px !important;
    }

    .occurrences-time-row {
      width: 50px !important;
      min-width: 50px !important;

      md-input-container {
        margin: 0px 0px 0px 0px !important;
        margin-top: 17px !important;
      }
    }

    table.simple tbody tr td {
      padding: 0 10px;

      &:first-child {
        padding-left: 30px;
      }
    }

    table.simple thead tr th {
      padding: 16px 10px;

      &:last-child {
        padding-left: 30px;
      }
    }

    .plans-table {
      border-radius: 10px 10px 0 0 !important;
    }
  }

  .hint-warning {
    margin-left: 5px;
  }

  .datatable-pagination-button {
    background: white;
    color: #333;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 12px;
    cursor: pointer;
    font-weight: 400;
    float: right;
  }

  .link-action {
    color: #0957c3;
    cursor: pointer;

    .create-task:hover {
      color: #0957c3 !important;
    }
  }

  .load-more-btn {
    cursor: pointer;
    color: #0957c3;
    font-size: 13px;
    text-decoration: none;
    padding: 10px 25px;
    border: 1px solid #0957c3;
    border-radius: 5px;
    text-align: center;
  }

  md-input-container .md-input[disabled] {
    background-position: 1px bottom;
  }
}

md-select-value,
.inactive-archived-item {
  .inactive {
    max-width: 80%;
    width: 80%;
    color: grey;
    text-decoration: line-through;
  }

  .caption {
    font-size: 0.65em;
    font-weight: bold;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 50%;
    transform: translate(0, -50%);
    color: grey;
  }

  .inactive-label {
    right: 20px;
  }
}

.table-navigation-dialog {
  padding: 14px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  border: 1px solid #d3ddf2;
  border-top: none;
  border-radius: 0 0 10px 10px;
  width: 100%;

  &__info {
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.52);
  }

  &__button {
    font-weight: 500;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.77);
    margin: 0 2px;
    padding: 9px 12px;

    &:hover:not([disabled]) {
      box-shadow: inset 999em 0 0 0 rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.77);
      border: 1px solid rgba(0, 0, 0, 0.12);
      background: none;
      cursor: pointer;
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.54);
      border-color: rgba(0, 0, 0, 0.12);
    }
  }
}

md-select-value {
  width: 100%;
  position: relative;
}

.inactive-archived-item .md-text {
  width: 100%;
  position: relative;
}

.mr-n5 {
  margin-right: -5px !important;
}

.ml-140 {
  margin-left: 140px;
}

.hyperlink {
  cursor: pointer;
  color: #0957c3;
  text-decoration: underline;
}

.fs-13 {
  font-size: 13px;
}

.mt-27 {
  margin-top: 27px;
}

.mw-50 {
  min-width: 50px !important;
}

.duration {
  font-size: 12px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-smooth {
  scroll-behavior: smooth;
}
