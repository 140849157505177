.limit-badge {
  padding: 2px 12px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: #0055FF;
  border-radius: 25px;

  &__inverse {
    color: #0055FF !important;
    background-color: white;
  }
}

.limit-badge-text {
  display: flex;
  align-items: center;

  md-icon {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
  }

  span, button {
    font-size: 12px;
    color: rgba(0,0,0,0.87);
  }

  button {
    color: #0055FF;
    text-decoration: underline;
  }
}
