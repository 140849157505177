#fc-color-picker {
    width: 100%;

    .color-value {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding-top: 5px;

        .color-selected {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            box-shadow: 0px 0px 5px #c0c0c0;
        }

        input {
            margin-left: 4px;
        }
    }

    .colors {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .color {
            display: flex;
            margin: 2.5px 0;

            button {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin: 5px 4px;
                box-shadow: 0px 0px 5px #c0c0c0;
            }
        }
    }
}