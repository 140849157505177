.control-panel-map {
  div.angular-leaflet-map {
    img.employee-marker {
      width: 40px;
      max-width: 40px;
      height: 40px;
      max-height: 40px;
    }

    div.marker-pin {
      position: absolute;
      top: 28px;
      left: 28px;
      width: 15px;
      height: 15px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      z-index: 9999999;
    }

    div.online-pin {
      background: #00c853;
    }

    div.offline-pin {
      background: #d50000;
    }

    .leaflet-div-icon {
      background: none !important;
      border: none !important;
    }

    .company-logo {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: #1e8ee8;
      box-shadow: 0 0 0 8px rgba(5, 155, 229, 0.5);
    }

    .legend {
      .outline:nth-child(13) {
        i {
          background: #1e8ee8;
          border-radius: 100%;
          width: 8px;
          height: 8px;
          margin-left: 4px;
          margin-top: 4px;
          margin-right: 11px;
          box-shadow: 0 0 0 4px rgba(5, 155, 229, 0.5);
        }
      }
    }
  }
}

.fix-label {
  line-height: 15px;
  width: 100%;
}

