@import './../../quotations.scss';

md-content {
  background-color: white !important;
}

.filter-quotations {
  md-list-item {
    margin: 18px 0;
  }

  .border {
    margin: 18px 16px;
    padding: 14px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    md-list-item {
      margin: 0;
      padding: 0;
    }

    .fc-datepicker {
      md-datepicker {
        flex-direction: row-reverse;
        display: flex;
        align-items: center;
        justify-content: space-between;

        md-icon {
          color: #135AC0;
          margin: unset;
        }

        .md-datepicker {
          &-button {
            width: 100%;
            z-index: 5;
            display: flex;
            justify-content: flex-end;
            margin: auto;

            &:focus {
              background-color: transparent;
            }
          }

          &-input {
            padding: 0;
            cursor: default;
            pointer-events: none;
          }

          &-triangle-button {
            top: 55%;
          }

          &-expand-triangle {
            left: auto;
          }

          &-input-container {
            left: 0;
          }
        }
      }
    }
  }
}

.date-component {
  label {
    color: #6D6D6D;
    width: 34px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.w-100-percent {
  width: 100% !important;
}
