.employees-list {

    .employee-list-title {
        padding-bottom: 10px;
    }

    .employee-item {

        padding-bottom: 8px;

        .username {
            font-size: 14px;
        }

        .device-info {
            font-size: 13px;
        }

        .device-info-loader {
            width: 20px;
            margin-top: -12px;
            margin-left: 5px;
        }
    }
}

#config-wizard {

    font-family: Roboto;
    line-height: 1.5;
    color: #58595b;

    a {
        text-decoration: none;
    }

    .navigation-container .md-button {
        padding: 5px 40px;
        border-radius: 3px;
        font-weight: bold;
        font-size: 14px;
    }

    .navigation {
        margin: 30px 16px;
    }

    md-tab-content {
        .explanation {
            padding-bottom: 20px;
        }

        .explanation .content {
            margin-top: 20px;
        }

        .md-text {
            width: 287px;
            margin-bottom: 24px;
        }

        .content-box {
            border-radius: 3px;
            border: solid 1px #d3ddf2;
        }

        .app-download-container {
            max-width: 40%;
        }

        .app-download-link div {
            display: flex;
            align-items: center;
        }

        .app-download-link span {
            margin-left: 49.5px;
        }

        .app-download-link {
            width: 317px;
            min-width: 317px;
            text-transform: uppercase;
            font-size: 12.8px;
            font-weight: bold;
            line-height: 1.88;
            letter-spacing: 0.8px;
            color: #58595b;
            padding: 1.2rem 3rem;
            margin-bottom: 22px;
        }
    }

    md-tabs-content-wrapper {
        top: 72px !important;
    }

    md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
        min-height: 100%;
    }

    .controls-wizard {
        position: absolute;
        bottom: 5px;
        right: 0;
        padding: 0 16px;
        background-color: white;
    }

    .navigation-container {
        position: relative;
        border-top: solid 1px #d3ddf2;
        background-color: white;
        bottom: 66px;
        height: 66px;
    }

    .clear-address {
        padding: 0 !important;
        margin-top: 20px !important;
    }

    .ms-form-wizard-form {
        padding: 10px 16px 5px 16px;
    }

}
