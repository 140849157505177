.fc-time-input {
  position: relative;
  height: 48px;
  width: 197px;

  &__title {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    background-color: #FFFFFF;
    padding: 0 4px;
    margin: 0 8px;
    color: #757575;
    font-size: 12px;
    transition: all 0.3s;
  }

  .center-time-value {
    transition: all 0.3s;
    font-size: 15px;
    transform: translateY(65%);
  }

  &__icon {

    svg path {
      fill: #135AC0;
    }

    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    margin: 0 12px;
  }

  &__input {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 16px 12px;
    border: 1px solid #D4DEF1;
    border-radius: 4px;
    height: 48px;
    width: 197px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }
}

.invalid-time {

  .fc-time-input {
    &__input {
      border-color: #d50000;
    }

    &__title {
      color: #d50000;
    }

    &__icon svg path {
      fill: #d50000;
    }
  }
}