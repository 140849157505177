.fc-toast {
  padding: 14px 13px;
  width: 377px;
  background-color: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border: transparent;
  border-radius: 6px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  z-index: 9999;
  display: flex;
  align-items: flex-start;

  &.success {
    width: 378px;
    height: 60px;
    align-items: center;
  }

  &.TOP {
    top: 40px;
  }

  &.BOTTOM {
    bottom: 40px;
  }

  &.RIGHT {
    right: 40px;
  }

  &.LEFT {
    left: 40px;
  }

  &.CENTER {
    right: 30%;
    top: 40%;
  }

  &.HIDDEN {
    opacity: 0;
    z-index: -1 !important;

    &.RIGHT {
      right: 0px;
    }

    &.LEFT {
      left: 0px;
    }
  }

  &__icon-generic {
    svg {
      height: 20px;
      width: 20px;
    }

    .centralized {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__title-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 14px;
    width: 290px;

    &__title {
      font-weight: 500;
      font-size: 15px;
      color: #313131;
      line-height: 19.5px;
      margin: 0;

      &.centralized {
        margin-top: 3px;
      }
    }
  }

  &__content {
    font-weight: 400;
    font-size: 13px;
    color: #acacac;
    line-height: 16.9px;
    margin-top: 8px;
  }

  &__icon-close {
    cursor: pointer;

    &:hover {
      color: #575757;
    }
  }
}
