


.general-body {
  padding: 16px !important;
  margin-top: 10px !important;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .info-div {
    width: 100% !important;
    padding: 16px !important;
    background-color: #F1F5FC !important;
    border-radius: 8px !important;
    display: flex !important;
    flex-direction: row;
    gap: 12px !important;
    align-items: center !important;

    .info-data {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .container-information {
    border: 1px solid #D4DEF1;
    width: 100% !important;
    padding: 16px !important;
    border-radius: 8px !important;
    display: flex !important;
    justify-content: space-between;
    flex-direction: row;
    gap: 12px !important;
    align-items: center !important;

    .bolding-text {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
    }

    .graying-text {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      color: #757575;
    }

    .bullet-pointer {
      font-size: 12px !important;
      font-family: Roboto;
      font-weight: 600;
      background-color: blue;
      border-radius: 100px;
      padding: 4px 8px;
      color: white !important;
    }
  }

  .container-reinformation {
    font-size: 12px !important;
    border: 1px solid #D4DEF1;
    width: 100% !important;
    padding: 16px !important;
    border-radius: 8px !important;
    display: flex !important;
    justify-content: space-between;
    flex-direction: row;
    gap: 12px !important;
    align-items: center !important;

    .bolding-text {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
    }

    .graying-text {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      color: #757575;
    }

    .hire-button {
      cursor: pointer;
      background-color: white;
      border-radius: 6px;
      padding: 12px 16px;
      color:#135AC0 !important;
      border: 1px solid #D4DEF1;
    }

    .hire-text {
      font-family: Roboto;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .config-container {
    height: 90px !important;
    border: 1px solid #D4DEF1;
    width: 100% !important;
    padding: 16px !important;
    border-radius: 8px !important;
    display: flex !important;
    flex-direction: row;
    gap: 12px !important;
    align-items: center !important;

    .config-text {
      color: #9E9E9E;
      font-family: Roboto;
      font-size: 14px;
    }
  }
}



.footer-data {
  border: none !important;
  font-family: Roboto !important;
  font-size: 14px;

  .hidden {
    display: none !important;
  }

  .footer-info {
    margin-top: 4px;
  }
}
