#optimizer {
  .leaflet-map-pane .leaflet-marker-icon i.icon {
    position: relative;
  }

  .leaflet-map-pane .leaflet-marker-icon i.icon .indice {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 62%;
    background: #fff;
    border-radius: 50%;
    color: blue;
    font-size: 12px;
    font-family: "Arial";
    font-weight: bold;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  md-tabs-content-wrapper._md {
    top: 70px;
    border-top: 1px solid #d3ddf2;
    border-bottom: 1px solid #d3ddf2;
  }

  md-tabs {
    height: calc(100% - 80px);

    .ms-form-wizard-step-label .ms-form-wizard-step-text.step-blue-color {
      color: #0957c3;
    }

    .p-24 {
      padding: 24px;
    }

    md-tab-content:last-of-type {
      & > div {
        height: 130% !important;
      }
    }
  }

  .navigation {
    height: 80px;

    .confirm-button {
      width: 112px !important;
    }
  }

  .router-options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 18px;
    background-color: #e8e8e8;
  }

  .router-options .custom-search-icon {
    display: inline-flex;
    margin: 0 12px 0 0;
  }

  .router-options .custom-search-input {
    display: inline-flex;
    background-color: transparent;
    width: 280px;
  }

  .router-options md-input-container {
    margin: 0;
  }

  .router-options md-input-container .md-errors-spacer {
    display: none;
  }

  .router-options md-checkbox {
    margin-bottom: 0;
  }

  .router-advanced {
    width: 100%;
    padding: 16px;

    .router-advanced-div {
      border-radius: 8px;
      height: 80px;
      width: 100%;
      background-color: #F1F5FC;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      padding: 16px;

      .isights-icon {
        color: #135AC0
      }

      .router-text {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        width: 430px !important;
      }

      .load-more-button {
        cursor: pointer;
        background-color: white;
        border-radius: 4px;
        padding: 12px 16px;
        color:#135AC0 !important;
        border: 1px solid #D4DEF1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);


        .hire-text {
          font-family: Roboto;
          font-weight: 500;
          font-size: 14px;
          text-wrap: nowrap;
        }
      }

      .upgrade-button {
        cursor: pointer;
        background-color: #EA1453;
        border-radius: 4px;
        padding: 12px 16px;
        color:white !important;
        border: 1px solid #D4DEF1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);

        .hire-text {
          font-family: Roboto;
          font-weight: 500;
          font-size: 14px;
          text-wrap: nowrap;
        }
      }

      .closing-icon {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }


  .router-filters {
    margin-top: 24px;
    border: 1px solid #D4DEF1;
    border-radius: 4px;
    padding: 0px 20px 18px 20px;

    &__filter {
      &__content {
        div.fc-datepicker {
          height: 48px;
          width: 200px;
        }

        &__field {
          position: relative;
          height: 48px;
          width: 227px;
          margin-left: 12px;

          input {
            height: 100%;
            border: 1px solid #D4DEF1;
            font-size: 14px !important;
            width: 100%;
            border-radius: 4px;
            text-align: center;
            color: #9E9E9E;
          }

          &--date {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #D4DEF1 !important;
            width: 94.71px;
            height: 38px;
            border-radius: 4px;
            padding-left: 10px;

            md-checkbox {
              width: 100% !important;
              height: 100% !important;
              margin-bottom: 0px !important;
              font-size: 13px !important;

              div.md-label {
                margin-left: 25px;
              }
            }
          }
        }

        &--grid {
          display: grid !important;
          grid-template-columns: repeat(7, 1fr);
          gap: 8px;
        }
      }
    }
  }

  .md-list-tasks h3 {
    margin: 0;
  }

  .md-list-tasks h4 {
    margin: 0;
    color: #777777;
  }

  .configuration-block-title {
    display: block;
    width: 100%;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .radio-button-description,
  .checkbox-button-description {
    display: block;
    width: 100%;
    font-size: 13px;
    color: #777777;
  }

  .map-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .map {
    border-radius: 4px !important;
    &__description {
      padding: 0 16px;
      border: 1px solid #D4DEF1;
      border-radius: 0px 0px 4px 4px;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        height: 10.5px;
        width: 10.5px;
        margin: 0 4px;
      }
      p {
        font-size: 13px !important;
        color: #757575 !important;
      }
    }

    &__content {
      border-radius: 4px 4px 0 0;
    }
  }

  .map-kilometers {
    padding: 16px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #d3ddf2;

    &--no-width {
      width: auto;
    }

    &--no-border-top {
      border-top: none;
    }

    &__text {
      font-size: 14px;
      color: #262626;
    }

    &__km {
      font-size: 14px;
      font-weight: 500;
      color: #0957c3;
      margin-left: 3px;
    }
  }

  .endlocation-marker {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #1e8ee8;
    box-shadow: 0 0 0 8px rgba(5, 155, 229, 0.5);
  }

  .destination-block {
    width: 100%;

    .search-custom-location {
      width: 100%;
      padding-bottom: 20px;
    }

    .address {
      display: inline-block;
      font-size: 12px;
      color: #2957c3;
    }
  }

  .height-100p {
    height: 100%;
  }

  .height-50p {
    height: 50%;
  }

  .fc-button--back {
    color: white !important;

    &:hover {
      background-color: #dfdfdf !important;
      color: #0f52af !important;
    }
    &:disabled {
      background-color: #d7d7d7 !important;
      color: #707070 !important;
    }
  }
}

md-checkbox {
  &.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
    background-color: rgba(9,87,195,0.87) !important;
  }

  .md-container .md-icon {
    width: 16px;
    height: 16px;
    top: 2px !important;

    &:after {
      left: 2.78px;
      top: -2px;
    }
  }
}

md-checkbox

md-dialog.form-dialog md-toolbar .md-icon-button.margin-top-0 {
  margin-top: 0 !important;
}
