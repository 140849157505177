.map-dialog {
  &__map {
    position: absolute;
    top: 80px;
    bottom: 35px;
    left: 20px;
    right: 20px;
    z-index: 10;
    background-color: orange;
    transform: translateY(0%);
  }

  &__coordinates {
    position: absolute;
    left: 20px;
    bottom: 15px;
  }
}
