.fc-button {
  font-weight: 500;
  color: white;
  box-shadow: none;
  border-radius: 5px;
  margin: 0;
}

.fc-tooltip {
  font-size: 16px;
  font-weight: normal;
  height: 32px;
  line-height: 32px;
  padding-right: 16px;
  padding-left: 16px;
  color: #fafafa !important;
  background-color: #9e9e9e !important;
}

.plan-sidenav {
  &__description {
    width: 100%;

    &--expanded {
      width: 100%;
      height: 100%;
    }
  }

  &__button-container {
    padding: 16px;
    margin-top: 50px;
    width: 100%;
    height: 75px;
    border-top: thin solid rgba(0, 0, 0, .12);
    background-color: rgba(0, 0, 0, .03);

    .disable-button {
      background-color: #858fa4 !important;
      color: rgba(255, 255, 255, .87) !important;
    }
  }
}

#plan {


  &.page-layout.carded.fullwidth {

    > .center {
      margin-bottom: 26px;


      .content-card {
        border-radius: 10px;
        border-bottom: thin solid #d3ddf2;

        .content {
          min-height: 100%;
        }
      }
    }
  }

  #employees {
    .ct-series-a line {
      stroke: #12b886;
    }

    .ct-series-b line {
      stroke: #d3ddf2;
    }
  }

  #storage {
    .ct-series-a line {
      stroke: #0957c3;
    }

    .ct-series-b line {
      stroke: #f9bb42;
    }

    .ct-series-c line {
      stroke: #d3ddf2;
    }
  }

  #benefits {
    .upgrade-button-container {
      min-width: 160px;
      width: 13%;
    }
  }

  .fx {
    display: flex;
  }

  .fx-column {
    flex-direction: column;
  }

  .fx-row {
    flex-direction: row;
  }

  .fx-space-between {
    justify-content: space-between;
  }

  .text-right {
    text-align: right;
  }

  .icon-check {
    color: #12b886;
  }

  .icon-close {
    color: #ff3365;
  }

  .fw-500 {
    font-weight: 500;
  }

  .mr-4 {
    margin-right: 4px;
  }

  .black {
    color: black;
  }

  .gray {
    color: #9e9e9e;
  }

  .circle {
    width: 8px;
    height: 8px;
    border-radius: 5px;
    margin-right: 5px;
  }

  .images {
    background-color: #0957c3;
  }

  .videos {
    background-color: #f9bb42;
  }

  .box {
    border: thin solid #d3ddf2;
    border-radius: 6px;
    min-width: 150px;
    max-width: 100%;
    width: 100%;
    padding: 16px;

    &.renewal-alert {
      color: white;
      background-color: #ff3365;
      border-color: #ff3365;

      .box__header md-icon,
      .box__footer .link {
        color: white;
      }
    }

    &__header {
      .title {
        font-weight: 500;
        font-size: 14px;
      }

      md-icon {
        margin: 0;
      }
    }

    .chart {
      width: 70%;
      color: #9e9e9e;

      .legend {
        font-size: 12px;
        color: rgba(0, 0, 0, .8);
      }
    }

    .description {
      width: 20%;
      font-weight: 500;
      align-items: center;

      .upgrade-description {
        font-size: 12px;
        font-weight: normal;
        color: #9e9e9e;
      }
    }

    .upgrade-button-container {
      width: 10%;
    }

    .benefits-items-container {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      height: 100%;

      &>div {
        width: 50%;
      }

      .center {
        align-items: center;
      }
    }

  }


  .plan-container {
    min-height: 180px;
    max-height: 295px;
    height: 40%;

    .box {
      &:not(:last-child) {
        margin-right: 20px;
      }

      &__content {
        font-weight: 500;

        &.due-date {
          text-transform: capitalize;
          font-size: 40px;
        }

        &.plan-cost {

          .currency {
            font-size: 22px;
            color: #0957c3;
            padding: 2px;
          }

          .value {
            font-size: 40px;
            color: #0957c3;
            padding: 2px;
          }

          .period {
            font-size: 18px;
            font-weight: 500;
            color: #9e9e9e;
            padding: 2px;
          }
        }

        &.details {
          justify-content: space-evenly;
          margin-top: 20px;
          margin-bottom: 26px;

          &>div {
            width: 100%;

            span {
              font-size: 14px;
              font-weight: 500;

              &:first-child {
                color: #9e9e9e;
                font-weight: normal;
              }
            }
          }
        }
      }

      &__footer {
        text-align: right;

        .link {
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 500;
          color: #0957c3;
          cursor: pointer;
        }
      }
    }
  }
}

.subscription {

  &__overview,
  &__modules,
  &__cancel-account {
    .header-title {
      color: #262626;
      font-size: 15px;
      font-weight: 500;
    }

    .header-description {
      color: #757575;
      font-size: 14px;

      address {
        color: #0957c3;
      }
    }
  }

  &__overview {

    .header-title {
      margin-bottom: 30px;
    }

    &__boxs {
      display: flex;
      justify-content: space-between;
      gap: 18px;
    }
  }

  &__modules {
    margin: 30px 0;

    &__header {
      margin-bottom: 30px;
    }

    &__items {
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
  }
}

.content-card {
  border-radius: 10px;
  border: thin solid #d3ddf2;

  &__warning {
    display: flex;
    justify-content: center;
    margin: 20px 20px;
    padding: 50px 30px;
    border-radius: 4px;
    border: thin solid #d3ddf2;

    &__title {
      font-size: 17px;
      font-weight: 500;
    }

    &__subtitle {
      color: #788598;
      font-size: 14px;
    }

    &__button {
      background-color: #FC3868;
      width: 226px;
      height: 54px;
      margin-left: 75px;
      transition: all 0.3s ease;
    }

    &__button:hover {
      background-color: #FC3868;
      opacity: 0.8;
    }
  }
}

.button__disabled {
  background-color: #E0E0E0 !important;
  color: #757575;

  &:hover {
    color: #757575 !important;
    background-color: #E0E0E0 !important;
    opacity: 1;
  }
}
