.fc-warning-container {
  padding: 0 26px;
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  background-color: #f1f5fc;
  font-size: 14px;

  &--red {
    background-color: #fde1e1 !important;
  }

  &--blue {
    background-color: #f1f5fc;
  }

  &--large {
    height: 95px;
  }

  &--inside-tab {
    margin-top: -24px;
    margin-left: -24px;
    width: calc(100% + calc(24px * 2));
  }
}
