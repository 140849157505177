@mixin tooltip-style($background, $borderColor, $color) {
  background-color: $background !important;
  border: $borderColor 2px solid;
  color: $color !important;
  padding: 15px;
}

.tooltip {
  font-size: 11px;
  display: table;

  .icon {
    color: #ffffff;
  }
}

.scheduled {
  @include tooltip-style(#105BFB, darken(#105BFB, 10%), #ffffff);
}

.in-progress {
  @include tooltip-style(#E4771F, darken(#E4771F, 10%), #ffffff);
}

.done {
  @include tooltip-style(#24B787, darken(#24B787, 10%), #ffffff);
}

.canceled {
  @include tooltip-style(#C25AB5, darken(#C25AB5, 10%), #ffffff);
}

.reported {
  @include tooltip-style(#F84547, darken(#F84547, 10%), #ffffff);
}

#calendar {

  .calendar-feedback {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #D4DEF1;
    margin-bottom: 16px;
    margin-top: 6px;
    margin-left: 1px;
    border-radius: 8px;
    background-color: transparent !important;
  }

  .fc-day-header {
    color: #767676;
  }

  .calendar-header {
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    padding: 10px 7px;

    &__period-navigation {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: start;
      font-size: 14px;
    }

  }

  .fc-time-grid-container {
    height: initial !important;
  }

  .fc-row {
    &.fc-week {
      &.fc-widget-content {
        margin-right: initial !important;
      }
    }
  }

  .fc-widget-header {
    border: 0;

    th {
      padding: 0 0 7px 0;
      border: 0;
    }
  }

  #calendarView {
    padding: 0 15px;
    max-height: 100%;
  }

  // Day view
  .fc-agendaDay-view {
    .fc-time-grid {
      min-height: inherit;
      overflow: auto;
      width: 100%;
    }

    .fc-event {
      color: #ffffff;
      padding: 2px 4px;
      font-weight: 600;
    }

    .avatar-icon{
      display: none;
    }
  }

  // Week view
  .fc-agendaWeek-view {
    .fc-time-grid {
      min-height: inherit;
      overflow: auto;
      width: 100%;
    }

    .fc-event {
      color: #ffffff;
      padding: 2px 4px;
      font-weight: 600;
    }

    .avatar-icon{
      display: none;
    }
  }

  // Month view
  .fc-month-view {
    .fc-event {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      font-size: .85em;
      font-weight: 600;
      margin: 0;
      padding: 2px 4px;

      .fc-title {
        color: #ffffff;
        float: left;
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .avatar-icon{
        position: absolute;
        right: -9px;
        top: -1px;
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        border-radius: 50%;
      }

      .fc-time {
        color: #ffffff;
        float: left;
        margin-right: 5px;

        & + .fc-title {
          max-width: 65%;
        }
      }

      &-container {
        padding: 1px 12px;
      }
    }

    .fc-more {
      box-sizing: border-box;
      padding-left: 12px;
    }

    .fc-day-grid-container {
      overflow: hidden;
    }

    .fc-day-number {
      padding-right: 12px;
    }

    .fc-day-grid {
      overflow: auto;
      width: 100%;
    }
  }

  // modal view more
  .fc-popover {
    .fc-header {
      background: #039be5;
      color: #ffffff;
      font-weight: 600;
      padding: 5px 12px;

      .fc-close {
        background-color: #039be5;
        color: #ffffff;
        font-size: .9em;
        line-height: 1.2em;
        margin-top: 3px;
        height: 1em;
      }
    }

    .fc-day-grid-event {
      margin: 3px 0;
    }

    .fc-body {
      overflow: hidden;
    }

    .fc-event-container {
      max-height: 45vh;
      overflow: auto;
    }
  }
}

.content-card {
  .calendar-wrapper {
    overflow-y: scroll;
    height: calc(100vh - 250px);
    border-bottom: 1px solid #dddddd;
    position: relative;

    &__loading-calendar-task {
      position: absolute;
      top: 0;
      bottom: 0;
      height: calc(100vh - 250px);
      width: 100%;

      &__progress-circular{
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &::after {
        content: '';
        z-index: 2;
        position: absolute;
        top: 0;
        bottom: 0;
        height: calc(100vh);
        width: 100%;
        background-color: rgba(255,255,255,0.8);
      }
    }
  }
}
