.fc-icon-button {
  margin: 0;
  padding: 0;
  min-height: auto;
  min-width: auto;
  line-height: normal;
}

.fc-icon {
  cursor: pointer;
  background-color: white;
  height: 100%;
}

.t-24 {
  position: relative;
  top: 24px;
}

#due-date-sidenav {
  width: 450px !important;

  .p-0 {
    padding: 0;
  }
}

.plan-sidenav {
  .display {
    visibility: visible;
  }

  .fc-yellow {
    color: #f9bb42;
  }

  .fc-pink {
    color: #ff3365;
  }

  .fc-blue {
    color: #0957c3;
  }

  .icon-lightgrey {
    color: #c0c0c0;
  }

  .plan-button {
    width: auto;
    padding: 0 30px;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .text-justify {
    text-align: justify;
  }

  .md-datepicker-input-container {
    min-width: 150px;
  }

  .chart {
    width: 100%;

    .bar-chart {
      width: 100%;
      height: 5px;
      display: block;
      overflow: hidden;
      margin: 8px 0;
      background-color: #d3ddf2;
      border-radius: 5px;

      div {
        height: 5px;
        display: block;
        border-radius: 5px;
        background-color: #12b886;
        transition: width 500ms ease-in-out, background 1s ease-in-out;
      }

      .warning {
        background-color: #f9bb42;
      }

      .danger {
        background-color: #ff3365;
      }
    }
  }

  .icon-dots-horizontal {
    z-index: 1;
    margin: 0;
  }

  .emails-container {
    width: 100%;
    max-height: 250px;
    font-weight: 500;
    margin-bottom: 16px;
    border-bottom: 2px solid #d3ddf2;

    &__item {
      padding: 0;
      height: 50px;
      overflow: hidden;

      &__input-container {
        margin: 0;
      }

      &__address {
        padding-top: 7px;
        padding-left: 4px;
        max-width: 300px;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      md-input-container {
        height: 50px;
      }

      &__options {
        div {
          margin-right: 8px;
          position: relative;
          transition: right 300ms ease-in-out;
          transition-delay: 100ms;

          &.shrink {
            right: -9999px;
          }
        }

        &--view {
          &.expand {
            right: -95px;
          }
        }

        &--edit {
          &.expand {
            right: 0;
          }
        }
      }

      &__type {
        font-size: 10px;
        color: #9e9e9e;
        align-self: center;
        font-weight: normal;
      }

      &__confirm-delete {
        z-index: 1;
        width: 410px;
        margin: 0 20px;
        padding: 0 10px;
        position: absolute;
        border-radius: 5px;
        font-weight: normal;
        background-color: white;
        border: thin solid #d3ddf2;
        transition: left 300ms ease-in-out;

        .md-button {
          min-width: 60px;
          max-width: 60px;
        }

        &.expand {
          left: 0;
        }

        &.shrink {
          left: 9999px;
        }
      }
    }
  }

  &__description {
    padding: 20px;
    height: 100%;
    background-color: white;

    &__title {
      font-weight: 500;
      font-size: 16px;
    }

    &__content {
      font-size: 14px;
    }

    .current {
      font-weight: 500;
      color: #0957c3;
    }
  }

  &__button-container {
    margin-top: 0;
  }
}
