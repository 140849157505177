.fc-error {
  pointer-events: none;

  &__item {
    display: inline-block;
    width: 100%;
    font-size: 12px;
    color: #e20909;
  }
}
