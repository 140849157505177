.fc-container {
  position: relative;
  width: 100%;
  padding: 22px 16px 16px 16px;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  &__label {
    padding: 0 8px;
    position: absolute;
    top: 0;
    left: 2%;
    height: 16px;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    transform: translateY(-50%);
  }

  &__info {
    margin-right: 16px;
    display: inline-flex;
    flex-direction: column;

    &__label {
      margin-bottom: 4px;
      font-size: 12px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.7);
      line-height: 1;
    }

    &__text {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      line-height: 1;
      word-break: break-word;
    }
  }
}
