.equipments-forms-dialogs {
  md-dialog {
    display: flex;
    flex-direction: column;
  }

  .fc-warning-flexbox {
    display: flex;
    justify-content: center;

    .fc-warning-container {
      position: relative;
      padding: 30px !important;
      width: 810px;
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid #D4DEF1;
      margin: 24px 0px 10px 0px;
    }
  }

  md-dialog-content {
    flex: 1 1 auto;

    .question__item {
      margin-top: 20px;

      label {
        margin-bottom: 12px;
        word-break: break-all;
      }

      label.no-margin {
        margin-bottom: 0 !important;
      }

      span {
        word-break: break-all;
      }

      .datepicker__container {
        width: 130px;
        height: 48px;
        border-radius: 4px;
        text-align: left;
      }
    }

    .table-navigation__button {
      border-radius: 4px !important;
    }

    .equipment__input {
      border: 1px solid #D4DEF1;
      width: 130px;
      height: 48px;
      border-radius: 4px;
      text-align: center;
    }

    .equipment_text_input {
      border: 1px solid #D4DEF1;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      padding: 16px;
    }

    div.equipment__input {
      fc-textarea {
        border: none !important;
      }
    }

    div.fc-textarea {
      margin-top: 8px;
      textarea.fc-textarea__textarea  {
        border: 1px solid #D4DEF1 !important;
      }

      ::after {
        content: '' !important
      }
    }

    div.fc-input {
      input.fc-input__input {
        border: 1px solid #D4DEF1;
      }
    }

    div.container-picture-signature {
      border: 1px solid #D4DEF1;
    }

    .picture__container {
      border: 1.5px dashed #135AC0;
      padding: 20px;
      border-radius: 4px;

      &__image {
        position: relative;
        height: 101px !important;
        width: 146px !important;

        img {
          height: 115px !important;
          width: 145px !important;
          border: thin solid #d3ddf2;
          border-radius: 6px;
        }

        &-remove-icon {
          position: absolute !important;
          top: -10px !important;
          right: -12px !important;
          cursor: pointer;
        }
      }

      &__attachment {
        padding: 9.5px 16px;
        border: 1px solid #D4DEF1;
        color: #757575;
        background-color: transparent;
        font-size: 13px;
        border-radius: 4px;
      }
    }

    .attachment__image__button {
      position: absolute;
      bottom: 10px;
      right: 10px;
      cursor: pointer;

      md-icon {
        color: #FC3868;
      }
    }
  }

  md-dialog-content.list__equipments__forms {
    .list {
      display: flex;
      flex-direction: column;
      width: 100%;

      .answered__form {
        border: 1px solid #D4DEF1;
        border-radius: 4px;

        &:not(.answered__form:nth-child(1)) {
          margin-top: 12px;
        }

        &__info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .icon:nth-child(1) {
            margin: 16.75px 14.25px 41.25px 18.25px;
          }

          .icon {
            md-icon {
              color: #135AC0;
            }
          }
        }

        &__name {
          flex: 1;
          flex-direction: column;
          justify-content: start;
          align-items: start;

          p {
            margin: 0;
            margin-bottom: 9px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
          }

          span {
            font-size: 13px;
            font-weight: 400;
            color: #757575;
          }
        }

        &__button  {
          @extend .button-without-hover;

          span {
            font-size: 14px;
            color: #135AC0 !important;
            text-transform: none !important;
          }
        }

        &--unCollapsed {
          height: 100%;
          display: block;
          padding: 18px;
          padding-top: 0;
        }

        &--collapse {
          height: 0px;
          display: none;
          padding-top: 0;
        }

        &__answer {
          position: relative;
          display: flex;
          align-items: center;
          padding: 16px 14px;

          border: 1px solid #D4DEF1;
          background-color: #FFFFFF;
          border-radius: 4px;

          &:not(:last-of-type) {
            margin-bottom: 12px;
          }

          &__infos {
            display: flex;
            flex-direction: column;
            width: 25%;

            &__title {
              margin: 0px;
              font-size: 13px;
              font-weight: 400;
              color: #757575;
            }

            &__subtitle {
              margin: 0px;
              font-size: 14px;
              font-weight: 400;
              color: #262626;

              &__icon {
                transform: scale(.8);
              }
            }
          }

          .md-button {
            position: absolute;
            top: 50%;
            right: 14px;
            transform: translateY(-50%);
          }

          &--archived {
            .answered__form__answer__infos {
              opacity: 0.3;
            }
          }
        }
      }

      .collapsed {
        transform: rotate(0);
        transition: all 0.1s ease-in-out;
      }

      .unCollapsed {
        transform: rotate(-180deg);
        transition: all 0.1s ease-in-out;
      }
    }

    .fc-datepicker {
      height: 50px;
    }

    .fc-datepicker::after {
      border-left: none;
    }
  }

  .equipment-form-header {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    &__reload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;

      md-icon {
        scale: 0.7;
      }
    }
  }
}
