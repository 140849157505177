.ui-select-multiple.ui-select-bootstrap {
    height: auto;
    padding: 3px 3px 0 3px;
}

.dropdown {
    position: relative;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 13px;
    font-family: Arial !important;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    background-color: transparent !important;
    border: none;
    outline: none;
    height: 1.666666em;
    min-height: 28px;
    margin-bottom: 3px;
}

input[type="search"] {
    -webkit-appearance: none;
}

.ui-select-bootstrap > .ui-select-choices, .ui-select-bootstrap > .ui-select-no-choice {
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
    margin-top: -1px;
}

open > .dropdown-menu {
    display: block;
}
.ui-select-bootstrap > .ui-select-choices, .ui-select-bootstrap > .ui-select-no-choice {
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
    margin-top: -1px;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.open > .dropdown-menu {
    display: block;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.ui-select-bootstrap.open {
    z-index: 1000;
}

.ui-select-multiple.ui-select-bootstrap {
    height: auto;
    padding: 3px 3px 0 3px;
}

.dropdown {
    position: relative;
}

.ui-select-bootstrap .ui-select-choices-row.active>a {
    text-decoration: none;
    outline: 0;
    color: #000;
    background-color: white !important;
}

.ui-select-bootstrap .ui-select-choices-row>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.ui-select-bootstrap .ui-select-choices-row>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.ui-select-bootstrap .ui-select-choices-row>a:hover, .ui-select-bootstrap .ui-select-choices-row>a:focus {
    text-decoration: none;
    color: #fff;
    background-color: #039BE5 !important;
}

small, .small {
    font-size: 85%;
}

.ui-select-bootstrap.open {
    z-index: 1000;
}
.ui-select-multiple.ui-select-bootstrap {
    height: auto;
    padding: 3px 3px 0 3px;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
    outline: 0;
    margin: 0 3px 3px 0;
}

.ui-select-multiple .ui-select-match-item {
    position: relative;
}
.btn-xs, .btn-group-xs > .btn {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}
.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
    font-size: 1.6em;
    line-height: 0.75;
}
.close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
}

.btn {
    display: inline-block;
    padding: 3px 3px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #ccc;
}

md-select-menu.md-default-theme md-content md-option[selected], md-select-menu md-content md-option[selected] {
    color: rgb(16, 108, 200);
}

._md-checkbox-enabled.md-default-theme ._md-icon, ._md-checkbox-enabled ._md-icon {
    border-color: rgba(0, 0, 0, 0.54);
}

md-select-menu[multiple] md-option._md-checkbox-enabled ._md-icon {
    box-sizing: border-box;
    transition: 240ms;
    position: absolute;
    top: 13px;
    left: 5px;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px;
}

md-select-menu[multiple] md-option._md-checkbox-enabled[selected] ._md-icon:after {
    box-sizing: border-box;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 4.67px;
    top: 0.22px;
    display: table;
    width: 6.67px;
    height: 13.33px;
    border-width: 2px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    content: '';
}
