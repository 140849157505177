.products-services-tab {
  display: flex;
  flex-direction: column;

  .gap-12 {
    gap: 12px
  }

  .mt-8 {
    margin-top: 8px;
  }

  .pt-0 {
    padding-top: 0px !important;
  }

  .pt-8 {
    padding-top: 8px !important;
  }

  .z-10 {
    z-index: 10;
  }

  .items-edit-buttons {
    gap: 8px;
    margin-right: 8px;
  }

  .expanded-equipments-padding {
    padding: 12px 12px 20px 12px;
  }

  .products-service-equipments-content {
    overflow-x: hidden;
    height: 100%;
    padding-top: 32px;
  }

  &__items-equipments {
    margin-bottom: 16px;

    &__banner {
      margin-top: 16px;
      width: 100%;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      font-family: Roboto;

      &__add {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: start;
        gap: 20px;
        font-size: 14px;

        &__search {
          width: 100%;
        }
      }

      &__span {
        font-family: Roboto;
        line-height: 19.6px;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        margin-bottom: 8px;
      }

      &__button {
        font-size: 14px;
        font-weight: 500;
        color: #135AC0;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        padding: 4px 8px;

        &__icon {
          font-size: 20px !important;
          color: #135AC0 !important;

          &__svg {
            font-size: 20px !important;
            height: 16px !important;
            width: 16px !important;
            min-width: 16px !important;
            min-height: 16px !important;
            color: #135AC0 !important;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: #F1F5FC;
        }
      }
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 24px;
  }

  &__equipments-empty-state {
    display: flex;
    flex-direction: column;
    font-family: Roboto;
    width: 100%;
    color: #757575;
    justify-content: start;
    align-items: start;
    border: none;
    gap: 12px;

    &__search {
      width: 100%;
      border-radius: 4px;
    }

    &__button {
      font-size: 14px;
      font-weight: 500;
      color: #135AC0;
      border-radius: 8px;
      border: 1px solid #D4DEF1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      padding: 4px 8px;

      &__icon {
        font-size: 20px !important;
        color: #135AC0 !important;

        &__svg {
          font-size: 20px !important;
          height: 16px !important;
          width: 16px !important;
          min-width: 16px !important;
          min-height: 16px !important;
          color: #135AC0 !important;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: #F1F5FC;
      }
    }

    &__item {
      display: flex;
      height: 120px;
      width: 100%;
      color: #757575;
      font-weight: 500;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px dashed #D4DEF1;
      font-style: italic;
    }
  }

  &__equipments-container {
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    padding: 12px;
    background-color: #F6F7F9;

    &__item {
      height: 100%;
      position: relative;

      &__expanded-division {
        height: 1px;
        background-color: #E6E6E6;
        position: absolute;
        width: 200px;
        top: 64px;
        left: -12px;
        width: calc(100% + 24px);
      }

      &__expanded {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 8px;
      }

      &__without-image {
        margin-right: 12px;
        height: 48px;
        min-width: 48px;
        border: 1px solid #D4DEF1;
        background-color: #F2F2F2;
        border-radius: 8px;
        display: flex;

        md-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 18px !important;
            height: 18px !important;
          }
        }
      }

      &__image {
        height: 48px;
        min-width: 48px;
        border-radius: 8px;
        margin-right: 16px;
        stroke: #D4DEF1;
        stroke-width: 1px;
        object-fit: contain !important;
      }

      &__item-infos {
        width: 100%;
        height: 100%;
        grid-gap: 20px;

        &__side {
          display: flex;
          gap: 20px;
          margin-right: 16px;
          align-items: center;
          justify-content: end;

          &__div {
            height: 24px;
            width: 24px;
            overflow: hidden;
          }

          &__arrow {
            cursor: pointer;
          }

          &__button {
            font-size: 14px;
            font-weight: 500;
            color: #E64A1D;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            background-color: white;
            border: 1px solid #F2F2F2;
            padding: 4px 12px 4px 4px;

            &__icon {
              font-size: 20px !important;
              color: #E64A1D !important;
            }

            &:hover {
              cursor: pointer;
              background-color: #f8dfdc;
            }
          }
        }

        &__footer {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: 16px 0;

          &__items {
            padding-left: 8px;
          }

          p {
            height: min-content;
            color: #333333;
            margin: 0;
            font-size: 14px;

            md-icon svg {
              height: 20px;
            }

            &.action {
              transition: color 250ms;

              &:first-of-type {
                margin-right: 20px;
              }

              &:hover {
                cursor: pointer;
                color: #000000;
              }
            }
          }

          .icon-delete {
            color: #fc3868;
          }
        }

        &__content {
          grid-gap: 10px;
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: space-between;

          &__info {
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-top: 4px;

            p {
              word-wrap: break-word;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #262626;
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            span {
              font-size: 14px;
              color: #757575;
              margin-top: 8px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  &__equipment-items-container {
    border: 1px solid #D4DEF1;
    border-radius: 4px;
    padding: 12px;
    background-color: white;

    &__item {
      height: 100%;
      position: relative;

      &__expanded-division {
        height: 0.5px;
        background-color: #E6E6E6;
        position: absolute;
        width: 200px;
        top: 60px;
        left: -12px;
        width: calc(100% + 24px);
      }

      &__expanded {
        padding-top: 20px;
      }

      &__without-image {
        margin-right: 12px;
        height: 48px;
        min-width: 48px;
        border: 1px solid #D4DEF1;
        background-color: #F2F2F2;
        border-radius: 8px;
        display: flex;

        md-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 18px !important;
            height: 18px !important;
          }
        }
      }

      &__image {
        height: 48px;
        min-width: 48px;
        border-radius: 8px;
        margin-right: 16px;
        stroke: #D4DEF1;
        stroke-width: 1px;
        object-fit: contain !important;
      }

      &__item-infos {
        width: 100%;
        height: 100%;
        grid-gap: 20px;

        &__side {
          display: flex;
          gap: 4px;
          align-items: center;
          justify-content: end;

          &__arrow {
            cursor: pointer;
          }

          &__button-edit {
            font-size: 14px;
            font-weight: 500;
            color: #135AC0;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            background-color: white;
            border: 1px solid #F2F2F2;
            padding: 6px 10px 6px 6px;

            &__icon {
              font-size: 20px !important;
              color: #135AC0 !important;
            }

            &:hover {
              cursor: pointer;
              background-color: #dcddf8;
            }
          }

          &__button {
            font-size: 14px;
            font-weight: 500;
            color: #E64A1D;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            background-color: white;
            border: 1px solid #F2F2F2;
            padding: 4px;

            &__icon {
              font-size: 20px !important;
              color: #E64A1D !important;
            }

            &:hover {
              cursor: pointer;
              background-color: #f8dfdc;
            }
          }
        }

        &__footer {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: 16px 0;

          &__items {
            padding-left: 8px;
          }

          p {
            height: min-content;
            color: #333333;
            margin: 0;
            font-size: 14px;

            md-icon svg {
              height: 20px;
            }

            &.action {
              transition: color 250ms;

              &:first-of-type {
                margin-right: 20px;
              }

              &:hover {
                cursor: pointer;
                color: #000000;
              }
            }
          }

          .icon-delete {
            color: #fc3868;
          }
        }

        &__content {
          grid-gap: 10px;
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: space-between;

          &__info {
            p {
              word-wrap: break-word;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #262626;
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            span {
              font-size: 14px;
              color: #757575;
              margin-top: 8px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  &__quotation-items-container {
    height: calc(100vh - 250px);
    overflow-y: auto;
    padding: 0 20px;
    scrollbar-width: 6px;
    scrollbar-color: #adabab;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #adabab;
      border-radius: 15px;
    }
  }

  &__equipments-search {
    grid-gap: 12px;
    padding: 20px 0 10px 0;

    &__input-icon {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;

      svg {
        height: 14px;
        width: 14px;
      }
    }

    &__button {
      height: 48px;
      width: 48px;
      font-weight: 400;
      background-color: white;
      border: 1px solid #D4DEF1 !important;

      font-size: 14px;
      font-weight: 500;
      color: #135AC0;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      padding: 4px 8px;

      &:hover {
        cursor: pointer;
        background-color: #dcddf8 !important;
      }

      &__icon {
        font-size: 20px !important;
        color: #135AC0 !important;

        :hover {
          background-color: transparent !important;
        }
      }
    }
  }

  &__header {
    grid-gap: 12px;
    padding: 20px 0;

    &__input-icon {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;

      svg {
        height: 14px;
        width: 14px;
      }
    }

    &__button {
      height: 48px;
      font-weight: 400;
    }
  }

  &__empty-items {
    font-size: 14px;
  }

  &__items-container {
    border: 1px solid #D4DEF1;
    border-radius: 4px;
    padding: 20px 20px 0 20px;

    &__item {
      height: 100%;

      &__without-image {
        margin-right: 12px;
        height: 64px;
        min-width: 64px;
        border: 1px solid #D4DEF1;
        border-radius: 4px;
        display: flex;

        md-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 18px !important;
            height: 18px !important;
          }
        }
      }

      &__image {
        height: 64px;
        min-width: 64px;
        border-radius: 4px;
        margin-right: 16px;
        stroke: #D4DEF1;
        stroke-width: 1px;
        object-fit: contain !important;
      }

      &__item-infos {
        width: 100%;
        height: 100%;
        grid-gap: 20px;

        &__footer {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: 16px 0;

          &__items {
            padding-left: 8px;
          }

          p {
            height: min-content;
            color: #333333;
            margin: 0;
            font-size: 14px;

            md-icon svg {
              height: 20px;
            }

            &.action {
              transition: color 250ms;

              &:first-of-type {
                margin-right: 20px;
              }

              &:hover {
                cursor: pointer;
                color: #000000;
              }
            }
          }

          .icon-delete {
            color: #fc3868;
          }
        }

        &__content {
          grid-gap: 10px;
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: space-between;

          &__info {
            p {
              word-wrap: break-word;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #262626;
              margin: 0;
            }

            span {
              font-size: 14px;
              color: #757575;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  &__items-container + &__items-container {
    margin-top: 20px;
  }

  &__footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width: 100%;
    border-top: 1px solid #d4def1;
    padding: 10px 20px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;

      .fc-input__input__discount {
        border-radius: 4px 0 0 4px !important;
      }

      .fc-input__input__total {
        display: flex !important;
        align-items: center !important;
      }

      .discount-select {
        width: 30%;

        .fc-input__input {
          color: #135ac0 !important;
          font-weight: bold !important;
          border-left: none !important;
          border-radius: 0 4px 4px 0 !important;
          padding-right: 0px !important;
        }
      }

      &__span-title {
        font-size: 14px;
        font-weight: 500;
        display: inline-block;
        margin-right: 12px;
      }
    }
  }
}

.fc-select--position {
  top: -40px;
}
