$border-default: 1px solid #D4DEF1;

fc-subscription-module {

  + fc-subscription-module {
    margin-top: 18px;
  }

  .fc-subscription-module {
    display: flex;

    &__preview {
      width: 100%;
      max-width: 200px;

      img {
        width: 100%;
        height: auto;
        border-radius: 6px 0 0 6px;
        object-fit: cover;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1 1 auto;
      border: $border-default;
      border-radius: 0 6px 6px 0;
      padding: 0 20px;

      &--text {
        font-size: 14px;
        display: inline-block;
        padding: 29px 0;

        &-title {
          color: #135AC0;
          font-weight: 500;
          margin: 0 0 14px 0;
        }

        &-description {
          color: #262626;
        }
      }

      &__button {
        min-width: 180px;
        border-radius: 6px;
        background-color: transparent;
        height: 52px;
        color: #135AC0;
        font-weight: 500;
        font-size: 13px;
        border: $border-default;

        &--state {

          &-new {
            border: none;
            background-color: #135AC0;
            color: #FFF;
          }
        }
      }
    }

    &__hired {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      span {
        color: #757575;
        font-size: 14px;
      }
    }
  }
}
