.suggestion-dialog {
  display: grid;
  width: fit-content;
  gap: 24px;
  padding: 32px;

  &__title {
    width: fit-content;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #262626;
  }

  &__inputs {
    &__form {
      display: flex;
      gap: 12px;
    }

    &__error {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 1px;
      margin-left: -32px;
      margin-right: -32px;
      color: #d50000;
    }
  }

  &__content {
    width: fit-content;
    font-size: 14px;
    line-height: 20px;
    color: #757575;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    &__back, &__send {
      width: 100%;
      padding: 16px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    &__back {
      border: 1px solid #D4DEF1;
      background: #FFFFFF;
      color: #135AC0;

      &:hover {
        background-color: #e0e0e0;
        color: #114da1;
      }
    }

    &__send {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border: 0;
      background: #135AC0;
      color: #FFFFFF;

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}