md-dialog.form-auth-employee{
  max-width: 360px;

  .strength-meter{
    &:before {
      left: 20%;
    }
    &:after {
      right: 20%;
    }

    &:before, &:after {
      width: 20%;
    }
  }

  md-toolbar .title.credentials-title {
    font-size: 14px;
    text-align: center;

    @media only screen and (max-width: 958px) and (min-width: 0) and (orientation: portrait) {
      margin-top: 7px !important;
    }
  }

  .terms {
    .md-container {
      top: 34%;
    }
  }
}