.fc-clone-button {
  padding: 12px 44px;
  height: 48px;
  width: 100%;

  font-size: 14px;
  border: 1.8px dashed #135ac0;
  border-radius: 6px;
  background-color: #fff;
  color: #135ac0;
  cursor: pointer !important;

  transition: border-color 250ms, color 250ms, background-color 250ms;

  &:hover {
    border-color: #084599;
    color: #084599;
    background-color: #f8f8f8;
  }

  &.disabled {
    color: #707070;
    border: 1.8px dashed #707070;

    &:hover {
      color: #707070;
      border: 1.8px dashed #707070;
      background-color: #fff;
    }
  }
}
