.products-services-input-container {
  grid-gap: 12px;
}

.warning-icon {
  margin: 0;
  margin-right: 10px;

  svg {
    height: 20px;
    width: 20px;
  }
}

.image-input {
  &__description {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 14px;
      line-height: 16px;
      color: #757575;
      margin-left: 20px;
    }
  }
}

.inputs-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  grid-gap: 10px;
  position: relative;
  margin-top: 40px;

  h3 {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    font-size: 15px;
  }

  input {
    border-color: #D4DEF1;
  }
}

.input-product-service-locked {
  color: gray;
}

.img-product-service {
  &--disabled {
    pointer-events: none;

    md-icon {
      color: #d3d3d3;
    }
  }
}

.products-services-image {
  object-fit: contain !important;
}
