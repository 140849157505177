.audit-logs-dialog {
  .event-data {

    &__description {
      margin-bottom: 25px;
      border: thin solid #ccc;
      border-radius: 4px;
    }

    md-input-container {
      display: flex;
      flex-direction: column;

      .md-errors-spacer {
        display: none;
      }

      md-datepicker {
        margin-top: 0;
      }
    }

    &__code {
      display: block;
      white-space: pre-wrap;
      background-color: #F8F8FA;
      color: #0957C3;
      border: 1px solid rgba(9, 87, 195, 0.288);
      border-radius: 4px;
      padding: 10px;
    }
  }
}
