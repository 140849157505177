$border-default: 1px solid #D4DEF1;

fc-box {

  flex: 1 1 340px;
  .fc-box {
    border: $border-default;
    border-radius: 6px;
    height: 100%;

    &__header {
      border-bottom: $border-default;
      padding: 18px 20px;

      span {
        color: #9E9E9E;
        font-size: 14px;
        font-weight: 500;
      }
    }

    &__body {
      padding: 18px 20px;
    }

    &__content {
      font-size: 14px;
      color: #262626;
      height: 46px;

      &__display-count {
        margin: 8px 0;
        position: relative;
        overflow: hidden;
        height: 8px;
        border-radius: 6px;

        &__progress {
          width: 100%;
          height: 8px;
          position: absolute;
          left: 0;

          &--empty {
            background-color: #C7D8F0;
          }

          &--current {
            background-color: #135AC0;
          }
        }
      }

      &--state {

        &-renewal {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    &__button {
      padding: 16px 0;
      width: 100%;
      font-weight: 500;
      font-size: 13px;
      border-radius: 6px;
      background: #FFF;
      color: #135AC0;
      border: $border-default;

      span {
        display: inline-block;
        min-width: max-content;
      }

      &:disabled {
        color: #9E9E9E;
      }

      &--state {

        &-renewal {
          background: #FC3868;
          color: #FFF;
        }
      }
    }
  }
}
