#mail {
  .content-tasks-of-day.classic {

    .toolbar {
      position: relative;
      padding: 8px 24px;

      .select-menu {

        .md-icon-button {
          padding-left: 0;
          margin: 0;
        }
      }

      .page-info {
        font-weight: 500;
        margin: 0 8px;
        white-space: nowrap;
      }

      .close-thread {
        margin: 0 0 0 -8px;
      }
    }

    .content-wrapper {
      position: relative;
    }
  }

  .content-tasks-of-day {
    &__pagination {
      padding: 16px 8px;
      position: absolute;
      bottom: 0;
      height: 64px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      .page-info {
        margin: 0 2px;

        span {
          font-weight: 600;
        }
      }
    }

    &__pagination-button {
      padding: 0px !important;
      font-size: 1.3rem;
      margin: 0 2px;
      white-space: nowrap;
      text-transform: none;
      border: solid 1px;
      border-color: rgba(0, 0, 0, 0.12) !important;
    }
  }
}

.thread-labels-toggle-list {

  md-list-item {

    md-icon {
      margin: 0 !important;
    }
  }
}

span.pagination-text {
  margin-right: 5px;
}

.options-menu-content {

  position: relative;
  padding: 4px 0;
  max-height: 400px;

  &__subtitle {
    font-weight: 500 !important;
    padding: 14px 17px;
    font-size: 14px;
  }

  md-input-container {

    input {
      font-size: 16px;
    }
  }

  .option {

    .md-button {

      .avatar {
        margin-right: 16px;
        width: 32px;
        height: 32px;
        min-width: 32px;
      }

      .option-label {
        font-size: 13px;
      }
    }
  }
}

.members-menu-content {

  position: relative;
  padding: 4px 0;
  max-height: 400px;

  md-input-container {

    input {
      font-size: 16px;
    }
  }

  .member {

    .md-button {

      .avatar {
        margin-right: 16px;
        width: 32px;
        height: 32px;
        min-width: 32px;
      }

      .member-name {
        font-size: 13px;
      }
    }
  }
}

.edit-not-allowed {
  cursor: not-allowed !important;
}
