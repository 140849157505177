.fixed-position{
    position: fixed !important;
    top: 8vh;
    max-height: 80vh;
}
md-dialog {
    &.form-dialog {
        max-width: 720px;
        width: 720px;

        md-dialog-content {
            display: block;
            position: relative;
        }

        md-toolbar {

            .title {
                font-size: 17px;
            }
        }

        md-input-container {

            textarea {
                min-height: 150px;
            }
        }

        .date-container {
            padding: 16px 0 24px 0;
        }

        .notes {

            > label {
                color: rgba(0, 0, 0, 0.54);
            }

            .mce-tinymce {
                margin-top: 8px;
            }
        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
        md-dialog-actions-tasks {
            position: fixed; 
            justify-content: space-between;
            bottom: 0; 
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            width: 100%;
        }
    }
}

.attachments {

    .title {
        margin-bottom: 16px;
        font-weight: 500;
    }

    .attachment {

        .preview {
            width: 100px;
            height: 80px;
            margin: 0 16px 8px 0;
            border: 1px solid black;
        }

        .link {
            margin-bottom: 2px;
        }

        .size {
            font-size: 11px;
        }
    }
}

.attachment-title {
    width: 100px;
    height: 18px;
    overflow: hidden;
    font-size: 10px;
    padding-bottom: 5px;
}

.top-left-margin-fix {
    margin-top: 24px;
    padding-left:24px;
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-md) {
    md-dialog {
        &.form-dialog {
            width: 80%;
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    md-dialog {
        &.form-dialog {
            width: 90%;
        }
    }
}