#order-dialog-deadlines-tab {
  .deadlines-tab {
    .subtitle {
      color: #2e2e2e;
      font-weight: 500;
      font-size: 16px;
      margin: 0 !important;
      margin-bottom: 0;
      padding: 30px 0 30px 0;
    }

    .input-group {
      gap: 15px;
    }

    .office-hours {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 20px;
      gap: 10px;
      width: 830px;
      height: 118px;
      background: #FFFFFF;
      border: 1px solid #D4DEF1;
      border-radius: 4px;
      flex: none;
      order: 7;
      align-self: stretch;
      flex-grow: 0;

      &-frame {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 32px;
        flex-basis: 33.333333%;
        width: 790px;
        height: 52px;
        flex-wrap: wrap;
        flex: none;
        order: 4;
        align-self: stretch;
        flex-grow: 0;
      }

      &-text {
        width: 171px;
        height: 0px;
        margin-bottom: -10px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: #262626;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }

    .fc-input {
      pointer-events: none !important;

      .deadline-in-progress {
        color: rgba(0,0,0,0.38);
      }

      &__label--no-animation {
        font-size: 12px;
      }

      &__suffix {
        &__disabled-by-enterprise {
          width: 30px;
          height: 30px;

          &_icon {
            color: #9e9e9e;
          }
        }
      }

      &__input {
        &__linked-by-enterprise {
          color: #262626;
          font-size: 14px;
          line-height: 48px;

          &--italic {
            font-style: italic;
            color: #9e9e9e;
          }

          &--disabled {
            color: #9e9e9e;
          }
        }
      }
    }

    .h100 {
      height: 100%;
    }

    .reason-violantion-select {
      &:not(.disabled):hover {
        .fc-select {
          &__select {
            cursor: pointer!important;
            .fc-input__input {
              cursor: pointer!important;
              border:1px solid #000000;
            }
          }
        }
      }
      .fc-select {
        &__dropdown {
          width: 100% !important;
          &__searcher {
            cursor: text !important;
            padding: 14px 16px !important;
          }
        }
      }
    }
  }
}
