#fc-spreadsheet-result {
    .spreadsheet-review {
        padding: 20px 25px;
        border: solid 1px #ccc;

        .spreadsheet-name {
            align-self: center;
            text-align: center;
            font-weight: 600;
            font-size: 15px;
        }

        &__status {
            margin-right: 15px;
        }

        &__progress {
            &__counter {
                float: right;
                color: rgb(73, 73, 73);
            }
        }

        .valid-rows-percentage {
            .md-mode-determinate {
                background-color: rgb(76,175,80) !important;
                .md-bar {
                    background-color: rgb(244,67,54) !important;
                }
            }
        }
    }

    .spreadsheet-rows-table {
        border: solid 1px #ccc;
        border-radius: 3px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        color: rgba(0, 0, 0, 0.7) !important;

        .spreadsheet-rows-erros-table {
            border: solid 1px #ccc;
            border-radius: 3px;
            color: rgba(0, 0, 0, 0.7) !important;

            .invalid-column {
                color: rgb(244,67,54);
            }

            td {
                :last-child {
                    background-color: blue;
                    border: solid 1px #ccc !important;
                }
            }
        }
    }
}