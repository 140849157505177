.task-dialog {
  md-menu-content {
    .option {
      min-height: 51px;
    }
  }
  .md-select-icon{
    width: 10px !important;
  }
  .feedback-div {
    display: flex;
    gap: 12px;
    span {
      margin-top: 1.5px !important;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.14px;
    }
  }
  .md-tab {
    &.md-disabled {
      display: none;
    }

    .badge-counter {
      background-color: rgba(0, 0, 0, 0.45);
      border-radius: 100%;
      color: white;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      position: absolute;
      right: 5px;
      top: 2px;
      width: 20px;
      transition: background-color 0.5s ease-in;
    }

    &.md-active {
      .badge-counter {
        background-color: #ff3365;
      }
    }
  }

  md-input-container {
    &.margin-fix {
      margin: 17px 0 !important;
    }

    &.margin-fix2 {
      margin: 7px 0 !important;
    }

    md-select {
      .gray {
        font-size: 11px;
        color: gray;
      }
    }
  }

  .warning-task-locked {
    font-size: 14px;
    color: #5f5f5f;
  }

  .no-border {
    border: none !important;
  }

  .red-text {
    color: red;
  }

  &.w-760 {
    width: 760px;
    max-width: 760px;
  }

  &.w-925 {
    width: 925px;
    max-width: 100%;
  }

  .mt-5n {
    margin-top: -5px !important;
  }

  .op-100 {
    opacity: 100% !important;
  }

  .px-15 {
    padding: 0 15px;
  }

  .leaflet-div-icon {
    background: none !important;
    border: none !important;
  }

  .required-forms-error {
    color: rgb(213, 0, 0);
    font-size: 12px;
    margin-top: -15px;
    margin-bottom: 33px;
  }

  .remove-margin {
    margin-top: 0;
    margin-bottom: 0;
  }

  .red-border-bottom {
    md-select-value {
      border-bottom: 1px solid rgb(213, 0, 0) !important;
    }
  }

  .identifier-content {
    height: 60px;
  }

  .labels-content {
    display: block;
  }

  .pb-question {
    label {
      .md-required {
        color: red !important;
      }

      &.md-required::after {
        content: " *";
      }
    }
  }

  .omie-tiny-icon {
    min-width: 15px !important;
    width: 15px !important;
    min-height: 15px !important;
    height: 15px !important;
    margin-right: 3px;
  }

  .order-info {
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    width: 100%;
    padding: 16px 16px;
    border: thin solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    &__label {
      position: absolute;
      top: -7.8px;
      left: 2%;
      background-color: #ffffff;
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
    }

    &__text {
      margin-top: 24px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .table-navigation {
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 0;
    margin-top: auto;
    padding-bottom: 0;

    &__info {
      color: #7f7f7f;
    }
  }

  info-component-provider {
    & > div {
      margin-bottom: 30px;
    }
  }
}

.icon-pdf {
  transform: scale(.9);
}

.fc-warning-container.cant-edit {
  height: 60px;
  min-height: 60px;
}

.additional-customer-info {
  color: #777 !important;
}

.warning-new-visit {
  font-size: 13px;
  color: #6b6b6b;
}

span.task-customer-label {
  display: inline-block;
  margin-top: 3px;
  margin-right: 4px;
  color: #fff;
  font-size: 11px;
  padding: 1px 5px;
  border-radius: 2px;
}

span.customer-notes {
  word-break: break-word;
  margin-bottom: 12px;
}

md-toolbar {
  .md-toolbar-tools {
    >.md-menu {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .due-date {
      .moment-picker {
        .moment-picker-container {
          font-size: 13px;
        }
      }
    }
  }
}

.menu-content-title {
  margin: 15px 0px 15px 0px;
  font-size: 14px;

  span {
    text-transform: none !important;
    font-weight: 500 !important;
    margin-left: 15px !important;
  }
}

.menu-item-inner {
  min-width: 100% !important;
  margin: 0 !important;
  line-height: 50px !important;

  md-icon {
    margin-left: 10px !important;
  }

  span {
    text-transform: none !important;
    font-weight: 400 !important;
    margin-left: 15px !important;
    font-size: 13px;
  }
}

.fs13 {
  font-size: 13px;
}

.no-padding-right {
  padding-right: 0 !important;
}

.members-menu-content {
  position: relative;
  padding: 4px 0;
  max-height: 400px;

  md-input-container {
    input {
      font-size: 13px;
    }
  }

  .member {
    .md-button {
      .avatar {
        height: 32px;
        margin-right: 16px;
        min-width: 32px;
        width: 32px;
      }

      .member-name {
        font-size: 13px;
      }
    }
  }
}

md-dialog-content {
  padding: 0;
  display: flex;

  .due-date {
    .moment-picker {
      .picker {
        background: material-color("grey", "200");
        padding: 5px 10px;

        &:hover {
          background: material-color("grey", "300");
        }

        i {
          margin-right: 8px;
        }
      }

      .moment-picker-container {
        margin-top: 8px;
        right: 0;

        &:before,
        &:after {
          right: 15px;
          left: auto;
        }
      }
    }

    .remove-due-date {
      background: material-color("grey", "200");
      padding: 5px;
      cursor: pointer;

      &:hover {
        background: material-color("grey", "300");
      }
    }

    font-size: 13px;
  }

  .sections {
    .section {
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;

        .section-content {
          padding-bottom: 0;
        }
      }

      .section-header {
        font-size: 16px;

        i {
          margin-right: 8px;
          color: rgba(0, 0, 0, 0.6);
        }

        .section-title {
          font-weight: 500;
        }
      }

      .section-content {
        padding: 24px 0 0 0;

        &__content {
          gap: 5px;
        }

        .datepicker-container {

          ._md-datepicker-floating-label._md-datepicker-has-calendar-icon>label:not(.md-no-float):not(.md-container-ignore) {
            right: initial;
            width: 100%;
          }

          ._md-datepicker-floating-label._md-datepicker-has-calendar-icon .md-input-message-animation {
            margin-left: 0px;
          }

          md-datepicker {
            width: 100%;
            padding-right: 0px;

            input:disabled {
              border-bottom-color: rgba(0, 0, 0, 0.12) !important;
            }

            .md-datepicker-input-container,
            input {
              width: 100%;
            }

            .md-datepicker-triangle-button {
              right: 8px;
            }
          }
        }

        .datepicker-container, .time-input-container {
          &__md-input-container {
            &__icon {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 5px;
              right: 0;
              left: unset;
              margin: 0;

              svg {
                transform: scale(.7);
              }
            }
          }
        }

        .time-input-container {
          &__md-input-container {
            padding-right: 0px;
          }
        }

        .flex-19 {
          flex: 1 1 100%;
          max-width: 19%;
          max-height: 100%;
          box-sizing: border-box;
        }

        .flex-21 {
          flex: 1 1 100%;
          max-width: 21%;
          max-height: 100%;
          box-sizing: border-box;
        }
      }

      .labels {
        .section-content {
          padding: 8px 0 32px 0;
        }

        .label-chips {
          .md-chips {
            box-shadow: none;
            padding: 0;

            md-chip {
              background: transparent;
              padding-right: 0 !important;
              padding-left: 0 !important;

              .label-chip {
                padding: 0 32px 0 12px;
                border-radius: 16px;
                display: block;
                height: 32px;
                line-height: 32px;
                font-size: 14px;
              }

              ._md-chip-remove-container {
                right: 12px;
                line-height: 30px;
              }
            }
          }
        }
      }

      .members {
        .section-content {
          padding: 8px 0 32px 0;
        }

        .member-chips {
          .md-chips {
            box-shadow: none;
            padding: 0;

            md-chip {
              padding-left: 0;

              .member-chip {
                .member-chip-avatar {
                  width: 32px;
                  border-radius: 50%;
                }
              }

              ._md-chip-remove-container {
                right: 6px;
                line-height: 30px;
              }
            }
          }
        }
      }

      .checklist {
        .checklist-progress {
          margin-bottom: 16px;

          .checklist-progress-value {
            margin-right: 12px;
            font-weight: 500;
            white-space: nowrap;
            font-size: 14px;
          }
        }

        .editable-wrap {
          flex: 1;
        }

        .check-items {
          .check-item {
            md-checkbox {
              margin-bottom: 0;

              .md-label {
                font-size: 14px;
              }

              &.md-checked {
                .md-label {
                  text-decoration: line-through;
                  color: rgba(0, 0, 0, 0.6);
                }
              }
            }
          }
        }

        .new-check-item-form {
          padding-top: 16px;

          md-input-container {
            margin: 0;
          }

          .md-button {
            margin-left: 16px;
          }
        }
      }

      .activities {
        .activity {
          margin-bottom: 12px;

          .activity-member-avatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 16px;
          }

          .activity-member-name {
            font-size: 14px;
            font-weight: 500;
            margin-right: 8px;
          }

          .activity-message {
            font-size: 14px;
            margin-right: 8px;
          }

          .activity-time {
            font-size: 12px;
          }
        }
      }
    }

    &__archived-button {
      min-width: 0px !important;
    }
  }

  #timeline {
    .break-card {
      word-wrap: break-word;
      word-break: break-word;
    }
  }

  .pb-question {
    padding-bottom: 24px;
  }

  .ml-24n {
    margin-left: -24px;
  }

  .ml-5n {
    margin-left: -5px !important;
  }

  .mt-2n {
    margin-top: -2px;
  }

  .mt-17n {
    margin-top: -17px !important;

    md-not-found {
      .employee-not-found {
        padding: 50px 50px 50px 30px;
      }
    }
  }

  .mb-46 {
    margin-bottom: 46px !important;
  }

  .mt-44 {
    margin-top: 44px !important;
  }

  .mb-24 {
    margin-bottom: 24px !important;
  }

  .mt-13n {
    margin-top: -13px !important;
  }

  .mt-13 {
    margin-top: 13px !important;
  }

  .mt-4n {
    margin-top: -4px !important;
  }

  .mt-10n {
    margin-top: -10px !important;
  }

  .mb-15n {
    margin-bottom: -15px !important;
  }

  .fs-15 {
    font-size: 15px;
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-13 {
    font-size: 13px;
  }

  .fs-12 {
    font-size: 12px;
  }

  .fc-black {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .fc-grey {
    color: rgba(0, 0, 0, 0.54);
  }

  .fw-400 {
    font-weight: 400;
  }

  .fw-500 {
    font-weight: 500;
  }

  .fw-550 {
    font-weight: 550;
  }

  .text-align-r {
    text-align: right;
  }

  .schedule-time {
    text-align: center;
    width: 45px;
  }

  .comment-tab {
    margin-left: -25px;
    width: calc(100% + 50px) !important;
  }

  .confirm-button {
    cursor: pointer;
    color: #0288d1;
    text-decoration: underline;
  }

  .add-task {
    cursor: pointer;
    font-size: 13px;
    text-decoration: underline;
  }

  .remove-or-archive-attachment {
    display: flex;

    &__action-button {
      pointer-events: all !important;
      margin: 0;
      max-height: 35px;
      min-width: 35px;

      .light-blue {
        color: #135AC0;
      }

      .light-grey {
        color: #adb5bd;
      }

      .dark-red {
        color: #A40000;
      }
    }

    .md-is-open {
      position: relative;
      z-index: 20;
    }

    .md-fab-toolbar {
      .md-toolbar-tools {
        width: 515px;
        height: 130px;
        max-height: 130px;
      }

      .md-fab-toolbar-wrapper {
        height: 130px;
      }
    }
  }

  .attachment-delete-dialog.visible,
  .attachment-archive-dialog.visible {
    width: calc(100% - 220px);
    margin-right: 2px;

    @media (max-width: 925px) {
      width: calc(100% - 24px);
    }
  }

  .equipment-delete-dialog.visible {
    width: 86%;

    @media (max-width: 925px) {
      width: 100%;
    }
  }

  .attachment-delete-dialog,
  .equipment-delete-dialog,
  .attachment-archive-dialog {
    width: 0;
    overflow: hidden;
    position: absolute;
    height: 130px;
    right: 0;
    z-index: 10;
    border-radius: 3px;
    background-color: #fafafa;
    border: 1px solid transparent;
    white-space: nowrap;
    transition: width 0.5s ease-in-out;

    &.visible {
      border-color: #d3ddf2;
    }
  }

  .checkbox-margin {
    margin: 2px 0 2px !important;
  }

  .display-inline {
    display: inline;
  }

  .display-block {
    display: block !important;
  }

  .form-answers-datepicker {
    .md-datepicker-input-container {
      position: absolute !important;
      top: 28% !important;
      left: 8px !important;
      padding: 0 !important;
      border: none !important;
      width: calc(100% - calc(35px + 16px));

      input {
        min-width: 100%;
        max-width: 100%;
      };

      button {
        position: absolute;
        right: -44px;
        top: 50%;
        transform: translateY(-50%);

        .md-datepicker-expand-triangle {
          border-top-color: #135ac0 !important;
        }
      }
    }
  }

  .text-darkred {
    color: darkred;
  }

  .wrap-label {
    white-space: normal !important;
    word-wrap: break-word;

    & .md-select-placeholder>span {
      white-space: normal !important;
      word-wrap: break-word;
    }
  }

  #timeline.fs-13 {
    font-size: 13px !important;

    .timeline-icons {
      font-size: 15px;
      margin-right: -2px;
      margin-top: -2px;
      min-width: 8px;
      width: 16px;
    }
  }

  .container-os {
    .email-space {
      margin-bottom: -15px;
    }

    .phone-space {
      margin-top: 17px;
    }

    .customer-space {
      margin-bottom: -15px;

      input[name="customerName"] {
        margin-bottom: 12px;
      }
    }
  }

  .container-attachments,
  .container-tab-content {
    display: block;
    min-height: 500px;
    width: 100%;
  }

  .container-tab-content {
    min-height: 250px;
  }
}

.labels-menu-content {
  position: relative;
  padding: 4px 0;
  max-height: 400px;

  md-tabs-wrapper {
    height: 0;
    overflow: hidden;
  }

  md-tabs-content-wrapper {
    top: 0;
  }

  md-input-container {
    input {
      font-size: 16px;
    }
  }

  .label {
    padding: 4px 16px;

    .label-toggle-button {
      font-size: 13px;
    }

    .label-edit-button {
      margin: 0 0 0 8px;
      padding: 0;

      md-icon {
        margin: 0 auto;
        font-size: 20px;
        display: grid;
        place-items: center;
      }

      md-icon::before {
        font-size: 20px;
      }
    }

    button {
      border-radius: 5px;
    }
  }

  md-menu-divider {
    display: block;
  }

  .add-label-tab-content,
  .edit-label-tab-content {
    .header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .content {
      padding: 0 16px 8px 16px;

      .label-color-picker {
        width: 240px;
        margin: 0 -4px 0 2px;

        .color {
          width: 24px;
          height: 24px;
          margin: 0 6px 6px 0;
          border-radius: 50%;
          cursor: pointer;
        }
      }

      .create-button {
        margin: 16px 0 8px 0;
      }
    }
  }
}

#tab-form {
  md-input-container.md-input-has-value.no-scale label:not(.md-no-float) {
    max-width: 133%;
    width: 133%;
  }

  md-input-container.md-input-focused.no-scale label:not(.md-no-float) {
    max-width: 133%;
    width: 133%;
  }

  md-input-container {
    margin-bottom: 0px !important;
  }

  .dropdown-margin-bottom {
    margin-top: 23px;
  }

  .option-description-container {
    display: flex;
    padding: 4px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #D4DEF1;
    background: #FFF;

    h3 {
      color: #262626;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    p {
      color: var(--fc-text-label, #757575);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .form-score {
    position: relative;
    padding: 15px;
    margin-top: 20px;
    border: solid 1px #dfdfdf;
    border-radius: 3px;
    cursor: pointer;
    transition: border-color 500ms;

    &__title {
      display: flex;
      justify-content: space-between;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }

    &__body {
      padding-top: 20px;

      &__question {
        display: flex;
        justify-content: space-between;
        padding: 0 10px 5px 10px;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-top: solid 1px #ccc;

    }

    &__total {
      font-weight: 500;
    }

    &:hover {
      border-color: rgb(70, 146, 252);
    }
  }
}

.dialog-menu-customer-space{
  min-width: 330px !important;
  max-height: 549px !important;
}

md-menu-content {

  .fs-13 {
    font-size: 13px !important;
  }

  .to-right {
    float: right;
    margin-top: 7px !important;
    margin-left: 10px !important;
    margin-right: 3px !important;
  }

  .option {
    min-height: 61px;

    &__shared-wpp {
      margin: 0px 5px 0px 5px;
    }

    &__icon {
      margin-right: 5.74px !important;
      font-size: 20px;

      md-icon {
        font-size: 20px;
      }
    }

    &__label {
      font-size: 14px;
      max-width: 300px;
      word-wrap: break-word;
    }

    &__sub-label {
      font-size: 13px;
      color: #757575;
    }

    &__end-icon {
      display: flex;
      align-items: center;

      padding-bottom: 10px;
    }

    &__send-link-button {
      display: flex;
      justify-content: center;
      background-color: #135AC0 !important;
      padding: 16px !important;
      width: 298px;
      height: 48px;
      border-radius: 4px;
      text-align: center !important;
      font-weight: 500;
      color: #fff;
      margin: 17px 0px 12px 0px;

      .icon-telegram {
        display: contents;
        color: #fff;
        line-height: 15px !important;
      }
    }

    &__buttom-label {
      margin: 0 !important;
      margin-left: 15px !important;
      font-weight: 500 !important
    }

    &__buttom-label-count {
      margin: 0 !important;
      margin-left: 5px !important;
      font-weight: 500 !important
    }

    &__send-link-button:disabled {
      display: flex;
      justify-content: center;
      padding: 16px !important;
      width: 298px;
      height: 48px;
      border-radius: 4px;
      text-align: center !important;
      font-weight: 500;
      margin: 17px 0px 17px 0px;
      background-color: #CFCFCF !important;
      color: #757575;

      .icon-telegram {
        display: contents;
        color: #757575;
        margin-right: 15px;
        line-height: 15px !important;
      }
    }

    &__send-whats-button {
      display: flex;
      justify-content: center;
      background-color: #3BBC8C !important;
      color: #fff !important;
      padding: 16px !important;
      width: 298px;
      height: 48px;
      border-radius: 4px;
      text-align: center !important;
      font-weight: bold;
      margin: 17px 0px 17px 0px;

      .icon-whatsapp {
        display: contents;
        color: #fff !important;
        margin-right: 12px;
      }

      &__remove-margin {
        margin: 17px 0px 0px 0px;
      }
    }

    &__buttom-label {
      margin: 0 !important;
      margin-left: 15px !important;
    }
  }
}

md-content {
  .fs-11 {
    font-size: 11px;
  }
}

md-autocomplete-parent-scope {
  .alert-km-distance {
    float: right;
    font-size: 11.5px;
    color: #000;
  }
}

md-select-header.header-services {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  padding-left: 10.667px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}

md-option span.caption {
  font-size: 0.7em;
  font-weight: 500;
  position: absolute;
  right: 1%;
  text-transform: uppercase;
  top: 50%;
  transform: translate(0, -50%);
  color: grey;
  margin-top: 1px;
}

.form-list-icon {
  margin-right: 0px !important;
}

.form-status-container {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.form-select {
  .icon-info {
    z-index: 99;
    cursor: default
  }

  &__option {
    .md-text {
      @extend .form-status-container;
    }

    .icon-info {
      display: none;
    }
  }

  .md-text {
    @extend .form-status-container;
  }

  &__status-container {
    &__status {
      font-size: 16px;
      font-style: italic;
      margin-left: 5px;
    }

    .icon-info svg {
      height: 21px;
    }
  }
}

md-icon.s-18 {
  svg {
    width: 18px;
    height: 18px;
  }
}

.order-form-date {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  float: right;
  margin-left: 5px;
}

.counter-labels {
  margin-bottom: 2px;
  font-size: 12px;
  color: gray;
  text-align: left;
  width: 100%;
  display: block;
  padding: 10px 25px;
}

.line- {
  &through {
    text-decoration: line-through;
  }

  &none {
    text-decoration: none;
  }
}

.orders-equipments {
  &__image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-width: initial;
    border: thin solid rgba(0, 0, 0, 0.12);
  }

  .content-disabled {
    color: #757575;
  }

  &__item {
    position: relative;
    padding: 18px 0;

    &--selected {
      color: #9E9E9E;
    }

    strong {
      font-weight: 500;
    }
  }

  &__select-all {
    padding-right: 12px;
  }

  .remove-equipment {
    position: absolute;
    right: 0;
    top: 40%;
    transform: translate(0, -50%);

    .md-is-open {
      position: relative;
      z-index: 20;
    }

    .md-fab-toolbar {
      .md-toolbar-tools {
        width: 100%;
        height: 100px;
        max-height: 100px;
      }

      .md-fab-toolbar-wrapper {
        height: 100px;
      }
    }
  }
}

.fix-textarea-scroll {
  position: sticky !important;
  top: 0 !important;
}

#tab-form {
  .datepicker-container {
    label {
      right: initial;
    }

    .md-datepicker-input-container {
      width: 100%;

      input {
        max-width: inherit;
      }
    }
  }
}

md-input-container {
  &.md-input-invalid {
    .form-datepicker {
      input {
        border: none;
      }
    }
  }
}

.form-picture-preview {
  background-color: #eef0f2;
  width: 160px;
  height: 128px;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-right: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.attachment-delete-dialog,
.attachment-archive-dialog {
  &.form-answer {
    &.visible {
      width: 79%;
    }
  }
}

.picture-name {
  font-weight: 500;
  font-size: 16px;
}

.question-answer-container {
  position: relative;
}

.question-label {
  bottom: 110% !important;
}

.question-multiple-choice {
  display: flex;
  flex-direction: column;
  justify-content: center;

  md-radio-button {
    height: auto !important;
    width: max-content;
    max-width: 100%;
  }
}

.uploading-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;

  span {
    margin-right: 2rem;
  }
}

.icon-task-export svg {
  width: 19px;
  margin: 0 auto;
}

.sub-menu {
  margin: 0;

  .sub-menu-button {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 18px;
    background-color: transparent;
  }

  .sub-menu-icon-left {
    margin: 0 18px 0 0;
  }

  .sub-menu-icon-right {
    margin: 0;
  }

  .sub-menu-title {
    margin: 0;
    font-size: 13px;
    text-transform: none;
    font-weight: normal;
  }
}

.sub-menu-option-label {
  font-size: 13px;
}

.option-label-fs-13 {
  font-size: 13px;
}

.default-option-color {
  color: rgba(0, 0, 0, 0.87) !important;
}

.option-link p {
  color: rgba(0, 0, 0, 0.87);
}

.received-viwed-at-container {
  display: block;
  margin-bottom: 24px;
  margin-top: -10px;
  text-align: right;

  small {
    color: rgba(0, 0, 0, 0.87);
    display: block;
    font-size: 11px;

    md-icon {
      color: rgba(0, 0, 0, 0.87);
      font-size: 15px;
      height: 15px;
      line-height: 20px;
      margin-left: 9px;
      min-width: 15px;
      width: 15px;
    }
  }
}

.fc-btn-outline-primary {
  border-radius: 4px;
  border: thin solid rgb(9, 87, 195);
  color: rgb(9, 87, 195);
  margin: 0;
  padding: 10px 20px;
  text-decoration: none !important;
  text-transform: uppercase;

  &:hover {
    color: white;
    background-color: rgb(9, 87, 195);
  }
}

.badge-pink {
  background: #ea2f65 !important;
  color: white;
}

.icon-forward {
  transform: rotateY(180deg);
}

.pad-signature,
.container-picture-signature {
  border: thin solid #cccccc;
  border-radius: 10px;
  width: 650px;
  height: 220px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    padding-top: 20px;
  }

  .no-signature {
    font-weight: 600;
    color: #cccccc;
    font-size: 25px;
    display: block;
  }
}

#tab-form md-input-container.signature-block {
  margin-bottom: 15px !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  padding-bottom: 30px;
  display: block;
}

#order-equipments {
  .delete-icon {
    padding-top: 8px;
    color: red;
  }
}

#add-equipments {
  display: none;
}

.button-without-hover {

  &:hover,
  &:active {
    background-color: #fff !important;
  }
}

.search-bar {
  .fc-input {
    width: 95%;

    &__input--search {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &__button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
      width: 42px;

      md-icon {
        color: #135AC0;
      }
    }
  }

  &__hint {
    color: #7f7f7f;
    font-size: 12px;
  }
}

.new-equipment-button {
  margin-left: 8px;
  padding: 15px 30px;
}

#dialog-update-task-button {
  text-transform: uppercase;
  width: 27%;
}

.equipment-link-hint {
  color: #757575;
  font-style: italic;
  font-size: 13px;
}

.fc-button--actions {
  width: 80px !important;
  background-color: #135ac0 !important;
}

.no-items-alert {
  margin: 30px 0px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: thin solid #E0E0E0;
  border-radius: 4px;
}

.multiple-picture {
  margin-top: 20px;

  label {
    margin-bottom: 12px;
  }

  &__pictures {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 34px;
  }

  &__alert {
    font-size: 13px;
    font-weight: 400;
    color: #757575;

    md-icon svg {
      height: 20px;
    }
  }
}

.rating-form-label {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
}

md-icon.rating-form-icon svg {
  height: 20px !important;
}

input.hour-input:disabled {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  background-image: none !important;
}

button.reload-form-icon {
  &:disabled {
    md-icon {
      filter: opacity(0.5);
    }
  }

  md-icon {
    scale: .8;
  }
}

.no-edit-permission{
  pointer-events: none !important;
}

.pointer-events {
  pointer-events: all !important;
}

.no-action-permission {
  color: rgba(0,0,0,0.54) !important;
  padding: 0;
  cursor: not-allowed;
  opacity: 0.3;
}

.md-open-menu-container.md-active {
  border-radius: 6px;
}

.options-menu-content__no-content-text {
  font-weight: normal !important;
  padding: 10px 48px;
  font-size: 13px;
  color: #757575;
}

.customer-rating-comments {
  word-wrap: break-word !important;
}

label.employee-asterisk::after {
  content: ' *';
}

label.employee-label-error {
  color: red !important;
}

.display-as-error {
  color: red;
  font-size: 16px;
}

.feature-flag-warning {
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 8px;
  background: #F1F5FC;

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__content {
    color: #262626;
    font-size: 14px;
    font-weight: 400;
  }

  &__test-button {
    padding: 0px 12px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #EA1453;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    border: none;
    white-space: nowrap;
  }

  &__close {
    color: #757575;
  }
}

.tab-label-with-badge {
  display: flex;
  align-items: center;
  gap: 6px;

  .title-badge {
    width: 8px;
    height: 8px;
    background-color: #FC3868;
    border-radius: 50%;
  }
}

.hover-link:hover {
  text-decoration: none;
}
