.choose-location {
  padding: 32px;

  &__title {
    margin: 0;
    color: #262626;
    font-weight: 500;
    font-size: 15px;
  }

  &__subtitle {
    margin-top: 24px;
    color: #757575;
    font-weight: 400;
    font-size: 14px;
  }

  md-radio-group {
    margin-bottom: 0px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    md-radio-button {
      margin-bottom: 16px;
      height: fit-content;

      span {
        font-weight: 400;
        font-size: 14px;
        color: #262626;
      }

      div {
        color: #757575;
        font-weight: 400;
        font-size: 13px;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    width: 100%;


    &__continue,&__back {
      width: 100%;
      padding: 16px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      transition: background-color 0.1s ease-in-out;
    }

    &__continue {
      background-color: #135AC0;
      color: #ffffff;
      border: none;

      &:active {
        background-color: #0f4ea6;
      }
    }

    &__back {
      border: 1px solid #D4DEF1;
      color: #135AC0;

      &:active {
        background-color: #e9e9e9;
      }
    }
  }

  &__address {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 347px;
  }
}
