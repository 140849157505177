.integration-prompt {
  max-width: 600px;
}

.no-list-padding {
  padding-left: 20px !important;
}

.button-right-margin {
  margin-right: 12px !important;
}

.customers-import-prompt {
  width: 548px !important;
}

.gray-coloring {
  color: #757575;
}

.top-margin-title {
  margin-top: 12px;
}
