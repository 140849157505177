.redirect-screen {
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url('../assets/images/backgrounds/login-bg.jpg') no-repeat;
  background-size: cover;

  &__content {
    padding: 64px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 440px;
    border-radius: 10px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.22);
    background-color: white;

    &__logo {
      margin: 0px 0px 32px 0px;
      width: 128px;
      height: 128px;
      border-radius: 10px;
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.22);
    }

    &__title {
      margin: 0px 0px 32px 0px;
      font-size: 17px;
    }

    &__description {
      margin: 0px 0px 32px 0px;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.54);
      text-align: center;
    }
  }
}