.services-dialog {
  .ps-scrollbar-x {
    display: none;
  }
}

.services-input-container {
  grid-gap: 12px;

  .insert-picture {
    display: flex;
    flex-direction: column;
  }

  .insert-picture-button {
    margin-left: 14px;
    border: solid 1px #D4DEF1;
    color: #135AC0;
    font-weight: 500;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;

    width: 188px;
    height: 48px;
  }
}

.warning-icon {
  margin: 0;
  margin-right: 10px;

  svg {
    height: 20px;
    width: 20px;
  }
}

.image-input {
  &__description {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 14px;
      line-height: 16px;
      color: #757575;
      margin-left: 20px;
    }
  }
}

.inputs-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  grid-gap: 10px;
  position: relative;
  margin-top: 40px;

  h3 {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    font-size: 15px;
  }

  input {
    border-color: #D4DEF1;
  }
}

.input-service-locked {
  color: gray;
}

.img-service {
  &--disabled {
    pointer-events: none;

    md-icon {
      color: #d3d3d3;
    }
  }
}

.services-image {
  object-fit: contain !important;
}
