.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  width: 500px !important;
}

.quotation-dialog {
  md-tab-content {
    padding: 0 !important;
  }

  .save-button {
    margin-right: 12px;
  }

  .cancel-button-quotations {
    background-color: #FC3868;
    transition: background-color 250ms;
    text-transform: uppercase;
    padding: 0;
    width: 200px;
    max-width: initial;

    &:hover {
      background-color: #c03b5c;
    }
  }

  .subtitle {
    color: #2e2e2e;
    font-weight: 500;
    font-size: 15px;
    margin: 0 !important;
    padding-top: 24px;
    padding-bottom: 20px;
  }

  .empty__items {
    padding: 8px 20px;
    border: 1px solid #D4DEF1;
    border-radius: 4px;
    margin-bottom: 14px;

    md-icon {
      color: #135AC0;
    }

    p {
      display: inline-block;
      margin-left: 13px;
    }
  }
}

.counter-labels {
  margin-bottom: 2px;
  font-size: 12px;
  color: gray;
  text-align: left;
  width: 100%;
  display: block;
  padding: 10px 25px;
}
