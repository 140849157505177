#gantt-chart {
    .route-or-break-task {
        height: 30% !important;
        cursor: not-allowed;
    }
    .routechecker {
        padding: 4px !important;
    }
}

.gantt-chart-task-tooltip {

    div {
        height: auto;
        line-height: 1;
        padding: 3px;

        .tooltip-name {
            margin-bottom: 6px;
        }
    }
}

.gantt-scrollable {
    max-height:100% !important;
    height:100% !important;
}

.gantt-table-content {
    max-height:100% !important;
    height:100% !important;
}

.clickable {
    cursor:pointer !important;
}

.absence-task {
    cursor: not-allowed;
    background: repeating-linear-gradient(135deg, #ccc, #ccc 5px, #FFF 5px, #FFF 10px) !important;
}

.task-hover-increase:hover {
    z-index: 100 !important;
    min-width: 100px !important;
}

.gantt-task-info {
    display: block !important;
}

.gantt-task-info-content {
   color: #000000;
   z-index: 999 !important;
}

.gantt-header-row-first {
    display:none !important;
}

.gantt-header-row {
    border-top: none !important;
}

.gantt-header, .gantt-side, .gantt-task {
    z-index: initial !important;
}

.zoom {
    margin-right: 8px;

    .label {
        margin-right: 8px;
    }

    .input-wrapper {
        border: 1px solid rgba(0, 0, 0, 0.2);
        height: 28px;
        overflow: hidden;

        .md-button {
            margin: 0;
            padding: 0;
            width: 28px;
            height: 28px;
            min-height: 28px;
            line-height: 28px;
            border-radius: 0;

            md-icon {
                font-size: 12px;
                width: 28px;
                height: 28px;
                line-height: 28px;
            }

        }

        input {
            border: none;
            border-right: 1px solid rgba(0, 0, 0, 0.2);
            border-left: 1px solid rgba(0, 0, 0, 0.2);
            background-color: transparent;
            text-align: center;
            width: 48px;
            height: 28px;
            min-height: 28px;
            line-height: 28px;
        }

    }
}

.spin-spin-spin {
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
    cursor: not-allowed;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.jumping {
    -webkit-animation: jump 0.9s ease 0s infinite normal ;
    animation: jump 0.9s ease 0s infinite normal ;
}

@-webkit-keyframes jump {
    0%{
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    20%{
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40%{
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    50%{
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    60%{
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
    80%{
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100%{
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes jump {
    0%{
        transform: translateY(0);
    }
    20%{
        transform: translateY(0);
    }
    40%{
        transform: translateY(-30px);
    }
    50%{
        transform: translateY(0);
    }
    60%{
        transform: translateY(-15px);
    }
    80%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(0);
    }
}

.f16 {
    font-size: 16px;
}

.list-card-labels {
    margin-top: -6px;

    .list-card-label {
        width: 12px;
        height: 6px;
    }
}

.avatar-status-gantt {
    position: absolute;
    top: 5px;
    left: 38px;
    width: 15px;
    height: 15px;
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    z-index: 10;

    &.none {
        background-color: #c5c5c5;
    }

    &.online {
        background-color: #00C853;
    }

    &.offline {
        background-color: #D50000;
    }

    &.away {
        background-color: #FF6D00;
    }
}

.loading-gantt-tasks {
    width: 100%;
    text-align: center;
    padding: 32px;
    font-size: 15px;
}

.ms-navigation-folded .gantt-task-info {
    margin-left: -64px;
}
.gantt-task-info {
    margin-left: 0px;
}

.ms-info-bar-active{
    .gantt-task-info {
        margin-top: -60px !important;
      }
}

.gantt-scrollable-header {
    position: relative;
    z-index: 10;

    .gantt-header {
        position: fixed;
        background-color: #fafafa;
        border-left: thin solid rgba(0, 0, 0, .15);
    }
}

.gantt-scrollable {
    margin-top: 52px;
}
