.fc-smart-rewrite {
  position: relative;
  display: block;

  &--active {
    textarea, input {
      border: 1.5px solid #135AC0 !important;
    }

    label {
      color: #135AC0 !important;
      font-weight: 500 !important;
    }

    .fc-smart-rewrite__buttons {
      pointer-events: all;
      opacity: 1;
    }
  }

  &__buttons {
    position: absolute;
    top: 0;
    right: 0;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
    gap: 6px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%);
    transition: visibility 250ms, opacity 250ms;
    z-index: 10000;
  }

  &__button {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: none;
    background: #135AC0;
    color: #FFF;
    cursor: pointer;

    span {
      font-size: 12px;
      font-weight: 500;
    }

    &--secondary {
      background: #d0def2;
      color: #125ac0;
    }
  }
}
