$size: 48px;
$padding: 12px;

.fc-input {
  position: relative;
  height: $size;

  &:hover {
    .fc-input__input:not(.disabled):not(.invalid):not(.without-border):not([disabled]) {
      padding-right: calc(#{$padding} - 0.6px);
      padding-left: calc(#{$padding} - 0.6px);
      border: 1.6px solid #777777;
    }

    .fc-input__input--with-prefix:not(.disabled):not(.invalid) {
      padding-left: 4.25% !important;
    }

    .fc-input__input__button {
      border-left: 1.6px solid #777777;
    }
  }

  &--with-border {
    display: flex;
    border: 1px solid #e0e0e0 !important;
    border-radius: 4px !important;

    .fc-input__input {
      width: 50%;
    }

    &:hover,
    &:focus {
      border-color: #777777 !important;

      .fc-input__half {
        border-color: #777777 !important;
      }
    }
  }

  &--with-prefix {
    .fc-input__input {
      padding-left: 38px !important;
    }

    &:hover {
      .fc-input__input:not(.disabled):not(.invalid) {
        padding: 0 37.4px !important;
      }
    }
  }

  &__prefix {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: #797979;
    border-radius: 50%;
  }

  &__input {
    height: 100%;
    width: 100%;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    background: #ffffff;
    color: #000;
    border-radius: 4px;
    padding-right: $padding;
    padding-left: $padding;
    transition: border-color 250ms;

    &__button {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      height: $size;
      width: $size;
      border-left: 1px solid #e0e0e0;
      cursor: pointer;
      transition: border-color 250ms;

      button {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }

    &--without-border {
      border: none !important;
    }

    &:focus:not(.fc-input__input--without-border) {
      padding-right: calc(#{$padding} - 0.6px);
      padding-left: calc(#{$padding} - 0.6px);
      border: 1.6px solid #777777;
    }

    &:not(:placeholder-shown), &:focus, &--force-label-open {
      & + .fc-input__label {
        top: 0;
        left: calc(#{$padding} / 2);
        font-size: 12px;
        padding: 0 5px;
      }
    }

    &:required ~ .fc-input__label {
      &::after {
        content: '*';
      }
    }

    &.disabled, &[disabled] {
      color: #757575;
    }

    &.invalid {
      border-color: #e20909;

      & + .fc-input__label,
      & + .fc-input__label--no-animation {
        color: #c20606 !important;
      }
    }

    &--with-prefix {
      &__label {
        left: 4.3% !important;
      }

      &:not(:placeholder-shown),
      &:focus {
        padding-left: 4.3% !important;

        & + .fc-input__label {
          top: 0;
          left: 12px !important;
          font-size: 12px;
        }
      }
    }

    &--change-border-color {
      border-color: #D4DEF1;
    }
  }

  &__label,
  &__label--no-animation {
      position: absolute;
      top: 50%;
      left: 12px;
      color: #757575;
      font-size: 14px;
      background-color: #fff;
      border-radius: 4px;
      transform: translateY(-50%);
      transition: top 250ms, left 250ms, font-size 250ms, transform 250ms;
      pointer-events: none;
    }

  &__input-hint {
    color: #757575;
    font-size: 12px;
  }

  &__label--no-animation {
    background-color: white;
    top: 0;
  }

  &__input-error {
    position: absolute;
    bottom: -20px;
    white-space: nowrap;
  }

  &__input-hint {
    position: absolute;
    left: $padding;
    bottom: -20px;
    white-space: nowrap;
    font-size: 12px;
    color: #757575;
  }

  &__half {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #e0e0e0;
    transition: border-color 150ms;
    font-size: 14px;
    color: #000;
    width: 50%;
  }

  &__suffix {
    position: absolute;
    right: $padding;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    transform: translateY(-50%);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: border-color 250ms;
  }

  &--with-suffix {
    position: relative;
  }
}
