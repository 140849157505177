.customers-import-manual-dialog {
  width: 750px;
  height: 600px;

  .text-wrap {
    white-space: normal !important;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .fs-16 {
    font-size: 16px;
  }

  .action-centralize {
    width: 88px;
    text-align: center;
    margin-right: 8px;

    md-progress-circular {
      margin: 0 auto;
    }
  }

  .search-customers-container {
    position: relative;

    md-progress-circular {
      position: absolute;
      right: 0;
    }
  }

  md-list-item h3 {
    margin-bottom: 4px !important;
  }

  .md-3-line.extended {
    min-height: 120px;
  }

  .customer-infos {
    display: block;
    width: 100%;
  }

  .customer-infos>div {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
  }

  .customer-infos>div i {
    margin-right: 4px;
  }

  .customer-archived-tag {
    display: inline-block;
    padding: 0px 6px;
    background: #888;
    font-size: 11px;
    border-radius: 4px;
    color: #fff;
    margin-left: 6px;
    vertical-align: middle;
  }
}