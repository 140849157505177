.accept-suggestion-dialog {
  display: grid;
  gap: 24px;
  padding: 32px;
  width: 553px;

  &__close-button {
    position: absolute;
    border: 0;
    right: 0;
    top: 0;
    margin: 16px;
    cursor: pointer;
  }

  &__title {
    color: #262626;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  &__content {
    color: #757575;
    font-size: 14px;
    line-height: 20px;
  }

  &__suggestions {
    &__suggestion {
      display: flex;
      justify-content: space-between;
      padding: 16px 0;

      &:first-child {
        padding-top: 0;
      }
    
      &:not(:first-child) {
        border-top: 1px solid #D4DEF1;
      }
    
      &:last-child {
        padding-bottom: 0;
      }

      &__date, &__time {
        display: grid;
        gap: 8px;

        &__title {
          color: #757575;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
        }

        &__value {
          color: #000000;
          font-size: 14px;
          line-height: 16px;
        }
      }

      &__accept-button {
        border: 1px solid #D4DEF1;
        border-radius: 4px;
        padding: 12px;

        color: #135AC0;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;

        &:hover {
          color: #FFFFFF;
          background-color: #0957c3;
        }
      }
    }
  }
}

.reatroactive-schedule-suggestion-dialog {
  padding: 32px;
  width: 470px;
  
  .fc-modal {
    &__title {
      font-size: 15px;
      color: #262626;
      line-height: 18px;
    }

    &__content {
      margin: 24px 0;
    }

    &__button {
      width: 197px;
      height: 48px;
      text-transform: none;
    }
  }
}