.fc-skeleton {
  position: relative;
  background-color: #9E9E9E;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, transparent 20%, rgba(255, 255, 255, 0.5) 50%, transparent 80%);
    animation-name: fc-skeleton;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    @keyframes fc-skeleton {
      0% {
        left: -100px;
      }
      100% {
        left: 100%;
      }
    }
  }
}