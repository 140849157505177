.angular-leaflet-map {
    .leaflet-control-layers-toggle {
        background-image: url(../assets/icons/leaflet/layers.png);
        width: 36px;
        height: 36px;
    }
    .leaflet-retina .leaflet-control-layers-toggle {
        background-image: url(../assets/icons/leaflet/layers-2x.png);
        background-size: 26px 26px;
    }
    .leaflet-control-fullscreen {
        a.leaflet-control-fullscreen-button {
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="26" height="52"><g color="rgb(0,0,0)" fill="rgb(3,155,229)"><path style="marker:none" d="M5 15v6h6v-2H7v-4zM21 15v6h-6v-2h4v-4zM10 37v4l1 1h4l1-1v-4l-1-1h-4zM5 11V5h6v2H7v4zM21 11V5h-6v2h4v4zM5 41v6h6v-2H7v-4zM21 41v6h-6v-2h4v-4zM5 37v-6h6v2H7v4zM21 37v-6h-6v2h4v4z" overflow="visible"/></g></svg>');
            background-position: 50% 2px;
        }
    }
    .leaflet-fullscreen-on {
        a.leaflet-control-fullscreen-button {
            background-position: 50% -24px;
        }
    }
    .legend {
        font: 12px/14px Arial, Helvetica, sans-serif;
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 6px 8px;
        width: 190px;
        line-height: 18px;
        color: #555;
        i {
            width: 16px;
            height: 16px;
            float: left;
            margin-right: 8px;
            opacity: 0.9;
            line-height: inherit;
            font-size: inherit;
        }
    }
}