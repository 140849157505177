.filter-absences {
    md-list {
        md-list-item {
            .w-100-percent {
                width: 100%;
            }
        }
    }

    .bg-grey {
        background-color: #ccc;
    }

    .icon-account-remove {
        position: relative;
        bottom: -3px;
    }
}

.absence-name-employee{
    display: flex;
    flex-direction: row;
}
