.fc-select-mutiple {
  position: relative;

  .md-select-value {
    border: 1px solid #e0e0e0;
    font-size: 14px;
    height: 43px;
    border-radius: 4px;
    transition: border-color 250ms;
  }

  .md-select-value > span {
    width: 120px;
  }

  .md-select-value > span >div > span {
    margin-right: -3px !important;
  }

  .md-select-value:hover {
    border: 1px solid #777777;
    transition: border-color 250ms;
  }

  .md-input-container.md-default-theme label.md-required:after, md-input-container label.md-required:after {
    color: #757575 !important;
  }

  .md-select.md-default-theme:not([disabled]):focus .md-select-value, md-select:not([disabled]):focus .md-select-value {
    border-bottom: 1px solid #e0e0e0;
  }

  .md-select.md-default-theme.ng-invalid.ng-touched .md-select-value, md-select.ng-invalid.ng-touched .md-select-value {
    border-color: #d50000 !important;
  }

  .fc-select-mutiple__label {
    background-color: white !important;
    max-width: fit-content !important;
    height: 20px;
    bottom: 44px;
    left: 43px;
  }

  md-input-container.md-input-focused .md-placeholder {
    opacity: 0 !important;
  }

  .md-select-value > span:not(.md-select-icon) {
    margin-left: 8px;
    font-size: 14px;
    background-size: auto;
    background-color: white;
    left: 13px;
    max-width: fit-content;
    width: fit-content;
  }

  .md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused label, md-input-container:not(.md-input-invalid).md-input-focused label {
    color: #757575 !important;
  }

  .fc-select-mutiple md-select .md-select-value:hover {
    border: 1.6px solid #777777 !important;
    height: 42.4px !important;
    transition: border-color 250ms !important;
  }

  .md-input-container.md-input-invalid md-select.md-default-theme .md-select-value, md-input-container.md-input-invalid md-select .md-select-value {
    border: 1px solid #e0e0e0 !important;
    color: #757575 !important;
  }

  .md-select-icon::after {
    opacity: 0;
  }

  .with-divider::after {
    top: 2px !important;
  }
}
