.ms-card {

    .template-5 {

        .media {
            width: 80px;
            min-width: 80px;
            max-width: 80px;
        }
        // ie10 fix
        .info, .media {
            flex: 0 1 auto;
            -ms-flex: 0 1 auto;
        }
    }
}