.ms-card {

    .template-11 {

        .media {
            width: 80px;
            min-width: 80px;
            max-width: 80px;
        }
    }
}