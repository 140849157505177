#contacts {
  .h-125 {
    height: 125px;
    max-height: 125px;
    min-height: 125px;
  }

  .p-0 {
    padding: 0 !important;
  }

  md-list-item {

    &.selected {

      .md-button {
        font-weight: 500;
        color: #000000;
      }

    }
  }
}