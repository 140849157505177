
.tag-item {
    background: none !important;
}

.tag-item.selected {
    background: #ccc !important;
}

tags-input .tags {
    border: none;
    box-shadow: none !important;
}

tags-input .tags.focused {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
}

tags-input.with-border .host {
    border: 1px solid rgb(204, 204, 204);
}

tags-input .tags .input {
    font: inherit;
    width: 100%;
    min-width: 100px;
}

tags-input .tags .tag-item {
    border-radius: 0 !important;
}

.suggestion-item.selected {
    color: #fff;
    background-color: #039BE5 !important;
}

tags-input .autocomplete .suggestion-item {
    font-size: 14px !important;
}

tags-input .autocomplete {
    margin-top: -2px;
    margin-left: -1px;
}