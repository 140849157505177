.quotation-general-tab {
  .fc-datepicker {
    z-index: 5 !important;
  }

  .fc-warning-container {
    padding: 0 20px !important;
  }

  .md-datepicker-input:disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  &__circle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }

  .warning-icon {
    color: #135ac0;
  }

  &__only-status {
    padding: 30px 0 0 20px;
  }

  fieldset {
    display: grid;
    grid-gap: 20px;
    border: 0;
    margin: 0;
    padding: 0 20px;

    h3 {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }

    md-input-container {
      margin: 0 !important;
    }
  }

  &__input-container {
    grid-gap: 15px;
    margin-bottom: 5px;

    .fc-autocomplete__dropdown__item {
      z-index: 20;
    }

    .fc-input__input--with-prefix__label {
      left: 32px !important;
    }

    .fc-input__input--with-prefix:not(:placeholder-shown),
    .fc-input__input--with-prefix:focus {
      padding-left: 30px !important;
    }
  }

  h3 {
    padding: 30px 0 30px 0;
  }

  &__send-quotation-container {
    padding: 0 25px;
    padding-bottom: 30px;

    &__disabled-quotation-approve-by-corporate {
      font-size: 14px;
      border: 1px solid #e0e0e0 !important;
      background: #ffffff;
      border-radius: 4px !important;
      padding: 14px 12px !important;
    }

    &__item,
    &__add-button {
      margin-bottom: 25px;
    }

    &__add-button {
      width: 100%;
    }

    &__item {
      grid-gap: 8px;

      &__email-number {
        font-size: 14px;
        font-weight: 400;
        color: #2b2b2b;
      }

      &__email-phrase {
        font-size: 13px;
        color: #9e9e9e;
        background-color: transparent !important;
      }

      &__remove-button {
        height: 48px;
        background-color: transparent !important;
        border: 1.8px solid #d3003f;
        color: #d3003f;

        &:hover {
          background-color: #f8f8f8 !important;
          border-color: #c0023b;
          color: #c0023b;
        }
      }

      &__resend-button {
        height: 48px;
        background-color: transparent !important;
        border: 1.8px solid #9e9e9e;
        color: #135ac0;

        &:hover {
          background-color: #f8f8f8 !important;
          border-color: #838383;
          color: #0d4ca3;
        }

        &:disabled {
          background-color: transparent !important;
          color: #707070 !important;

          &:hover {
            background-color: transparent !important;
            color: #707070 !important;
          }
        }
      }
    }
  }

  .quotation-create-order {
    gap: 12px;

    &__button {
      display: flex;
      height: 48px;
      max-width: 100%;
      padding: 8px 16px;
      align-items: center;
      gap: 8px;
      text-transform: initial;
      border-radius: 4px;
      background-color: var(--normal-blue, #135ac0);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    }

    &__button:disabled {
      border-radius: 4px;
      background: var(--button-disabled, #cfcfcf);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      pointer-events: none;
    }
  }
}

.md-datepicker-calendar-pane {
  z-index: 1001;
}

.md-open-menu-container {
  z-index: 1002;
}

.md-menu-backdrop{
  z-index: 1002 !important;
}

.md-panel-outer-wrapper {
  z-index: 1004 !important;
}

md-toast {
  z-index: 1005 !important;
}

.fc-progress {
  z-index: 1006 !important;
}