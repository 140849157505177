#mail {
  .center {
    .header {
      .sidenav-toggle {
        margin: 0;
        width: 56px;
        height: 56px;
        background: #ffffff;
        border-radius: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
      }

      md-list md-list-item {
        input {
          height: 35px;
          border: 1px solid #ccc;
        }
      }
    }
  }
}

.suggest-new-date-modal {
  width: fit-content !important;
}

.accept-suggest-modal {
  width: 553px;
}

.header-text {
  display: flex;
  gap: 1rem;

  &__new-version-button {
    display: inline-flex;
    padding: 6px 12px 6px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #CDF;
    background: #FFF;
    transition: background 0.3s;

    span {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  
    md-icon {
      width: 20px;
      height: 20px;
      max-width: 20px;
      max-height: 20px;
      min-height: 20px;
      min-width: 20px;
    }

    &:hover {
      background: #FAFAFA;
    }
  }
}

.fade {
  transition: opacity 0.5s linear;
}

.fade.ng-enter {
  opacity: 0;
}

.fade.ng-enter-active {
  opacity: 1;
}

.fade.ng-leave {
  opacity: 1;
}

.fade.ng-leave-active {
  opacity: 0;
}