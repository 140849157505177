.fc-datepicker {
  position: relative;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px;

  &--without-after-border {
    &::after {
      border-left: none !important;
    }
  }

  &--change-border-color {
    border: 1px solid #D4DEF1 !important;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;

    content: '';
    display: block;
    height: 100%;
    width: 45px;

    cursor: pointer;
    border-left: 1px solid #e0e0e0;
    background-color: #ffffff;
    pointer-events: none;
    transition: border-color 250ms;
  }

  &__label {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    position: absolute;
    top: -8px;
    left: 6px;
    padding: 0 2px;
  }

  &.invalid {
    border-color: #e20909 !important;

    & .fc-datepicker__label {
      color: #c20606 !important;
    }

    & .fc-datepicker__error {
      font-size: 12px;
    }
  }

  .md-datepicker-input-container {
    position: absolute !important;
    top: 28% !important;
    left: 8px !important;
    padding: 0 !important;
    border: none !important;
    width: calc(100% - calc(45px + 16px));

    input {
      min-width: 100%;
      max-width: 100%;
    };

    button {
      position: absolute;
      right: -55px;
      top: 50%;
      transform: translateY(-50%);

      .md-datepicker-expand-triangle {
        border-top-color: #135ac0 !important;
      }
    }
  }

  .md-datepicker-open .md-datepicker-triangle-button, .md-datepicker-open.md-input-has-value > label, .md-datepicker-open.md-input-has-placeholder > label {
    display: initial;
  }

  .md-datepicker-triangle-button {
    z-index: 15;
  }

  md-datepicker[disabled] .md-datepicker-triangle-button {
    display: initial;
  }

  &::after {
    border-left: none;
  }
  
  .md-datepicker-input-container button .md-datepicker-expand-triangle {
    filter: grayscale(1);
  }

  input:hover {
    border: none !important;
  }

  .md-datepicker-input {
    pointer-events: none;
  }
}