#archive-service-alert-dialog {
  padding: 0 8px 15px 8px;

  h3.md-title {
    font-size: 16px;
  }

  .task-types {
    border: thin solid #d3ddf2;
    border-radius: 8px;
    max-height: 300px;
    overflow-y: auto;
    table-layout: fixed;
    width: 100%;

    table {
      border-collapse: separate;
      border-spacing: 0;
      width: 100%;
    }

    thead th {
      background-color: #ffffff;
      color: #8a8888;
      position: sticky;
      top: 0;
      width: 50%;
    }

    td,
    th {
      border-bottom: thin solid #d3ddf2;
      padding: 1rem 1.2rem;
    }

    tr th:first-child,
    tr td:first-child {
      border-right: thin solid #d3ddf2;
    }

    tr:last-child td {
      border-bottom: none;
    }
  }

  md-dialog-actions {
    .cancel-button,
    .confirm-button {
      border: thin solid #135ac0;
      border-radius: 4px;
      padding: 0 2rem;
    }

    .cancel-button {
      background-color: #135ac0;
      color: #fff;

      &:hover {
        background-color: #084599;
      }
    }

    .confirm-button {
      background-color: #fff;
      color: #135ac0;

      &:hover {
        background-color: #d7d7d7;
      }
    }
  }
}
