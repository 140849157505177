$equipment-logs-color: (
  created: #b1b1ff,
  changed: #8d8d8d,
  archived: #5f7c8b,
  unarchived: #505b60
);

.equipment-dialog__historic-tab {
  &__log {
    &_date {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      color: #757575;
    }

    &__container {
      padding: 24px 0;

      &-status {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &_icon {
          width: 48px;
          height: 48px;
          border-radius: 24px;

          svg, path {
            fill: #ffffff;
          }

          @each $name, $color in $equipment-logs-color {
            &--#{$name} {
              background: $color;
            }
          }
        }

        &_line {
          width: 1px;
          margin-top: 10px;
          height: calc(100% - 58px);
          background-color: #d4def1;
        }
      }

      &__detail {
        width: 100%;
        padding: 20px;
        min-height: 48px;
        margin-left: 12px;
        border-radius: 6px;
        border: thin solid #d4def1;

        &_description {
          margin: 0;
          font-size: 14px;
          margin-top: 20px;
          font-weight: 400;
          color: #757575;

          b {
            font-weight: 500;
          }

          p {
            margin: 0;
            padding: 0;
            margin-top: 10px;
          }

          img {
            width: 70px;
            height: 70px;
            margin-top: 12px;
            object-fit: cover;
            border-radius: 4px;
          }

          &-pictures {
            gap: 12px;
            width: 100%;
            display: grid;
            flex-direction: 'row';
            grid-template-columns: repeat(8, 70px);
          }

          &-category {
            padding: 10px;
            margin-top: 12px;
            line-height: 24px;
            border-radius: 6px;
            border: thin solid #d4def1;

            p {
              margin: 0;
            }
          }
        }

        &_top {
          &-date {
            margin: 0;
            padding: 0;
            font-size: 13px;
            color: #757575;
            font-weight: 400;
          }

          &-title {
            margin: 0;
            padding: 0;
            font-size: 14px;
            color: #262626;
            font-weight: 400;
            text-align: left;

            b {
              font-weight: 600;
            }

            @each $name, $color in $equipment-logs-color {
              &--#{$name} b {
                color: $color;
              }
            }
          }
        }

        &-author {
          font-size: 12px;
          color: #757575;
          font-weight: 400;

          md-icon {
            font-size: 20px;
          }

          p {
            height: 24px;
            margin: 0 2px 0;
            line-height: 24px;
          }
        }
      }
    }
  }
}
