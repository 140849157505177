.form-dialog {
    .plr-24 {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .mt-20n {
      margin-top: -20px;
    }

    .ml-24n {
        margin-left: -24px !important;
    }

    .mb-25n {
        margin-bottom: -25px !important;
    }

    .fs-16 {
        font-size: 16px;
    }

    .ml-7n {
        margin-left: -7px !important;
    }

    .mb-5n {
        margin-bottom: -5px !important;
    }

    .mt-25n {
        margin-top: -25px !important;
    }

    .ml-42n {
        margin-left: -42px;
    }

    label.date-label {
        left: 0 !important;
        right: 0 !important;
        width: auto !important;
    }

    .strength-meter{
        &:before {
        left: 20%;
        }
        &:after {
        right: 20%;
        }

        &:before, &:after {
        width: 20%;
        }
    }

}

md-autocomplete-parent-scope {
    .alert-km-distance {
        float:right;
        font-size:11.5px;
        color:#000;
    }
}

.avatar-employee {
    width: 50px;
    min-width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.avatar-wrapper {
    text-align: center;
}

.crop-area-employee {
    background: #E4E4E4;
    overflow: hidden;
    width: 100%;
    height: 250px;
}

.upload-content {
    height: 260px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: -webkit-center;
 }

 .cursor- {
    &pointer {
        cursor: pointer;
    }

    &default {
        cursor: default;
    }
 }

.credential-button {
    margin-left: 14px;

    border-radius: 4px;
    color: #135AC0;
    font-weight: 500;
    border: 1px solid #CDF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);

    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 16px;
    padding-block: 8px;
    gap: 8px;

    height: 40px;
}