.customers-area-dialog {
  .slug-container {
    width: 100%;

    &__start-url {
      flex: 1 1 100%;
      max-width: 8.3%;
      max-height: 100%;
      box-sizing: border-box;
      padding-top: 7px;
    }


    &__input {
      padding-right: 280px !important;

      .md-icon-button {
        position: absolute;
        top: 0px;
        margin: 0;
        right: 35px;
        left: auto;
      }
    }

    &__end-url {
      position: absolute;
      top: 20%;
      margin: 0;
      right: 100px;
      left: auto;
    }
  }

  md-tabs-wrapper {
    display: none;
  }

  .customers {

    &__title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
    }

    &__toolbar {
      padding: 0px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__wrapper {
        position: relative;
      }

      &__search {
        padding: 6px;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 300px;
        border-bottom: thin solid #d3ddf2;

        input {
          width: 100%;
          border: none;
        }

        md-icon {
          color: #0957c3;
          margin: 0px 6px 0px 0px;
        }
      }

      &__button {
        padding: 0px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: none;
        width: 180px;
        border-radius: 10px;
        font-size: 12px;

        md-icon {
          margin: 0px;
        }
      }

      &__limit-indicator {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-100%, -50%);
        color: rgba(0, 0, 0, 0.8);
      }
    }

    &__list {
      padding: 16px;

      &__not-found {
        padding-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          padding: 15px 10px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          text-align: center;
          border: thin solid rgba(0, 0, 0, 0.4);
          border-radius: 10px;
        }
      }
    }

    &__loading {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }

    &__wrapper {
      padding: 0px 16px;
    }

    &__person {
      $this: &;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;
      border-bottom: thin solid #d3ddf2;

      &--inactive {

        .customers__person__info {

          &__logo {
            filter: grayscale(100%);
          }

          &__name {
            color: #9E9E9E;
          }

          &__description {
            color: #9E9E9E;
          }
        }
      }

      &.no-border {
        border-bottom: none;
      }

      &__info {
        display: flex;
        align-items: center;
        width: 80%;

        &__logo {
          margin-right: 16px;
          height: 64px;
          max-height: 64px;
          min-height: 64px;
          width: 64px;
          max-width: 64px;
          min-width: 64px;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
        }

        &__letter-logo {
          margin-right: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 64px;
          max-height: 64px;
          min-height: 64px;
          width: 64px;
          max-width: 64px;
          min-width: 64px;
          border-radius: 50%;
          box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
          background-color: #9E9E9E;
          color: white;
          font-size: 24px;
          font-weight: 500;
        }
        
        &__container{
          max-width: 99%;
        }

        &__name {
          margin: 0px;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.8);
          font-weight: 500;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &__description {
          margin: 0px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
          max-width: 300px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &__warn {
          margin: 0px;
          font-size: 12px;
          color: rgb(9, 87, 195);
          max-width: 300px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &__buttons {
        display: flex;
      }
    }

    &__new-user {
      display: block;
      padding: 24px;

      md-input-container {
        display: block;
        max-width: 300px;
      }

      &__title {
        margin-bottom: 8px;
        display: block;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }

      &__subtitle {
        display: block;
        width: 100%;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
      }

      &__buttons {
        display: flex;

        .md-button {
          margin-top: 0px;
          margin-bottom: 0px;
          width: 124px;
          height: 32px;
          border-radius: 5px;
          font-size: 12px;
          text-transform: none;

          &:first-child {
            margin-left: 0px;
          }
        }

        .cancel-button {
          color: #ff3365;
          border: thin solid #ff3365;
        }
      }
    }
  }

  .module-sidenav__button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
