.resize-logo-dialog {

  md-dialog-content {
    padding: 0px;
    position: relative;
  }

  md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .crop-area-square {
    background: #E4E4E4;
    overflow: hidden;
    width: 300px;
    height: 300px;
  }
}