.products-tab {
  height: 850px;

  &.tab-min-height {
    height: 700px;
  }

  &.tab-medium-height {
    height: 750px;
  }

  &__content {
    padding: 24px 0 0 0 !important;

    &#products-tab-content {
      height: 100% !important;
    }

    .fc-input__input--search {
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      border: 1px solid #D4DEF1;
    }

    &__search-container {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;
    }

    &__search-bar {
      width: 100%;

      input {
        padding: 0 34px !important;
        border-radius: 4px;
      }

      label {
        left: 34px;
      }

      md-icon {
        position: absolute;
        left: 9px;
        top: 12px;
        color: #135AC0;
        font-size: 18px !important;
      }
    }

    &__link-button {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none !important;
      background-color: #135AC0 !important;
      color: #ffffff !important;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      text-transform: none;
      margin: 0px !important;
      padding: 6px 16px !important;
      min-width: 190px;

      &--disabled {
        background: #719CD9 !important;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 16px;
      color: #262626;
      margin: 24px 0;
    }

    &__value {
      display: flex;
      padding: 12px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #D4DEF1;
      margin-bottom: 16px;

      &__highlighted {
        color: #135AC0;
        font-size: 13px;
        font-weight: 500;
        line-height: 150%;
        margin: 0 0 0 3px;

        &--sign {
          margin: 0px 12px;
          color: #135AC0;
          font-size: 13px;
          font-weight: 500;
          line-height: 150%;
        }
      }

      &__not-highlighted {
        margin: 0;
        color: #757575;
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
      }
    }

    &__table {
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #D4DEF1;

      &__content {
        width: 100%;
        border-collapse: collapse;

        &__header {
          height: 39px;
          border-bottom: 1px solid #D4DEF1;

          &__row {
            th {
              color: #757575;
              font-size: 13px;
              font-weight: 500;

              &:first-child {
                cursor: pointer;
                padding-left: 16px;
                width: 360px;

                &:hover {
                  md-icon {
                    opacity: 1;
                    transition: all 0.2s ease-in-out;
                  }
                }

                md-icon {
                  font-size: 16px;
                  width: 16px;
                  height: 16px;
                  min-width: 16px;
                  line-height: 16px;
                  min-height: 16px;
                  margin: 0 8px;
                  opacity: 0;

                  &.name-asc {
                    transform: rotate(180deg);
                  }
                }
              }
            }

            &__value {
              width: 125px;
            }
          }
        }

        &__items {
          &__row {
            overflow: hidden;
            color: #262626;
            text-overflow: ellipsis;
            font-family: Roboto;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.13px;
            border-bottom: 1px solid #D4DEF1;

            &__quantity {
              width: 122px;

              &__content {
                z-index: 5;
                position: absolute;
                margin-top: 12px;
                top: 12px;
                height: 52px;
                margin-right: 12px;

                fc-error {
                  width: 100%;
                  .fc-input__input-error {
                    bottom: -15px;
                  }
                }

                &__word-break {
                  white-space: pre-wrap !important;
                  position: relative;
                  width: 100%;

                  .fc-error__item {
                    position: absolute;
                    top: -14px;
                    left: 5px;
                  }
                }
              }
            }

            &__value {
              width: 122px;

              &__content {
                z-index: 5;
                position: absolute;
                margin-top: 12px;
                top: 12px;
                height: 52px;
                margin-right: 12px;

                fc-error {
                  width: 100%;
                  .fc-input__input-error {
                    bottom: -15px;
                  }
                }

                &__word-break {
                  white-space: pre-wrap !important;
                  position: relative;
                  width: 100%;

                  .fc-error__item {
                    position: absolute;
                    top: -14px;
                    left: 5px;
                  }
                }
              }
            }

            &__remove {
              button {
                border: 0;
              }
            }

            &:last-child {
              border-bottom: 0;
            }

            td {
              position: relative;
              height: 110px;
            }

            &__cell {

              &--image-name {
                display: flex;
                padding-left: 16px;
                gap: 8px;

                &__default-image,
                &__image {
                  margin: auto 0;
                  border-radius: 4px;
                  border: 0.533px solid #D4DEF1;
                  overflow: hidden;
                }

                &__default-image {
                  height: fit-content;

                  img {
                    width: 20px;
                    height: 20px;
                    padding: 14px;
                    box-sizing: content-box;
                  }
                }

                &__image {
                  height: 48px;
                  width: 48px;

                  img {
                    width: 100%;
                    height: 100%;
                    box-sizing: content-box;
                    object-fit: cover;
                  }
                }

                &__title {
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  justify-content: center;
                  gap: 4px;

                  &__name {
                    color: #262626;
                    width: 270px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }

                  &__measure {
                    color: #757575
                  }
                }
              }
            }
          }
        }
      }

      &__navigation {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        padding: 12px 16px;
        border-top: 1px solid #D4DEF1;

        &__text {
          color: #757575;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0.13px;
        }

        &__button-previous,
        &__button-next {
          padding: 10px 12px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
        }

        &__button-previous {
          border: 1px solid #F2F2F2;
          color: #262626;
        }

        &__button-next {
          border: 1px solid #D4DEF1;
          color: #135AC0;
        }

        &__button-previous:disabled,&__button-next:disabled {
          cursor: not-allowed;
          border: 1px solid #F2F2F2;
          color: #757575;
        }
      }
    }

    &__loading {
      margin: 18rem 0;
      gap: 2rem;
    }

    &__not-found {
      display: grid;
      justify-content: center;
      justify-items: center;

      color: #757575;
      border: 1px solid #D4DEF1;
      border-radius: 8px;
      padding: 32px 0px;
      gap: 16px;
    }

    &__product {
      display: flex;
      border: 1px solid #D4DEF1;
      padding: 20px;
      padding-left: 0px;
      border-radius: 4px;
      margin-bottom: 16px;

      &__info {
        display: inline-block;
        margin-left: 22px;
        margin-top: 10px;
        font-weight: 400;
        font-size: 14px;

        &__name {
          color: #262626;
        }

        &__measure,
        &__value {
          color: #757575;
        }
      }

      &__quantity {
        width: 100%;
        z-index: 5;
        position: absolute;
        margin-left: 640px;
        margin-top: 6px;
      }

      .fc-input {
        width: 110px !important;
      }

      &__trash {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        width: 790px;
        margin-top: 20px;
        z-index: 4;

        button {
          border: none;
          z-index: 3;
        }
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .fc-input__input.invalid {
    border-color: #FC3868 !important;
  }

  .fc-input__input.invalid+.fc-input__label {
    color: #FC3868 !important;
  }

  .fc-error__item {
    color: #FC3868 !important;
  }
}
