.filter-customers {
  md-list {
    md-list-item {
      .w-100-percent {
        width: 100%;
      }
    }
  }

  .filter-property-name {
    padding-left: 10px;
    height: 25px;
    color: rgba(0, 0, 0, 0.54);
  }

  .star-color {
    color: #ffb508 !important;
  }
}

#table-list {
  .star-style {
    font-size: 14px;
    color: #ffb508;
  }
}

.customer-rating-tooltip {
  display: table;
  line-height: 13px;
  max-width: 500px;
  white-space: pre-line;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 11px;
}
.leaflet-div-icon {
  background: none !important;
  border: none !important;
}
.leaflet-popup-content {
  font-size: 12px !important;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.map-loader {
  height: 150px;
  width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.loader-map {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customers-map-button {
  text-align: center;
  margin: auto;
  display: block;
  height: 325px;
}

.icon-customer-import svg {
  width: 20px;
  margin: 0 auto;
}

.avatar-customer {
  max-width: 30px;
  min-width: 30px;
  height: 100%;
  max-height: 30px;
  min-height: 30px;
  border-radius: 50%;
  margin: 0 8px 0 0;
  object-fit: cover;
}

.avatar-letter {
  width: 30px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}

.text-light-gray {
  color: #9e9e9e;
}

.content-card.customer-map-down-margin {
  margin-bottom: 32px;
}
