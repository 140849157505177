md-dialog.md-default-theme, md-dialog {
  background-color: #FFFFFF !important;
}

.mleft-10 {
  margin-left: 10px;
}

.ml-10i {
  margin-left: 10px !important;
}

.mleft-15 {
  margin-left: 15px;
}

.mtop-10 {
  margin-top: 10px !important;
}

.mtop-15 {
  margin-top: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.mtop-m15 {
  margin-top: -15px !important;
}

.mtop-m20 {
  margin-top: -20px !important;
}

.hide-off-page {
  position: absolute !important;
  margin-left: -9000px !important;;
  left: -9000px !important;;
}

.underlined {
  text-decoration: underline;
}

div.checkbox-list md-checkbox {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.ngrs-range-slider .ngrs-join {
  background-image: -webkit-linear-gradient(#2196F3, #2196F3) !important;
  background-image: linear-gradient(#2196F3, #2196F3) !important;
}

.tt-multiline md-tooltip .md-content {
  height: auto !important;
}

span.small-label {
  font-size: 10px !important;
  padding: 4px 4px !important;
}

.hidden-button {
  position: absolute;
  color: white !important;
  left: -99999px !important;
}

.cursor-drag {
  cursor: move !important;
}

.content-with-bl {
  white-space: pre-line !important;
}

.no-m-top {
  margin-top: 0px !important;
}

.no-m-bot {
  margin-bottom: 0px !important;
}

.no-display {
  display: none;
}

.f-right {
  float: right !important;
}

.line-thru {
  text-decoration: line-through !important;
}

.archived-task {
  text-decoration: line-through !important;
  color: #dfdfdf !important;
}

.archived-task-no-line-thru {
  color: #dfdfdf !important;
}

.activity-archived-color {
  color: #9e9e9e !important;
}

.activity-problem-color {
  color: map-get($status-colors, reported) !important;
}

.activity-onroute-color {
  color: map-get($status-colors, on-route) !important;
}

.activity-canceled-color {
  color: map-get($status-colors, canceled) !important;
}

.activity-done-color {
  color: map-get($status-colors, done) !important;
}

.activity-inprogress-color {
  color: map-get($status-colors, in-progress) !important;
}

.activity-scheduled-color {
  color: map-get($status-colors, scheduled) !important;
}

.activity-pending-color {
  color: map-get($status-colors, pending) !important;
}

.non-clickable {
  cursor: default !important;
}

.button-white-bg {
  background: white !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.button-white-bg[disabled] {
  opacity: 0.5;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.status-label {
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  border-radius: 14px;
  word-wrap: break-word;
  max-width: 100%;
  padding: 4px 8px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.fc-label {
  font-size: 12px;
  white-space: nowrap;
  padding: 2px 5px;
  border-radius: 2px;
}

.label-circle {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 2px;
}

.subtitle-info {
  color: rgba(0, 0, 0, 0.54);
  font-size: 15px;
  width: 100%;
  margin: 0 auto;
}

.avatar-status {
  position: absolute;
  top: 0px;
  left: 46px;
  width: 16px;
  height: 16px;
  border: 3px solid #FFFFFF;
  border-radius: 50%;
  z-index: 10;

  &.none {
    background-color: #c5c5c5;
  }

  &.online {
    background-color: #00C853;
  }

  &.offline {
    background-color: #D50000;
  }

  &.away {
    background-color: #FF6D00;
  }
}

md-datepicker.icon-hide {

  margin-top: 4px !important;

  .md-datepicker-input-container {
    margin-left: 1px !important;
  }

  button.md-datepicker-button {
    display: none;
  }

}

.moment-picker table.header-view {
  display: none !important;
}

.md-datepicker-input-mask {
  display: none;
}

.md-date-picker-only-icon {

  .md-datepicker-input-container {

    .md-datepicker-input {
      display: none;
    }

    .md-datepicker-triangle-button {
      display: none;
    }
  }

}

.hint {
  right: auto;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  color: grey;
}

.fake-floating-label {
  color: #828282;
  font-size: 12px;
  top: -47px;
}

.strength-meter {
  position: relative;
  height: 3px;
  background: #DDD;
  margin: 10px auto 20px;
  border-radius: 3px;
}

.strength-meter:before,
.strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #FFF;
  border-style: solid;
  border-width: 0 5px 0 5px;
  position: absolute;
  width: 80px;
  z-index: 10;
}

.strength-meter:before {
  left: 70px;
}

.strength-meter:after {
  right: 70px;
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}

.strength-meter-fill[data-strength='0'] {
  background: darkred;
  width: 20%;
}

.strength-meter-fill[data-strength='1'] {
  background: orangered;
  width: 40%;
}

.strength-meter-fill[data-strength='2'] {
  background: orange;
  width: 60%;
}

.strength-meter-fill[data-strength='3'] {
  background: yellowgreen;
  width: 80%;
}

.strength-meter-fill[data-strength='4'] {
  background: green;
  width: 100%;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px !important;
}

.avatar-select {
  width: 22px;
  min-width: 22px;
  height: 22px;
  line-height: 22px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF;
}

.avatar-select-mini {
  width: 19px;
  min-width: 19px;
  height: 19px;
  line-height: 19px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF;
}

.md-button-disabled-css {

  color: rgba(0, 0, 0, 0.38) !important;
  cursor: not-allowed !important;
  background: #dcdcdc !important;

  md-icon {
    color: rgba(0, 0, 0, 0.30) !important;
  }
}

#table-list {
  .md-button-disabled-css {
    background: none !important;
  }
}

.text-icon {
  font-size: 18px !important;
  margin-top: -4px;
}
