.fc-image-input {
  position: relative;

  &__remove-icon {
    position: absolute;
    top: -8px;
    right: -11px;
    cursor: pointer;
    z-index: 99;

    &--invisible {
      display: none;
    }
  }

  &__image-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    transition: background-color 250ms;

    &--change-border-color {
      div {
        border-color:#D4DEF1 !important;
      }
    }

    align-items: center;
    justify-content: center;

    overflow: hidden;

    &:not(.fc-image-input__image-container--disabled):hover {
      background-color: rgba($color: #111111, $alpha: 0.3);
    }

    &__options {
      display: flex;
      flex-direction: row;
      position: absolute;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      opacity: 0;

      &:not(.fc-image-input__image-container--disabled__options):hover {
        cursor: pointer;
        opacity: 1;
        background-color: rgba($color: #000000, $alpha: 0.3);
        backdrop-filter: blur(1.5px);
      }

      &__input {
        display: flex;

        &__icons {
          display: flex;
          flex-direction: column;
          margin: 8px;
          height: 0;
          align-items: center;
          justify-content: center;
          transition: background-color 250ms;
          z-index: 1;

          md-icon {
            color: #fff;
            transition: all .1s linear;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }

    &__image {
      display: flex;
      width: 100%;
      height: 100%;

      overflow: hidden;
      background-size: cover;
      object-fit: cover;
    }
  }
}
