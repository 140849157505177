.location-dialog {
  md-tabs.md-dynamic-height md-tab-content.md-active {
    padding: 0 !important;
  }

  .icon-delete {
    color: #ff003d;
    transition: color 250ms;

    &:hover {
      color: #d40434;
    }
  }
}

.filter-locations {
  background-color: #fff !important;
}

.tab-label-with-badge {
  display: flex;
  align-items: center;
  gap: 6px;

  .title-badge {
    width: 8px;
    height: 8px;
    background-color: #FC3868;
    border-radius: 50%;
  }
}
