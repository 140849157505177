#video-tutorial {
  padding: 15px 24px 24px;
  width: 100%;
  max-width: 685px;
  height: 435px;

  > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
  }

  .md-button {
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center;

    min-width: 0 !important;
    min-height: 0 !important;
  }

  .video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;

    iframe {
      border-radius: 4px;
    }

  }
}
