.pendencies-tab {
  height: 850px;

  &.tab-min-height {
    height: 700px;
  }

  &.tab-medium-height {
    height: 750px;
  }

  &__content {
    padding: 0;

    &#pendencies-tab-content {
      height: 100% !important;
    }

    &__empty-state {
      cursor: pointer;
      display: flex;
      padding: 32px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid #D4DEF1;
      background: #FFF;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
        transition: opacity 0.3s;
      }

      &__icon {
        width: 32px;
        height: 32px;
        font-size: 32px;
        min-width: 32px;
        min-height: 32px;
        color : #757575;
      }

      &__text {
        color: #757575;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &__no-permissions {
      display: flex;
      padding: 32px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid #D4DEF1;
      background: #FFF;

      &__icon {
        width: 32px;
        height: 32px;
        font-size: 32px;
        min-width: 32px;
        min-height: 32px;
        color : #757575;
      }

      &__text {
        color: #757575;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &_title {
      margin: 0;
      color: #000000;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      font-style: normal;
      line-height: normal;
    }

    &_add-button {
      width: 100%;
      margin: 24px 0 0 0; 
      padding: 20px 0;
      border-radius: 4px;
      background: #ffffff;
      border: 2px dashed #D4DEF1;
      color: #135AC0;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: normal;
      text-transform: none;

      &:hover {
        border-color: #135AC0;
        background-color: #ffffff !important;
      }
    }

    &__scroll {
      overflow: auto;
      height: 70vh;
      width: 100%;
      flex-grow: 1;
      padding: 24px;
      border: 1px solid #d4def1;
      border-radius: 8px 8px 0 0;
      margin-top: 24px;

      &::-webkit-scrollbar {
        width: 18px;
      }
    
      &::-webkit-scrollbar-track {
        background: transparent;
      }
  
      &::-webkit-scrollbar-thumb {
        background: #d4def1;
        border-radius: 20px;
        border: 5px solid white;
      }

      &.increase-height {
        @media (max-height: 1480px) {
          height: calc(65vh + 60px);
        }
  
        @media (max-height: 1081px) {
          height: calc(55vh + 60px);
        }
  
        @media (max-height: 890px) {
          height: calc(50vh + 60px);
        }
  
        @media (max-height: 780px) {
          height: calc(45vh + 60px);
        }
      }

      @media (max-height: 1480px) {
        height: 65vh;
      }

      @media (max-height: 1081px) {
        height: 55vh;
      }

      @media (max-height: 890px) {
        height: 50vh;
      }

      @media (max-height: 780px) {
        height: 45vh;
      }

      &__item {
        width: 100%;
        overflow: hidden;
        gap: 16px;
        padding: 16px;
        display: flex;
        border-radius: 6px;
        margin-bottom: 16px;
        align-items: center;
        background: #ffffff;
        border: 1px solid #d4def1;
  
        &__picture {
          overflow: hidden;
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
          display: flex;
          border-radius: 6px;
          align-items: center;
          justify-content: center;
          border: 1px solid #d4f5ff;
  
          &_logo {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
  
          &_icon {
            width: 24px;
            height: 24px;
            font-size: 24px;
            min-width: 24px;
            min-height: 24px;
            color : #9e9e9e;
          }
        }
  
        &__details {
          width: 100%;
          height: 100%;
          overflow: hidden;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          gap: 1rem;
  
          &__data {
            display: grid;
            width: 100%;
  
            &_title {
              margin: 0;
              padding: 0;
              color: #4a4a4a;
              font-weight: 400;
              font-style: normal;
              line-height: normal;
              font-size: 14px;
              max-width: 410px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
  
              &--bold {
                font-weight: 500;
              }
            }
  
            &_description {
              margin: 0;
              padding: 0;
              color: #9e9e9e;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              max-width: 410px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
  
          &__status-and-actions {
            gap: 4px;
            display: flex;
            align-items: center;
  
            &_info {
              padding: 4px 6px;
              text-align: center;
              border-radius: 3px;
              color: #ffffff;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              text-transform: uppercase;
  
              &--opened {
                min-width: 68px;
                background-color: #e2772e;
              }
  
              &--awaiting-management {
                min-width: 130px;
                background-color: #464646;
              }
  
              &--awaiting-company {
                min-width: 130px;
                background-color: #135ac0;
              }
  
              &--done {
                min-width: 68px;
                background-color: #2fb688;
              }
            }
  
            &__assignee {
              cursor: pointer;
              margin: 0px 16px;
              display: flex;
              width: 24px;
              height: 24px;
              border-radius: 24px;
              align-items: center;
              justify-content: center;
              background: #ffffff;
              border: 1px dashed #d4def1;
              transition: opacity 0.3s;

              &.locked {
                cursor: not-allowed;
              }
  
              &__button {
                height: 24px;
  
                &_picture {
                  width: 24px;
                  height: 24px;
                  object-fit: cover;
                  border-radius: 24px;
                }
  
                &_icon {
                  width: 16px;
                  height: 16px;
                  font-size: 16px;
                  min-width: 16px;
                  min-height: 16px;
                  color : #9e9e9e;
                }
              }
  
              &:hover {
                opacity: 0.8;
                transition: opacity 0.3s;
              }
            }
  
            &_open {
              margin: 0px !important;
  
              md-icon {
                width: 18px;
                height: 18px;
                font-size: 18px;
                min-width: 18px;
                min-height: 18px;
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__load-more-button {
      cursor: pointer;
      font-family: Roboto;
      text-transform: unset;
      width: 100%;
      margin: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background-color: transparent;
      padding: 22px 0;
      border: 1px solid #d4def1;
      border-top: 0;
      border-radius: 0 0 8px 8px;
      color: #4a4a4a;

      &.disabled {
        cursor: not-allowed;
        color: #9e9e9e;

        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}