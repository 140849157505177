.comments {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 220px);
  position: relative;

  &__body {
    position: relative;
    flex: 1 1 auto;
    overflow-y: scroll;
    transform: translateY(22px);

    &__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__started {
    display: block;
    text-align: center;
    background-image: linear-gradient(#d3ddf2, #d3ddf2);
    background-size: 100% 1px;
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 22px;
    margin-right: 22px;

    &__label {
      display: inline-block;
      padding: 0px 20px;
      color: #858fa4;
      background-color: #ffffff;
      font-size: 13px;
    }
  }

  &__footer {
    padding: 22px;
    padding-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 130px;
    min-height: 130px;
    border-top: thin solid rgba(0, 0, 0, 0.12);
    background-color: whitesmoke;
    transform: translateY(22px);
  }

  &__input {
    padding: 10px;
    width: 100%;
    height: 63%;
    border: thin solid #d3ddf2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: white;
    resize: none;
    text-align: justify;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #999;
      width: 8px;
      border: 5px;
    }
  }

  &__actions {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40%;
    background-color: white;
    border-left: thin solid #d3ddf2;
    border-right: thin solid #d3ddf2;
    border-bottom: thin solid #d3ddf2;

    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__label {
      margin-right: 10px;
      color: #737373;
    }

    &__select {
      border-bottom: 0 !important;
      margin-bottom: 22px;
      color: black;

      &:focus {
        md-select-value {
          border-bottom: 0 !important;
        }
      }

      md-icon {
        color: black;
      }

      md-select-value {
        border-bottom: 0 !important;
      }
    }

    &__icon {
      margin-top: -4px !important;
      font-size: 20px !important;
    }
  }

  &__icon {
    &--manager {
      color: #0957c3 !important;
    }

    &--team {
      color: #12b886 !important;
    }

    &--corporate {
      color: #f67828 !important;
    }
  }

  &__no-comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__first-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
  }
}

.comment {
  $this: &;
  margin: 16px 0px;
  padding: 0px 16px;
  display: flex;

  &__wrapper {
    margin-right: 16px;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
  }

  &__header {
    display: flex;
    align-items: center;

    &__user-name {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      flex-direction: column;

      &__comment-type {
        color: var(--text-neutral-subtle, #757575);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    &__icon {
      margin-left: 3px;
      margin-bottom: -3px;
      margin-right: 3px;
    }
  }

  &__body {
    margin: 5px 0px;
    display: flex;

    &__text {
      margin: 0px;
      padding: 10px;
      position: relative;
      max-width: 520px;
      font-size: 14px;
      background-color: white;
      border: thin solid #d3ddf2;
      border-radius: 5px;
      white-space: pre-line;
      word-break: break-word;

      &--archived {
        font-style: italic;
        white-space: nowrap;
      }
    }
  }

  &__footer {
    display: flex;

    &__date {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  &--self {
    flex-direction: row-reverse;

    #{$this}__wrapper > * {
      flex-direction: row-reverse;
    }
  }
}
