.customer-dialog__general-tab {
  padding: 26px;

  .omie-declaration-customer-container {
    display: flex;
    width: 810px;
    padding: 4px 12px;
    align-items: center;
    gap: 10px;

    margin-bottom: 16px;
    border-radius: 4px;
    border: 1px solid  #D4DEF1;
    background: #FFF;
  }

  .omie-declaration-customer-icon {
    width: 18px;
    height: 18px;
    flex: 1;
  }

  .omie-declaration-customer-text {
    padding-top: 3px;
    flex: 15;
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }


  h2 {
    font-weight: 500;
  }

  &__avatar-description {
    color: #757575;
    font-size: 14px;
  }

  &__customer-infos-row {
    grid-gap: 12px;
  }

  &__label-span {
    border-radius: 30px !important;
  }

  &__remove-label-icon {
    color: #fff !important;
    font-size: 16px !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #c9c9c9 !important;
    }
  }

  .mt-18 {
    margin-top: 18px;
  }

  .mb-25 {
    margin-bottom: 25px !important;
  }
}
