.confirm-delete-productService-dialog {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  width: 540px;

  &__title {
    color: #262626;
    font-size: 15px;
    font-weight: 500;
  }

  &__message {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }

  &__buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;

    &__yes,
    &__no {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;

      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 80px;
      height: 40px;
    }

    &__yes {
      border-radius: 4px;
      border: 1px solid #D4DEF1;
      background: #FFF;

      color: #135AC0;

      &:hover {
        background-color: #e0e0e0;
        color: #114da1;
      }
    }

    &__no {
      border: 0;
      border-radius: 4px;
      background: #135AC0;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);

      color: #FFF;

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}