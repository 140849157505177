.content-routes {
    div.angular-leaflet-map {
        img.employee-marker {
            width: 40px;
            max-width: 40px;
            height: 40px;
            max-height: 40px;
        }
        div.marker-pin {
            position: absolute;
            top: 28px;
            left: 28px;
            width: 15px;
            height: 15px;
            border: 2px solid #FFFFFF;
            border-radius: 50%;
            z-index: 9999999;
        }
        .leaflet-div-icon {
            background: none !important;
            border: none !important;
        }
        .company-logo {
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background-color: #1e8ee8;
            box-shadow: 0 0 0 8px rgba(5, 155, 229, 0.5)
        }
    }
}

.marker-border-radius {
    border-radius: 100%;
    width: 40px;
    height: 40px;
}

md-sidenav {
    md-content {
        md-list {
            .item-title {
                padding-left: 10px;
                margin-top: 20px;
                height: 25px;
                color: rgba(0, 0, 0, 0.54);
            }
            .ml-1 {
                margin-left: 1px;
            }
            .full-width {
                width: 100%;
            }
            .mt-23n {
                margin-top: -23px;
            }
            .employee-search-input {
                padding-left: 10px;
                height: 35px;
                border: 1px solid #ccc;
            }
        }
    }
}

span.gantt-label-text {
    cursor: default !important;
}

#gantt-chart .route-or-break-task {
    cursor: pointer !important;
    z-index: 0 !important;
}

.gantt-task {
    cursor: pointer;
    z-index: 10 !important;
}

.displacement {
    line-height: 0px;
}

.down-margin {
  padding-bottom: 32px;
}

.gantt-route {
    .gantt-scrollable {
        .gantt-row-content {
            .md-blue-grey-100-bg:not(.active) {
                :hover {
                    background: rgba(0, 0, 0, 0.25) !important;
                }
            }
            .md-blue-400-bg:not(.active) {
                :hover {
                    background: rgb(0, 130, 234) !important;
                }
            }
            .md-orange-400-bg:not(.active) {
                :hover {
                    background: rgb(243, 156, 18) !important;
                }
            }
            .md-green-700-bg:not(.active) {
                :hover {
                    background: rgb(0, 105, 0) !important;
                }
            }
            .md-purple-400-bg:not(.active) {
                :hover {
                    background: rgb(138, 51, 152) !important;
                }
            }
            .md-red-700-bg:not(.active) {
                :hover {
                    background: rgba(190, 40, 0, 1) !important;
                }
            }
            .active {
                background: #039BE5 !important;
            }
            /*.md-blue-grey-100-bg:hover {
                background: rgba(0, 0, 0, 0.25) !important;
            }
            .md-blue-400-bg:hover {
                background: rgb(0, 130, 234) !important;
            }
            .md-orange-400-bg:hover {
                background: rgba(250, 175, 38, 1) !important;
            }
            .md-green-700-bg:hover {
                background: rgb(33, 130, 36) !important;
            }
            .md-purple-400-bg:hover {
                background: rgb(138, 51, 152) !important;
            }
            .md-red-700-bg:hover {
                background: rgba(190, 40, 0, 1) !important;
            }*/
        }
    }
}

#routesMap {
    .legend {
        .outline:nth-child(13) {
            i {
                background: #1e8ee8;
                border-radius: 100%;
                width: 8px;
                height: 8px;
                margin-left: 4px;
                margin-top: 4px;
                margin-right: 11px;
                box-shadow: 0 0 0 4px rgba(5, 155, 229, 0.5);
            }
        }
    }
}
