.modal-invalid-geo {
  width: 454px;
  max-height: 594px;
  padding: 32px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-invalid-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
}

.modal-invalid-title strong {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto;
  margin: 0;
  padding: 0;
}

.modal-invalid-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto;
  color: #676767;
  width: 400px;
  margin: 0;
}

.modal-invalid-identifiers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
  border-top: 1px solid #D4DEF1;
  border-right: 1px solid #D4DEF1;
  border-left: 1px solid #D4DEF1;
  border-radius: 8px 8px 0 0;
  max-height: 254px;
  overflow-y: auto;
}

.modal-invalid-identifiers-copy {
  display: flex;
  justify-content: center;
  width: 100%;
  border: 1px solid #D4DEF1;
  border-radius: 0px 0px 8px 8px;
  height: 50px;
  padding: 8px 0;
  cursor: pointer;
}

.modal-invalid-identifiers-copy-text {
  display: flex;
  gap: 10px;
  align-items: center;
}

.modal-invalid-identifiers-copy-text span {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  font-family: Roboto;
  color: #757575;
}

.invalid-geo-icon-close {
  margin: 0;
}

.modal-invalid-exit {
  display: flex;
  justify-content: center;
}

.modal-invalid-exit button {
  border: none;
  text-align: center;
  width: 100%;
  background-color: #135AC0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 14px 0;
}
