$attachmentHeight: 130px;

.thread-detail {
  background: #ffffff;

  .no-thread-selected {
    md-icon {
      margin: -56px 0 12px 0;
    }

    span {
      font-size: 24px;
      font-weight: 300;
    }
  }

  .ticket {
    position: relative;

    &__scheduling-suggestions {
      display: grid !important;
      position: relative;
      padding: 20px 16px;
      gap: 24px;
      border: 1px solid #D4DEF1;
      border-radius: 4px;

      &__label {
        position: absolute;
        top: 0;
        padding: 0 4px;
        margin: 0 12px;
        transform: translateY(-50%);
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.01em;
        background-color: #ffffff;
        color: #666666;
      }

      &__situation {
        color: #262626;
        font-size: 12px;
        line-height: 19px;
      }

      &__suggestions {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 12px;

        &__suggestion {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;

          &__title {
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #262626;
          }

          &__datetime {
            font-size: 14px;
            line-height: 16px;
            color: #000000;
          }
        }
      }

      &__footer {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__text {
          font-size: 13px;
          line-height: 19px;
          color: #757575;
        }

        &__new-datetime {
          padding: 12px;
          gap: 10px;
          border: 1px solid #D4DEF1;
          border-radius: 4px;

          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          color: #135AC0;

          &:hover {
            color: white;
            background-color: #0957c3;
          }
        }
      }
    }

    &__box {
      flex-direction: column;

      &__info {
        padding-bottom: 15px;
      }
    }

    &__header {
      padding-bottom: 24px;
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: thin solid rgba(0, 0, 0, 0.12);

      &__avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 45px;
        min-height: 45px;
        min-width: 45px;
        border-radius: 100%;
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
      }

      &__name {
        font-size: 18px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        text-overflow: ellipsis;
        overflow: hidden;
        width: 339px;
        word-break: break-all;
      }

      &__subject {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
      }

      &__buttons {
        position: absolute;
        right: 10px;

        &__accept {
          max-width: 40px;
          max-height: 40px;

          &.disabled {
            cursor: default;
            background-color: rgb(9,87,195) !important;
            color: rgba(255,255,255,0.87) !important;
            opacity: .3;

            md-icon {
              color: rgba(255,255,255,0.87) !important;
            }
          }
        }

        &__cancel {
          max-width: 40px;
          max-height: 40px;
        }
      }
    }

    &__info {
      margin-bottom: 24px;
      display: flex;
      flex-wrap: wrap;

      &__message {
        margin-top: 16px;
        width: 100%;
        word-break: break-word;

        .fc-container__info__text {
          line-height: 1.6;
        }
      }
    }

    &__attachments {
      display: grid;
      grid-template-columns: repeat(auto-fit, #{$attachmentHeight});
      grid-auto-rows: calc(#{$attachmentHeight} + 25px);
      row-gap: 16px;
      column-gap: 16px;
      margin-bottom: 24px;
    }

    &__attachment {

      &__image {
        position: relative;
        display: block;
        width: 100%;
        height: calc(100% - 25px);
        background-color: #eef0f2;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 5px;

        img {
          border-radius: 5px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .file-icon {
          opacity: 100%;
          height: 50px;
          width: 50px;
          font-size: 50px;
          line-height: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
        }


        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000000;
          opacity: 0;
          transition: opacity 250ms;
          border-radius: 5px;
        }

        &:hover {
          md-icon {
            opacity: 1;
          }

          &::before {
            opacity: 0.6;
          }
        }
      }

      &__title {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        margin-top: 4px;
        margin-bottom: 0;
        height: 18px;
        overflow: hidden;
        cursor: default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      }
    }

    &__equipment {
      display: flex;

      &__info-container {
        flex: 1 1 auto;

        .fc-container__info {
          display: flex;

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }

      &__image-container {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        flex: 100px;
      }

      &__image {
        position: relative;
        display: block;
        width: 100%;
        height: 100px;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        md-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          opacity: 0;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000000;
          opacity: 0;
          transition: opacity 250ms;
        }

        &:hover {
          md-icon {
            opacity: 1;
          }

          &::before {
            opacity: 0.6;
          }
        }
      }

      &__skeleton {
        width: 100%;
        height: 100%;
      }

      &:not(:last-child) {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
      }
    }
  }
}
