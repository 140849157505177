.task-dialog-data-export {
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
}

.minimal-md-select {
    padding: 0;
    margin: 0;
    width: 100%;

    .md-select-value {
        width: 100%;
        min-height: 22px !important;
    }
}

.fx-wrap {
    flex-wrap: wrap;
}

.remove-selected-option {
    transform: translate(0, -50%);
    display: block;
    position: absolute;
    left: 16px;
    top: calc(50% - -2px);
    cursor: pointer;
}

.ml-22 {
    margin-left: 22px;
}

.mt-10 {
    margin-top: 10px;
}

.wd-100-22 {
    width: calc(100% - 22px);
}


.deadline-status-on-schedule {
  &--background-color {
    background-color: #135AC0 !important;
  }

  &--color {
    color: #135AC0 !important;
  }
}

.deadline-status-at-risk {
  &--background-color {
    background-color: #E4771F !important;
  }

  &--color {
    color: #E4771F !important;
  }
}

.deadline-status-out-of-schedule {
  &--background-color {
    background-color: #FC3868 !important;
  }

  &--color {
    color: #FC3868 !important;
  }
}

.deadline-status-completed-out-of-schedule {
  &--background-color {
    background-color: #FF6767 !important;
  }

  &--color {
    color: #FF6767 !important;
  }
}

.deadline-status-completed-on-schedule {
  &--background-color {
    background-color: #2BB98B !important;
  }

  &--color {
    color: #2BB98B !important;
  }
}

.deadline-status-not-applicable {
  &--background-color {
    background-color: #c0c9c6 !important;
  }

  &--color {
    color: #c0c9c6 !important;
  }
}

.task-description-column {
  display: block;
  overflow: hidden;
  white-space: normal;
  max-width: 300px;
  width: 300px;
  max-height: 55px;
  text-overflow: ellipsis;
}

.tutorial-video {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin-left: 20px;
  gap: 10px;
  border-radius: 24px;
  text-transform: none;


  md-icon {
      margin: 0 !important;
  }

  > span {
      font-weight: 400;
      font-size: 12px;
      color: #262626;
  }
}

.order-labels{
  display: flex;
  flex-wrap: wrap;
}

.tasks-page {
  .tasks-feedback {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #D4DEF1;
    margin-bottom: 16px;
    margin-top: 6px;
    margin-left: 1px;
    border-radius: 8px;
    background-color: transparent !important;
  }

  .tasks-cyber-field {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    border: none;
    margin-bottom: 16px;
    margin-top: 6px;
    margin-left: 1px;
    border-radius: 8px;
    background-color: #E5EEFF !important;

    &__icon {
      height: 28px !important;
      min-height: 28px !important;
    }

    &__container {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
    }

    &__div {
      margin-top: 4px;
    }
  }
}
