.plans-table {
  border-style: solid !important;
  border-width: thin !important;
  border-radius: 10px !important;
  border-color: #d3ddf2 !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

.max-h-73vh {
  max-height: 73vh;
}

.plan-dialog {

  .container {
    max-height: 630px;
  }
  .comment {
    display: flex;
    justify-content: center;
  }

  .reload-activity-plans {
    display: flex;
    justify-content: center;
    padding: 200px;
  }

  .text-reload {
      font-size: 20px;
  }

  .icon-reload-activity-plans {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 55px;
    margin-bottom: 20px;
  }

  max-width: calc(185vh - 300px) !important;
  width: calc(185vh - 300px) !important;

  .mt-25n {
    margin-top: -25px;
  }

  .mt-35n {
    margin-top: -35px;
  }

  .fs-17 {
    font-size: 17px;
  }

  .ml-13 {
    margin-left: 13px;
  }

  .ml-10n {
    margin-left: -10px;
  }

  .ml-3n {
    margin-left: -3px;
  }

  .mb-15n {
    margin-top: -15px;
  }

  .mb-50 {
    margin-bottom: 50px !important;
  }

  .questions-count {
    color: #858585 !important;
  }

  .color-grey {
    color: #bfbfbf;
  }

  .color-red {
    color: #D32F2F;
  }

  .color-green {
    color: #388E3C;
  }

  md-input-container {
    input.color-black {
      color: black !important;
    }
  }

  .tab-forms {
    .btn-remove-form {
      height: 20px;
      margin-top: 25px;
      cursor: pointer;
    }
  }

  .md-input-container-file {
    margin-bottom: 0;
  }

  .plans-label-color {
    color: #0957c3;
  }

  .inconsistency-plans-label-color {
    color: #ff3365;
  }

  .invalid-plan-list {
    list-style: none;
    list-style-type: none;
    display: grid;
    grid-template-rows: repeat(10, min-content);
    grid-auto-flow: column;
  }

  .invalid-plan-list li {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    flex: 1 0 25%;
    list-style: none;
    padding: 2px;
    font-size: 14px;
  }

  .plan-list {
    list-style: none;
    list-style-type: none;
    display: grid;
    grid-template-rows: repeat(4, min-content);
    grid-auto-flow: column;
  }

  .plan-list li {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    flex: 1 0 25%;
    list-style: none;
    padding: 2px;
    font-size: 14px;
  }

  .select-file-button {
    min-height: 30px;
    height: auto;
    padding: 20px;
    background: #f8f8f8 !important;
    border: 5px dashed #DDD;
    text-align: center;
    color: #acacac;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0;
    display: block;
    width: 100%;
    box-shadow: none !important;

    &:hover {
      border: 5px dashed rgb(196, 196, 196);
      color: #6e6e6e;
      transition: all 0.3s ease;
    }

    &.active {
      border: 5px dashed #049be5;
      color: #049be5;
      transition: all 0.3s ease;
    }
  }

  .input-file {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
  }

  .select-spreadsheet-flex {
    display: flex;
    flex-direction: column;

    .description {
      line-height: 20px;
      margin: 3px 0;
    }

    .hint {
      line-height: 16px;
      font-size: 11px;
    }
  }

  .input-file-error {
    margin-top: 6px;
    opacity: 1 !important;
    margin-top: 4px !important;
  }
}

.h-full {
  height: 100% !important;
}

.mat-tab-group {
  height: 100%;
}

.mat-tab-body-wrapper {
  flex-grow: 1;
}

.mat-tab-body {
  display: flex !important;
  flex-direction: column;
}

.mat-tab-body-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.md-tabs-content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.table-navigation {
  font-size: 12px;
  text-transform: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px;
  font-weight: 500;
  text-transform: none;

  table.simple thead tr th {
    padding: 16px 10px;

    &:last-child {
      padding-left: 30px;
    }
  }

  .plans-table {
    border-radius: 10px 10px 0 0 !important;
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid #d3ddf2;
    border-top: none;
    border-radius: 0 0 10px 10px;
    width: 100%;
    padding: 16px 10px;
  }

  &__info {
    font-weight: 600;
    padding: 0 8px;
  }

  &__button {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.87);
    margin: 0 2px;
    padding: 9px 12px;

    &:hover:not([disabled]) {
      box-shadow: inset 999em 0 0 0 rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.87);
      border: 1px solid rgba(0, 0, 0, 0.12);
      background: none;
      cursor: pointer;
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.54);
      border-color: rgba(0, 0, 0, 0.12);
    }
  }
}
