.filter-employees {
    md-list {
        md-list-item {
            .w-100-percent {
                width: 100%;
            }
        }
    }
}

.banner {
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
}


.icon-red {
    color: #E53935
}

.avatar-employees {
    max-width: 30px;
    min-width: 30px;
    height: 100%;
    max-height: 30px;
    min-height: 30px;
    border-radius: 50%;
    margin: 0 8px 0 0;
}

#table-list {

    .td-email {
        width: 25%;

        .employee-email {
            font-size: 18px !important;

            &--valid {
                color: #1cb987;
            }

            &--invalid {
                color: #ff3365;
            }
        }

        &__actions {
            md-icon {
                color: #0957c3;
            }
        }
    }
}
