.service-dialog {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .mt-25n {
    margin-top: -25px;
  }

  .mt-35n {
    margin-top: -35px;
  }

  .fs-17 {
    font-size: 17px;
  }

  .ml-13 {
    margin-left: 13px;
  }

  .ml-10n {
    margin-left: -10px;
  }

  .ml-3n {
    margin-left: -3px;
  }

  .mb-15n {
    margin-top: -15px;
  }

  .color-grey {
    color: #bfbfbf;

    svg {
      fill: #bfbfbf;
    }
  }

  .questions-count {
    color: #858585 !important;
  }

  .color-red {
    color: #d32f2f;
  }

  .color-green {
    color: #388e3c;
  }

  .color-yellow {
    color: #f9bb42;

    svg {
      fill: #f9bb42;
    }
  }

  md-input-container {
    input.color-black {
      color: black !important;
    }
  }

  .tab-general {
    margin-bottom: 180px;

    .distance-margin-top {
      margin-top: 6px;
    }

    .version-hint {
      font-size: 10px;
      color: #9e9e9e;
      position: absolute;
      margin-top: -10px;
    }

    &__configurations {
      margin-top: 15px;

      &__rating-form {
        margin-bottom: 15px;
      }

      md-switch {
        margin: 0 0 16px 0;
      }
    }
  }

  .tab-forms {

    .text-gray{
      font-size: 14px;
      line-height: 16px;
    }

    .btn-remove-form {
      height: 20px;
      margin-top: 25px;
      cursor: pointer;
    }
  }

  .tab-template {
    &__empty-state {
      margin-top: 24px;
      padding: 32px 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid #E6E6E6;

      span {
        color: #666;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &__item {
      margin-top: 24px;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px;
      border-radius: 4px;
      border: 1px solid #E6E6E6;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 60px;
      height: 60px;
      border-radius: 4px;
      border: 1px solid #E6E6E6;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      strong {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
      }

      span {
        color: #999;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &__label {
      padding: 4px 8px 4px 4px;
      display: flex;
      align-items: center;
      gap: 4px;
      border-radius: 9999px;
      font-size: 12px;
      font-weight: 500;
      height: 20px;

      &--active {
        border: 1px solid #D6F5EB;
        background: #EAFAF5;
        color: #29A37A;
      }

      &--inactive {
        border: 1px solid #FCC;
        background: #FFE5E5;
        color: #F00;
      }

      &--archived {
        background-color: rgba(96, 125, 139, 0.1);
        color: rgb(96, 125, 139);
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }

    &__unlink {
      color: #FF0000;
    }

    &__edit {
      color: #135AC0;
    }

    &__button {
      margin: 0px;
      border: solid 1px #D4DEF1;
      color: #135AC0;
      font-weight: 500;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px;
      gap: 10px;

      height: 48px;
    }
  }
}

.service-dialog-content{
  md-tab-content {
    padding: 0 !important;
  }
  .tab-service-dialog {
    padding: 26px;
  }
}


.custom-span-error {
  bottom: -20px;
  left: 0;

  span {
    font-size: 12px !important;
  }
}
