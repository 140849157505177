#webhook {
    .flex {
        display: flex;
    }

    .flex-align-center {
        align-items: center;
    }

    .status-code {
        border-radius: 4px;
    }

    .background-response {
        background-color: #f8f8fa;

        color: rgb(9,87,195);

        border: 1px solid rgba(9, 87, 195, 0.288);
        border-radius: 4px;

        padding: 10px;

        line-height: 25px;
        min-height: 46px;
        max-height: 350px;

        overflow: hidden auto;
    }

    .relative {
        position: relative;
    }

    .absolute {
        position: absolute;
        top: 5px;
        right: 10px;
    }

    .color-icon {
        color: rgb(9,87,195);
    }
}
