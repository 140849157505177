#settings-page {
  .integration-icon-container {
    height: 48px;
    line-height: 48px;
    width: 100%;
    text-align: center;

    img {
      vertical-align: middle;
    }
  }

  .card-border.integration-enabled {
    border-color: #0d5eff !important;
  }

  .beta-config {
    margin-top: 8px;
  }

  .situation-active {
    color: #388e3c;
  }

  .situation-inative {
    color: #d32f2f;
  }

  .sequential-form {
    .mt-18 {
      margin-top: 18px;
    }

    .ml-7 {
      margin-left: 7px;
    }

    .documentation-link-box-container {
      margin-bottom: 40px;

      md-input-container {
        margin-bottom: 0;

        input {
          font-weight: bold;
        }
      }
    }

    .documentation-link-box {
      margin: 0;
      display: inline-flex;
    }
  }

  .sidenav {
    .header {
      height: 100px !important;
      min-height: 0px !important;
      max-height: none !important;
      padding: 0px !important;

      .logo {
        text-align: center;
        margin-top: 26px;
        margin-left: 24px;
      }

      .config-title {
        font-size: 22px;
        color: #fff;
        font-weight: 400;
        margin-left: 12px;
      }

      .icon {
        color: #fff;
      }
    }

    .content {
      padding-top: 0px;
      padding-left: 5px;
      background: #fff;
      box-shadow: 0px 2px 16px 12px rgba(0, 0, 0, 0.12);
    }
  }

  .content-card {
    .fs-14 {
      font-size: 14px;
    }

    .fs-16 {
      font-size: 16px;
    }

    .mt-15n {
      margin-top: -15px;
    }

    .mt-20n {
      margin-top: -20px;
    }

    .ml-10 {
      margin-left: 10px !important;
    }

    .mb-6n {
      margin-bottom: -6px;
    }

    .fw-500 {
      font-weight: 500 !important;
    }

    .card-border {
      border: 1px solid #ccc;
      border-radius: 3%;
      max-height: 245px;
      width: 245px;

      &:hover {
        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.2), 0px 3px 3px 0px rgba(0, 0, 0, 0.14),
          0px 6px 3px -3px rgba(0, 0, 0, 0.12);
      }
    }

    .pl-0 {
      padding-left: 0 !important;
    }

    .hint-button {
      margin-top: 5px !important;
      margin-left: 0px;
    }

    .text-capitalize {
      text-transform: capitalize;
    }

    .end-session {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-bottom: 15px;
    }

    .save-fixed-button {
      position: fixed;
      top: 90%;
      right: 6%;
    }

    .md-tooltip {
      height: auto;
    }

    .grey-bg {
      background: #ccc;
    }
  }

  .secondary-text {
    md-icon {
      font-size: 16px;
      min-width: 20px;
      line-height: 14px;
      width: 16px;
      height: 16px;
    }
  }
}

.sound-select-option {
  width: 300px;
}

.page-layout.carded {
  .top-bg-height {
    height: 153px !important;
  }

  .center {
    .header {
      .grey-fg {
        color: #e4e4e4 !important;
      }
    }
  }
}


.md-list-item-text {
  vertical-align: middle;
}

.md-list-item-text p {
  display: flex;
  align-items: center;
}

.md-list-item-text p i {
  margin-right: 4px;
}

.title-text{
  width: 715px;
  height: 21px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px;
  margin-left: 3px;
  padding-top: 5px;
}

.new-integration-container {
  box-sizing: border-box;
  background: none;

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;

  gap: 20px;

  width: 100%;

  overflow: hidden;

  margin-bottom: -10px;
  margin-top: -5px;
  margin-left: 18px;
  width: 820px;
  max-width: 820px;

  background: #FFFFFF;

  border-radius: 8px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  .tips-container {
    margin-left: -6px;
    color: #262626;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
}

.setting-container {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
}

.multiple-accounts {

  box-sizing: border-box;
  background: none;

  /* Auto layout */

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;

  gap: 20px;

  width: 100%;

  overflow: hidden;

  margin-bottom: 15px;
  margin-top: -15px;
  margin-left: -12px;

  background: #FFFFFF;
  /* FC/Border/Default */

  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.trash-icon {
  display: flex;
  justify-content: center;

  button {
    border: none;
    width: 24px !important;
    height: 24px !important;
    background: none;
  }
}

.main-title-container {
  width: auto;
  margin-left: 26px;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  margin-top: 26px;
}

.main-info-text {
  height: 30px;
  color: #262626;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  color: #262626;
}

.settings-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  padding: 10px 11px;
  width: auto;
  margin: 15px 26px;
  border: solid  1px #D4DEF1;
  border-radius: 8px;
  background: #FFFFFF;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.omie-width {
  max-width: 362px;
  min-width: 362px;
  width: 362px;
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 40px;
}

.item-add-row {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 18px;
  height: 65px;

  .add-button {
    color: #135AC0;
    width: 810px;
    height: 80%;
    border-width: thin !important;
    border: #135AC0 dashed;
    margin: 8px 0px !important;
    border-radius: 8px;
  }
}

.secret-width {
  margin-left: 8px;
  max-width: 736px;
  min-width: 736px;
  width: 736px;
  margin-right: 10px;
  margin-bottom: 6px;
}

.action-width {
  position: absolute;
  right: 2.5%;
  top: 31.5%;
  max-width: 22px;
  min-width: 22px;
  width: 22px;
}

.item-width {
  box-sizing: border-box;
  padding: 0;

  md-input-container {
    width: 100%;
  }

}

.accounts-items-table-container {
  border-bottom: 15px;
  border-radius: 4px;

  .accounts-items-table-header {
    background-color: #eee;
    margin-bottom: 45px;

    md-input-container {
      margin: 0;
    }
  }

  .add-new-line-btn-row {
    margin-top: -35px;
  }

  .required {
    color: red;
  }
}

.accounts-items-table-body {
  border: 1px solid #D4DEF1;
  border-radius: 8px;
  padding: 10px 10px 17px;
  margin: 5px 0px;
  width: 812px;
  max-width: 812px;
  margin-left: 16px;
  position: relative;

  .md-ink-ripple {
    margin-top: -8px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.description-text {
  width: 778px;
  height: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #757575;
  margin: 3px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.toolbar {
  p {
    font-size: 16px;
    font-weight: bold;
  }
}

.md-select-menu-container {
  .remove-label {
    label.md-container-ignore {
      padding: 0;
    }
  }
}

.arrows-orders {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  left: 0;
  text-align: center;
  padding: 10px 0;
}

.employees-sortable-list {
  width: 550px;
  padding-top: 10px;
  overflow-x: hidden;

  &.sortable {
    .employee-sortable-item {
      padding: 0px 0px 0px 16px !important;
      background: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      padding: 16px;
      position: relative;
      text-transform: none;

      .handle-questions {
        display: block;
      }

      .handle-options {
        display: block;
        cursor: move;
      }
    }
  }
}

.badge {
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 20px;
  transition: opacity 0.2s ease-in-out 0.1s;
  background: #0095e5;
}

.landing-page-disabled {
  opacity: 0.5;
  position: relative;

  &::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }
}

.item-config-title {
  margin-top: 25px !important;
  padding-right: 10px !important;
}

.label-name {
  width: 170px !important;
  height: 28px !important;
}

#default-list.labels-manager {
  .content {
    padding: 0;
  }
}

small.link {
  color: #4b4bff;
  text-decoration: underline;
  cursor: pointer;
}

span.link {
  color: #4b4bff;
  text-decoration: underline;
  cursor: pointer;
}

.documentation-link-box-container {
  display: block;
  width: 100%;

  .documentation-link-box {
    display: inline-block;
    padding: 21px;
    border: thin solid #d3ddf2;
    margin-top: 36px;
    border-radius: 6px;

    &.full {
      width: 100%;
    }
  }

  .documentation-link-title {
    font-size: 20px;
    margin: 6px 0 0 0;
  }

  .documentation-link-description {
    color: rgba(0, 0, 0, 0.54);
  }

  .documentation-button-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    line-height: 18px;
  }

  .md-button {
    margin-left: 0 !important;
  }
}

.width-100 {
  width: 100% !important;
}

.padding-0 {
  padding: 0 !important;
}

.integration-url {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.fc-modal__buttons-container-flex {
  justify-content: flex-end;
}

.custom-task-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: 20px;

  &__header {
    display: flex;
    align-items: center;
    padding-left: 20px;

    h3 {
      margin: 0;
      color: #332929;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    p {
      margin: 0;
      color: #808080;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 20px;
  }

  &__error {
    display: flex;
    padding-top: 8px;
    align-items: center;
    gap: 8px;

    span {
      color: #F6474C;
      font-size: 16px;
      font-weight: 400;
    }

    md-icon {
      width: 16px;
      height: 16px;
      font-size: 16px;
      line-height: 16pxs;
      display: contents;
    }
  }
}
