.location-dialog__contacts-tab {
  padding: 26px;

  &__main-container {
    margin-bottom: 20px;

    &__subtitle{
      margin-bottom: 24px;
      margin-top: 0;
      font-size: 15px;
      font-weight: 500;
      color: #000000;
    }

    &__contacts-container {
      padding: 22px 14px;
      border: 1px solid #d4def1;
      border-radius: 4px;

      &.pb-0 {
        padding-bottom: 0;
      }

      &:first-of-type {
        margin-bottom: 24px;
      }

      &__select-main-contact {
        font-size: 14px;
        margin-bottom: 16px;

        md-switch {
          margin: 10px 0;
        }
      }

      &__empty-phrase {
        margin: 0;
        margin-bottom: 18px;
        font-size: 14px;
        color: #757575;
      }

      &__input-groups {
        grid-gap: 12px !important;
      }
    }

    .delete-icon {
      color: #FC3868;
      transition: color 250ms;
      margin-top: 4px;
      margin-bottom: 4px;

      &:hover {
        color: #b30c0c;
      }
    }
  }
}
