.fc-date-input {
  position: relative;
  border: 1px solid #D4DEF1;
  height: 48px;
  width: 197px;
  border-radius: 4px;

  &__title {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    background-color: #FFFFFF;
    padding: 0 4px;
    margin: 0 8px;
    color: #757575;
    font-size: 12px;
    transition: all 0.3s;
  }

  .center-date-value {
    transition: all 0.3s;
    font-size: 15px;
    transform: translateY(65%);
  }

  .md-datepicker {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 0;

    &-button {
      display: flex;
      position: absolute;
      min-height: 24px;
      right: 12px;
      order: 1;
      padding: 0;
      margin: 0;
      width: fit-content;
      height: fit-content;
    }

    &-calendar-icon {
      color: #135AC0;
    }

    &-input {
      color: #000000;
      padding: 0;
      max-width: 122px;
      overflow: hidden;
    }

    &-input-container {
      padding: 16px 0px;
      border: 0;

      .md-datepicker-triangle-button {
        display: none;
      }
    }
  }
}

.invalid-date {
  border-color: #d50000;

  .fc-date-input__title {
    color: #d50000;
  }

  .md-datepicker {
    &-calendar-icon {
      color: #d50000;
    }
  }
}