.tab-forms {
  md-input-container.md-input-focused:not([md-no-float]) .md-select-placeholder span:first-child {
    -webkit-transform: none;
    transform: none;
  }

  .form-list {
    &__row {
      padding: 0;

      .md-list-item-inner {
        padding: 0;
      }
    }

    .icons-header, .switches {
      display: flex;
      justify-content: space-between;
      md-icon {
        margin: 0 8px;
      }
    }
  }

  .allow-all-forms {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-gray {
    color: rgba(0, 0, 0, 0.35);
  }
}

.text-light-gray {
  color: #9e9e9e;
}
