.select-history {
  margin-top: 24px;
  padding: 0 24px;

  &__container {
    &__content {
      width: 100%;
    }
  }
}
