.ms-card {
    .template-14 {
        font-size: 14px;
        border: 1px solid#D4DEF1;
        border-radius: 6px;

        .header {
            .avatar {
                .image {
                    display: block;
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                }
            }

            .info {
                width: 100%;

                .created-at {
                    text-align: right;
                    color: #757575;
                }
            }
        }

        .media {
            .image {
                width: 100%;
            }
        }

        .text {
            color: #757575;
        }
    }
}

.show-button {
    display: flex;
    justify-content: center;
    width: 164px;
    height: 40px;
    margin: 15px;
    border-radius: 4px;
    border: 1px solid #D4DEF1;

    span {
        color: #135AC0;
        font-size: 13px;
        text-transform: none;
        font-weight: 400;
    }
}
