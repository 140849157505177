.link-products {
  padding: 24px;

  .fc-input__input--search {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border: 1px solid #D4DEF1;
  }

  &__search-bar {
    width: 100%;
    margin-bottom: 16px;

    input {
      padding:0 34px !important;
      border-radius: 4px;
    }

    label {
      left: 34px;
    }

    md-icon {
      position: absolute;
      left: 9px;
      top: 12px;
      color: #135AC0;
      font-size: 18px !important;
    }
  }

  &__header {
    margin-bottom: 24px;

    &__title {
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 25px;
    }

    &__select-message {
      font-style: italic;
      font-weight: 400;
      font-size: 13px;
      color: #757575;
      margin-left: 16px;
    }

    md-checkbox {
      margin: auto 12px;
    }
  }

  &__add-product {
    &__line {
      display: flex;
      font-size: 14px;
      font-weight: 400;
      gap: 4px 4px;

      &__measure {
        color: #757575;
      }
    }

    &__value {
      display: inline-block;
      color: #757575;
    }
  }

  &__add-product {
    md-checkbox+&__tooltip {
      display: none;
    }

    md-checkbox:hover+&__tooltip {
      display: inline;
      position: absolute;
      left: 493px;
      padding: 12px;
      margin-top: -10px;
      border-radius: 4px;
      background: #626262;
      color: #FFFFFF;
      box-shadow: 0px 10px 14px rgba(126, 50, 25, 0.149);
    }

    &__already-linked {
      font-size: 14px;
      font-weight: 400;
      color: #9E9E9E;
      margin-right: 16px;
      margin-bottom: 7px;
      display: block;
    }
  }

  &__table-navigation {
    margin-top: 20px;
    gap: 8px 8px;

    &__info {
      font-size: 13px;
      font-weight: 400;
      color: #757575;
      margin-left: 10px;
    }

    &__button-previous,&__button-next {
      font-weight: 500;
      font-size: 14px;
      padding: 9px;
      border-radius: 4px;
    }

    &__button-previous {
      border: 1px solid #F2F2F2;
      color: #262626;
    }
    &__button-previous:disabled,&__button-next:disabled {
      border: 1px solid #F2F2F2;
      color: #757575;
    }

    &__button-next {
      border: 1px solid #D4DEF1;
      color: #135AC0;
    }
  }

  &__flex-column {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }

  &__flex-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
    position: relative;
    padding: 16px;
    border: 1px solid #D4DEF1;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &__info {
      display: flex;
      max-width: 70%;
      gap: 16px;

      &__image {
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.12);
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: 64px;
        height: 64px;
        min-width: 64px;
        min-height: 64px;

        &--default {
          width: 24px;
          height: 24px;
        }
      }

      &__add-product {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        width: 650px;

        &__line {
          display: flex;
          gap: 4px;

          &__measure {
            color: #757575;
            white-space: nowrap;
          }

          &__name {
            display: block;
            word-wrap: break-word;
          }
        }

        &__value {
          color: #757575;
        }
      }
    }

    &--selected {
      color: #9E9E9E;
    }

    strong {
      font-weight: 500;
    }
  }

  &__loading {
    margin: auto 0 !important;
    gap: 16px;
  }
}

.omie-products-tiny-icon {
  margin-top: -3.2px !important;
  min-width: 15px !important;
  width: 15px !important;
  min-height: 15px !important;
  height: 15px !important;
  margin-right: 3px;
}
