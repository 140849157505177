.dialog-error-alert {
  height: 48px;
  width: 100%;
  padding: 0 15px;
  margin: 18px 0;

  position: relative;
  display: flex;
  align-items: center;

  font-size: 14px;
  border-radius: 4px;
  background-color: #fc3868;

  &__alert-icon {
    margin: 0;
    margin-right: 11px;

    svg {
      height: 23px !important;
      width: 18px !important;
    }
  }

  &__text {
    color: #fff;
    margin: 0;
  }

  &__close-icon {
    position: absolute;
    right: 15px;
    color: #fff !important;
    transition: color 250ms;
    cursor: pointer;

    &:hover {
      color: #acacac;
    }
  }
}
