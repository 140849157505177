.location-dialog__attachments-tab {
  padding: 25px 24px;

  &__upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    border: 1px dashed #D7D7D7;
    padding: 24px 28px;
    border-radius: 8px;
    background-color: #ffffff;

    &__drag-message {
      font-weight: 500;
      font-size: 15px;
      color: #135AC0;
    }

    &__drag-limit {
      font-weight: 400;
      font-size: 15px;
      color: #262626;
    }

    &__upload-button {
      border: #D6D6D6 1px solid;
      background-color: #ffffff;
      padding: 13px 18px;
      border-radius: 5px;
      color: #4E4E4E;
      font-weight: 400;
      font-size: 15px;
      display: flex;
      gap: 10px;
      transition: ease-in-out 0.2s;

      &:hover {
        background-color: #F2F2F2;
      }
    }

    &__send-button {
      font-weight: 500;
      font-size: 15px;
      color: #ffffff;
      border: 0;
      border-radius: 5px;
      background-color: #135AC0;
      padding: 10px 20px;
    }
  }

  &__limit-reached {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 26px;
    border: 1px dashed #D7D7D7;
    border-radius: 8px;
    padding: 55px;

    font-weight: 400;
    font-size: 15px;
    color: #757575;
  }

  &__attachments-container {
    display: flex;
    gap: 24px;
    flex-direction: column;

    &__single {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;

      &__arrows {
        button {
          border: none;
          background-color: transparent;

          &:hover {
            background-color: #F2F2F2;
            border-radius: 50%;
          }
        }

        display: flex;
        flex-direction: column;
        width: 14px;
        gap: 17px;
      }

      &__preview {
        width: 153px;
        height: 113px;
        border-radius: 3px;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &__information {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        gap: 4px;

        &__title {
          font-weight: 500;
          font-size: 15px;
          color: #000000;
        }

        &__date {
          font-weight: 400;
          font-size: 13px;
          color: #757575;
        }

        &__rename-button {
          background-color: transparent;
          border: none;
          font-weight: 500;
          font-size: 15px;
          color: #135AC0;
          text-align: left;
          width: fit-content;
        }

        &__archived-message {
          margin-top: 3px;
          display: flex;
          gap: 11px;
          align-items: center;
          font-weight: 500;
          font-size: 15px;
          color: #757575;
        }
      }

      &__action-buttons {
        position: absolute;
        right: 0;
        margin-right: 25px;
        display: flex;
        align-items: center;

        .md-button {
          border: none;
          background-color: transparent;
          min-width: 14px;
          border-radius: 50%;
          margin: 0;
          padding: 4px 12px;

          &:hover {
            background-color: #F2F2F2;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &__title {
    color: #262626;
    font-weight: 500;
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 25px;
    display: block;
  }

  &__no-attachments {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border: 1px solid #D4DEF1;
    border-radius: 8px;
    padding: 32px;
  }

  &__loading-attachments {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .attachments-limit {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    margin-left: 5px;
  }
}

.has-images {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  height: 100%;

  &__drag-message {
    font-weight: 400;
    font-size: 15px;
    color: #757575;
  }

  &__files-preview {
    margin: 0px;
    padding: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;

    &__loading {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      padding: 24px 82px;
    }

    &__thumbnail {
      width: 86px;
      height: 65px;
      border-radius: 3px;
      border: thin solid #D7D7D7;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__remove {
      cursor: pointer;
      position: absolute;
      margin-left: 74px;
      margin-top: -6px;
    }
  }
}

.wrapper-images-upload {
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: hidden;

  div {
    align-items: flex-end;
    justify-content: flex-start;
    height: 70px;
    display: flex;
    position: relative;
    width: 120px;
    margin-bottom: 10px;
    margin-left: 10px;

    .file-image-thumbnail{
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      cursor: pointer;
    }

    md-icon {
      position: absolute;
      right: 8px;
      top: 2px;
      z-index: 99;

      &:hover {
        svg path {
          fill: #bd0e0e;
        }
      }
    }

    img {
      border: thin solid #d5d5d5;
      border-radius: 5px;
      height: 60px;
      width: 100px;
      background-color: gray;
      object-fit: cover;
    }
  }
}
