.vehicle-form-tab {
  &__pickup-title,&__return-title {
    font-weight: 500;
    padding-left: 25px;
    font-size: 16px;
    display: block;
    margin-top: 24px;
  }

  &__pickup-container,&__return-container {
    border: solid 1px #D4DEF1;
    padding: 20px;
    padding-top: 5px;
    margin: 25px 25px 0px 25px;
    border-radius: 5px;
    height: 100%;

    .search-form-bar {
      padding: 41px;

      .fc-input__input {
        border-color: #D4DEF1 !important;
      }
    }

    &__clear-button {
      z-index: 10;
      min-width: 0 !important;
      height: 48px;
      width: 48px;

      cursor: pointer;
      border-left: 1px solid #D4DEF1;
      background: url('../assets/icons/icon-clear-autocomplete.svg') no-repeat;
      background-position: center;

      position: absolute;
      margin-top: -70px;
      margin-left: 720px;
    }
  }

  md-switch {
    padding: 0;
    font-size: 14px;
    width: fit-content;
    margin: 0px;
  }
}
