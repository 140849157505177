.vehicle-dialog {
  md-dialog-content {
    padding: 0;
    height: 100%;
  }

  .creating-vehicle {
    height: 0px !important;
  }

  md-tabs {
    padding: 0 !important;
    margin: 0 !important;

    &.md-dynamic-height md-tab-content.md-active {
      padding: 0 !important;
    }
  }

  &__footer {
    position: fixed !important;
  }
}
