#order-dialog-quotation-tab {
  .quotations-tab {
    &__title {
      margin: 30px 0px;
      display: flex;
      align-items: center;
      color: #000000;
      font-weight: 500;
      font-size: 16px;

      &__max-allowed {
        margin-left: 16px;
        font-size: 13px;
        font-weight: 400;
        color: #757575;
        font-style: italic;
      }
    }

    &__header {
      margin-top: 24px;
      padding-bottom: 20px;
      display: flex;

      fc-search {
        width: 100%;
        margin-right: 12px;
      }

      &__create-quotation {
        margin: 0px;
        height: 47px;
        width: 147px;
        min-width: 147px;
        box-shadow: none;
        background-color: #135AC0;
        color: #ffffff;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;

        &[disabled] {
          background-color: rgba(0,0,0,0.12);
          color: rgba(0,0,0,0.38);
        }
      }
    }

    &__loading {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__no-items {
      margin: 30px 0px;
      padding: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: thin solid #E0E0E0;
      border-radius: 4px;
    }

    .quotations-tab-quotation {
      margin-bottom: 16px;

      &__header {
        margin-bottom: 16px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #0A0A0A;
        }

        &__icon {
          margin-right: 10px;
          width: 16px;
          height: 16px;
        }

        md-menu {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      &__info {
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: thin solid #E0E0E0;
        border-radius: 4px;

        &__section {
          display: flex;
          flex-direction: column;

          & > *:not(:last-child) {
            margin-bottom: 6px;
          }

          &--main {
            flex: 1;
            max-width: 180px;
          }

          &--total {
            flex: 1;
            max-width: 180px;
          }

          table {
            border-spacing: 0;
            margin-top: -4px;
          }
        }

        &__title {
          font-weight: 500;
          font-size: 14px;
          color: #1C1C1C;
        }

        &__text {
          font-size: 14px;
          color: #1C1C1C;

          &--blue {
            color: #135ac0;
          }
        }
      }

      &--archived {
        .quotations-tab-quotation__header__title {
          color: #757575;
  
          span:nth-of-type(1) {
            text-decoration: line-through;
          }
        }
      }
    }
  }
}