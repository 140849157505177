.import-spreadsheet-dialog {

    .mt-25n {
        margin-top: -25px;
    }

    .mt-35n {
        margin-top: -35px;
    }

    .fs-17 {
        font-size: 17px;
    }

    .ml-13 {
        margin-left: 13px;
    }

    .ml-10n {
        margin-left: -10px;
    }

    .ml-3n {
        margin-left: -3px;
    }

    .mb-15n {
        margin-top: -15px;
    }

    .mb-50 {
        margin-bottom: 50px !important;
    }

    .questions-count {
        color: #858585 !important;
    }

    .color-grey {
        color: #bfbfbf;
    }

    .color-red {
        color: #D32F2F;
    }

    .color-green {
        color: #388E3C;
    }

    .center-download-button {
        text-align: center;
    }

    .width-download-button {
        width: 321px;
    }

    md-input-container {
        input.color-black {
            color: black !important;
        }
    }

    .tab-forms {
        .btn-remove-form {
            height: 20px;
            margin-top: 25px;
            cursor: pointer;
        }
    }

    .md-input-container-file {
        margin-bottom: 0;
    }

    .select-file-button {
        min-height: 30px;
        height: auto;
        padding: 20px;
        background: #f8f8f8 !important;
        border: 5px dashed #DDD;
        text-align: center;
        color: #acacac;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0;
        display: block;
        width: 100%;
        box-shadow: none !important;

        &:hover {
            border: 5px dashed rgb(196, 196, 196);
            color: #6e6e6e;
            transition: all 0.3s ease;
        }

        &.active {
            border: 5px dashed #049be5;
            color: #049be5;
            transition: all 0.3s ease;
        }
    }

    .input-file {
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
    }
    
    .select-spreadsheet-flex {
        display: flex;
        flex-direction: column;

        .description {
            line-height: 20px;
            margin: 3px 0;
        }

        .hint {
            line-height: 16px;
            font-size: 11px;
        }
    }

    .input-file-error {
        margin-top: 6px;
        opacity: 1 !important;
        margin-top: 4px !important;
    }
}