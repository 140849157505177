.location-dialog__general-tab {
  strong {
    margin: 0 2px;
  }

  &__main-container {
    display: grid;
    grid-gap: 25px;
    padding: 14px 22px;
    margin-bottom: 20px;

    h2 {
      margin-top: 8px;
      font-weight: 500;
    }

    &__input-groups {
      grid-gap: 12px;

      &__hint {
        display: flex;
        flex-direction: column;

        md-icon {
          margin: 0 !important;
        }

        span {
          display: inline-block;
          margin-left: -4px;
          margin-top: 2px;
        }
      }
    }

    &__toggle-location-search {
      display: flex;
      justify-content: space-between;

      md-radio-group {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 14px;

        md-radio-button {
          margin: 0;
          font-size: 14px;
          color: #2b2b2b;
        }
      }

      .enabled-icon {
        color: #135AC0;
      }
    }
  }
  .location-name{
    position: static !important;
  }
}
