.form-dialog {
  .ml-24n {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .mb-25n {
    margin-bottom: -25px !important;
  }

  .fs-16 {
    font-size: 16px;
  }

  .ml-7n {
    margin-left: -7px !important;
  }

  .mb-5n {
    margin-bottom: -5px !important;
  }

  .mt-25n {
    margin-top: -25px !important;
  }

  .mt-27n {
    margin-top: -27px !important;
  }

  .ml-42n {
    margin-left: -42px;
  }

  .top-left-margin-fix {
    label.date-label {
      color: rgba(0, 0, 0, 0.54);
      font-size: 12px;
    }
  }

  .container-form-title {
    max-width: 300px;
    min-width: 300px;
  }
  .container-service-type {
    max-width: 200px;
    min-width: 200px;
  }

  .service-error {

    label {
      color: red !important;
    }

    md-icon svg {
      height: 20px !important;
    }

    .select-service {
      color: red !important;
      border-bottom: 1px solid red;

      .md-select-icon {
        color: red;
      }
    }
  }
}

md-autocomplete-parent-scope {
  .alert-km-distance {
    float: right;
    font-size: 11.5px;
    color: #000;
  }
}

.form-questions-dialog {
  .add-option-fake-input {
    .md-ink-ripple {
      margin-top: -3px !important;
    }
  }

  .add-option-row {
    border-bottom: none !important;
  }
}

#questions {
  md-input-container {
    margin: 0 0 !important;

    .md-inline-form md-checkbox {
      margin: 3px 0 3px;
    }
  }
}

.form-dialog {
  .dialog-form-content {
    .margin-zero {
      margin: 0 0 !important;
    }
  }
}

.equipment-type-dialog-content{
  md-tab-content {
    padding: 0 !important;
  }

  .tab-equipment-types {
    padding: 26px;
  }
}

.form-question-list-pane {

  .form-questions {
    overflow-x: hidden;

    &.sortable {

      .form-question {
        padding: 16px 16px 16px 48px !important;

        .form-question-option {
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          background-color: #FFF;

          .option-value {
            width: 75%;
            margin-right: 5%;
            border: none;
          }

        }

        .handle-questions {
          display: block;
        }

        .handle-options {
          display: block;
        }

        .info {
          margin: 0 16px 0 8px;

          .title {
            font-size: 15px;
            font-weight: 500;
          }

          .notes {
            margin-top: 4px;
          }

          .full-width {
            width: 100%;
          }

          input.fz-14 {
            font-size: 14px;
          }

          .title {
            .add-option {
              cursor: pointer;
              color: #0288D1;
              font-size: 13px;
              text-decoration: underline;
            }

            .answer-template-22 {
              width: 78%;
              position: absolute;
              top: 22px;
            }

            .answer-template-20 {
              width: 78%;
              position: absolute;
              top: 20px;
            }
          }

          .color-blue {
            color: #0188d2;
          }
        }
      }
    }

    .bb-none {
      border-bottom: none;

      .add-question {
        cursor: pointer;
        color: #a3a3b1 !important;
        font-size: 13px;
        width: 100%;
        text-align: center;
        padding: 30px;
        border: 3px dashed #dddddd;

        &:hover {
          color: #0288D1 !important;
          border-color: #0288D1;
        }
      }
    }

    .link-disabled {
      pointer-events: none;
    }
  }

  .cursor-block {
    cursor: not-allowed !important;
  }

}


.form-question {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 16px;
  position: relative;
  text-transform: none;

  .arrows-orders {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    text-align: center;
    top: 15px;
    width: 48px;
  }

  .s-20 {
    font-size: 20px;
  }

  .radio-button-fix {
    display: inline-flex;
  }

  .handle-options {
    cursor: move;
    display: none;
    height: 50px;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 48px;

    md-icon {
      display: block;
      position: relative;
      margin-top: 26px;
      transform: translateY(-50%);
    }
  }

  .handle-questions-without-options {
    md-icon {
      display: block;
      position: relative;
      margin-top: 35px !important;
      transform: translateY(-50%);
    }

  }

  .handle-questions {
    display: none;
    position: absolute;
    height: 50px;
    width: 48px;
    top: 0;
    left: 0;
    cursor: move;
    text-align: center;

    md-icon {
      display: block;
      position: relative;
      margin-top: 26px;
      transform: translateY(-50%);
    }
  }
}

md-dialog.form-dialog md-toolbar .title {
  margin-top: 0;
}

md-dialog.form-dialog md-toolbar .md-icon-button {
  margin-top: 0 !important;
}

.tab-equipment-types-forms {
  .color-grey {
    color: #5a5a5a;
  }

  .questions-count {
    color: #858585 !important;
  }

  .color-red {
    color: #D32F2F;
  }

  .color-green {
    color: #388E3C;
  }

  md-input-container {
    input.color-black {
        color: black !important;
    }
  }

  .tab-forms {
    .btn-remove-form {
        height: 20px;
        margin-top: 25px;
        cursor: pointer;
    }
  }

  .icons-header,
  .switches {
    display: flex;
    justify-content: space-around;
    md-icon {
      margin: 0 8px;
    }
  }

  .form-error {
    border: thin solid red;
    position: relative;
    padding-top: 5px;
    padding-bottom: 20px;
    margin-bottom: 18px;
  }

  .selected-form-error {
    position: absolute;
    left: 0;
    bottom: 5px;

    span {
      font-size: 11px;
      color: red;
      margin-left: 16px;
    }
  }
}
