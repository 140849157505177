@import './components/limit-badge.scss';
@import './components/sidebar-dialog.scss';
@import './components/fc-container.scss';
@import './components/fc-skeleton.scss';

$layout-breakpoint-sm: 959px;
$status-colors: (
  pending: #3D99E9,
  canceled: #C25AB5,
  done: #24B787,
  in-progress: #E4771F,
  reported: #F84547,
  scheduled: #105BFB,
  on-route: #617D8A,
  active: #29A37A,
  extended: #FFAA00,
  expired: #FF0000,
  future: #00AAFF
);

$cards-colors: (lightish-green: #69db7c,
  light-grey-blue: #adb5bd,
  dodger-blue: #4dabf7);

@each $status,
$color in $status-colors {
  .#{$status} {
    &-bg {
      background-color: $color !important;
      color: #ffffff !important;

      .icon {
        color: #ffffff;
      }
    }

    &-fg {
      color: $color !important;
    }
  }
}

.autocomplete-custom-template {
  li {
    border-bottom: 1px solid #ccc;
    height: auto;
    padding-bottom: 8px;
    padding-top: 8px;
    white-space: normal;

    &:last-child {
      border-bottom-width: 0;
    }
  }

  .item-title,
  .item-metadata {
    display: block;
    line-height: 2;
  }

  .item-title {
    md-icon {
      height: 18px;
      width: 18px;
    }
  }

  .item-metadata {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;

    md-icon {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}


.sidenav-notifications {
  width: 30%;
}

table.dataTable .table-header {
  color: #767676;
}

.leaflet-control-attribution {
  display: none;
}

.md-default-theme {
  &.md-hue-1 {
    .md-datepicker-input-container {
      border-bottom-color: rgba(#ffffff, 0.14);
    }
  }

  md-menu-item>a.md-button {
    color: rgba(#000000, 0.54);
  }

  @each $status,
  $color in $status-colors {
    .#{$status} {
      &-bg {
        background-color: $color !important;
        color: #ffffff !important;

        .icon {
          color: #ffffff;
        }
      }

      &-fg {
        color: $color !important;
      }
    }
  }

  @each $name,
  $color in $cards-colors {
    .#{$name} {
      &-bg {
        background-color: $color !important;
        color: #ffffff !important;

        .icon {
          color: #ffffff;
        }
      }

      &-fg {
        color: $color !important;
      }
    }
  }

}

md-toast.md-error-toast-theme {
  .md-toast-content {
    background-color: rgba(255, 0, 0, .9);
    color: white;
  }
}

.fc-progress {

  .md-container.md-mode-indeterminate {
    .md-bar {
      background-color: #0055FF;
    }
  }
}

.page-layout.carded.fullwidth>.center .content-card {
  box-shadow: none;
  border: thin solid #d3ddf2;
  border-bottom: none;
  border-radius: 10px 10px 10px 10px;
}

.fieldcontrol {
  &__content {
    background: #f5f6fa !important;

    .button-white-bg {
      border: thin solid #d3ddf2;
      box-shadow: none;

      &:disabled,
      &[disabled] {
        box-shadow: none;
      }

      .icon-reload {
        font-size: 22px;
        height: 22px;
        line-height: 23px;
      }
    }
  }

  &__toolbar {
    box-shadow: none;
    border-bottom: thin solid #d3ddf2;
  }

  &__search {
    position: relative;
    padding-left: 24px;
    max-width: 550px;

    .search-icon {
      margin: 0 -24px 0 0;
    }

    .search-input {
      margin: 0 0 0 32px;
      background: none;
      font-size: 16px;
      line-height: 40px;
      height: 40px;
      color: rgba(#000000, 0.87);
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid rgba(#000000, 0.87);
      transition: border-color 300ms ease;

      &:focus {
        border-color: #0957c3;
      }

      &::-webkit-input-placeholder {
        color: rgba(#000000, 0.87);
      }
    }
  }
}

.fc-bgcolor-pink {
  background-color: #ff3365;
}

.mb-40n {
  margin-bottom: -40px;
}

.margin-0-auto {
  margin: 0 auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;
}

.section-subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #252525;
  margin: 0;
}

#crowd-shortcut {
  display: none !important;
}

.form {
  &--in-progress {
    color: #E2772E !important;
  }

  &--done {
    color: #2FB688 !important;
  }
}

md-toast.md-success-toast-theme,md-toast.md-error-toast-v2-theme {
  .md-toast-content {
    display: flex;
    align-items: center;
    color: #262626;
    font-weight: 400;
    font-size: 14px;
    padding-left: 34px;
    background: url('../assets/icons/icon-success-circle-green.svg') no-repeat 10px 50% #ffffff;
    border: 1px solid #D4DEF1;
    border-radius: 4px;
  }
}

md-toast.md-error-toast-v2-theme {
  .md-toast-content {
    background: url('../assets/icons/danger-icon.svg') no-repeat 10px 50% #ffffff !important;
    background-size: 17px 17px !important;
  }
}
