md-datepicker.ng-invalid.ng-touched .md-datepicker-calendar-icon {
  color: rgb(213, 0, 0);
}

.blue-icon {
  color: rgb(33, 150, 243) !important;
}

.align-icon-form-s40 {
  margin-top: 12px !important;
}

.align-icon-form-s30 {
  margin-top: 20px !important;
}

a.no-text-decoration {
  text-decoration: none !important;
}

md-autocomplete md-autocomplete-wrap md-progress-linear[md-mode="indeterminate"] {
  position: absolute;
}

.section-header {
  font-size: 16px;
  padding-bottom: 10px;

  i {
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.6);
  }

  .section-title {
    font-weight: 500;
  }
}

md-icon[md-font-icon].s15, i.s15 {
  font-size: 15px !important;
  width: 15px !important;
  height: 15px !important;
  line-height: 15px !important;
}

md-dialog.form-dialog md-toolbar .title {
  margin-top:20px !important;

  &__equipment {
    margin: 0;
  }
}

md-dialog.form-dialog md-toolbar .md-icon-button {
  margin-top:10px !important;
}