.integration-tasks-export-dialog {
  width: 940px !important;
  max-width: 940px !important;
  max-height: 100% !important;
  height: 100% !important;
  right: 0;
  position: absolute;
  border-radius: 0 !important;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  & form md-dialog-actions {
    margin-top: 20px !important;
  }

  .no-padding {
    padding: 0;
  }

  .conta-azul-container {
    min-height: 650px;
  }

  .sidebar-dialog__hint {
    height: 72px;
    width: 100%;
    margin-top: -35px;
    margin-left: -25px;
  }

  .alert-icon {
    height: 20px;
    width: 20px;
  }

  .title__conta-azul-tasks-export {
    font-size: 17px;
    margin-top: 0px !important;
  }

  .customer-autocomplete-input-text {
    label.fc-input__input--with-prefix__label {
      left: 2.3% !important;
    }
  }

  .general-information {
    box-sizing: border-box;
    background: none;
    z-index: -2;

    /* Auto layout */

    padding: 10px 15px 17px;

    width: 100%;

    max-height: 400px;

    overflow: hidden;


    margin-bottom: 15px;

    background: #FFFFFF;
    /* FC/Border/Default */

    border: 1px solid #D4DEF1;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .products-services {

      box-sizing: border-box;
      background: none;
      z-index: -2;

      /* Auto layout */

      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px;

      gap: 20px;

      width: 100%;

      overflow: hidden;

      margin-bottom: 15px;

      background: #FFFFFF;
      /* FC/Border/Default */

      border: 1px solid #D4DEF1;
      border-radius: 8px;

      /* Inside auto layout */

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
  }

  .date-selector {
    height: 49px;
    margin-top: 2px;
    margin-right: 10px;
  }

  input.fc-input__input:not(.invalid), textarea.fc-textarea__textarea:not(.invalid) {
    border: 1px solid rgb(212, 222, 241) !important;
  }

  .fc-datepicker {
    border: 1px solid rgb(212, 222, 241) !important;

    &::after {
      border-left: none;
    }

    .md-datepicker-input-container button .md-datepicker-expand-triangle {
      filter: grayscale(1);
    }

    input:hover {
      border: none !important;
    }

    .md-datepicker-input {
      pointer-events: none;
    }
  }

  .fc-autocomplete__autocomplete {
    &__search-icon {
      opacity: 0;
    }

    &__clear-button {
      display: none;
    }
  }

  .fc-select__select {
    &:not(.disabled):hover {
      &.with-divider::after {
        border-left: none;
      }
    }

    &.with-divider::after {
      filter: grayscale(1);
      border: none;
      background-color: transparent;
    }
  }

  .customer-complete {
    background: #FFFFFF;
    /* Painel/Border */

    border: 1px solid #D4DEF1;
    border-radius: 4px;
  }

  .general-info-text {
    height: 11px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    color: #262626;
  }

  .general-title-row {
    display: flex;
    justify-content: space-between;
    width: auto;
    margin-bottom: 20px;

    cursor: pointer;

    .icon-arrow {
      margin-top: 8px;
    }
  }

  .discount-title-row {
      display: flex;
      justify-content: space-between;
      width: auto;
  }

  .form-status-select {
    margin: -1px 0 0 0;
  }

  .form-wrapper .form-title {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .customer-infos {
    font-size: 12px;
  }

  .customer-data {
    margin-bottom: 10px;
  }

  .clear-selected-customer {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 4px !important;
  }

  .item-name {
    margin-right: 10px;
  }

  .customer-autocomplete-container {
    margin-right: 10px;
    position: relative;
    margin-top: 2px;

    .customer-autocomplete-field {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .customer-infos {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2px;
      margin-bottom: 2px;
      margin-left: 2px;
    }

    .row {
      display: flex;
      width: 100%;
    }

    .info {
      display: inline-flex;
      margin-right: 12px;
      align-items: center;
      i {
        margin-right: 4px;
      }
    }

    .md-autocomplete-input-item input:not(.invalid) {
      border: 1px solid rgb(212, 222, 241) !important;
    }
  }

  .sale-items-table-container {
    border-bottom: 15px;
    border-radius: 4px;

    .sale-items-table-header {
      background-color: #eee;
      margin-bottom: 45px;

      md-input-container {
        margin: 0;
      }
    }

    .add-new-line-btn-row {
      margin-top: -35px;
    }

    .required {
      color: red;
    }
  }

  .status-container {
    margin-top: 2px;
  }

  .discount-full {
    margin-bottom: 20px !important;
  }

  .disc-field {
    width: 100%;
  }

  .discounter-field {
    margin-left: 50px;
  }

  .sale-items-table-body {
    border: 1px solid #D4DEF1;
    border-radius: 8px;
    padding: 10px 10px 17px;
    margin: 5px 0px;
    width: 100%;

    .md-ink-ripple {
      margin-top: -8px !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .subtotal-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-top: 12px;

    .subtotal {
      line-height: .5;
      text-align: right;

      p {
        color: #888;
      }
    }
  }

  .type-selector {
    width: 100px !important;
  }

  .discount-container {
    margin-bottom: 25px;
    width: 500px;
  }

  .discount-container-values {
    margin-bottom: 25px;
    width: 500px;
  }

  .red-color {
    color: red
  }

  .blue-color {
    color: #135AC0;
  }

  .top-label-text {
    font-size: 12px;
    color: #757575;
    margin-top: -6px;
    margin-left: -8px;
  }

  .value-label-text {
    margin: 0;
    margin-top: -6px;
    font-size: 16px;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .triple-values {
    max-width: 150px !important;
  }

  .discount-type {
    margin-right: 10px;
    margin-top: 4px;
  }

  .label-values {
    width: 80px;
  }

  .total-row h2 {
    text-align: right;
    margin: 0;
    width: 100%;
  }

  .notes-row {
    border-color: #D4DEF1;
  }

  .ca-item-add-row {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 65px;

    .add-button {
      color: #135AC0;
      width: 100%;
      height: 80%;
      border-width: thin !important;
      border: #135AC0 dashed;
      margin: 8px 0px !important;
      border-radius: 8px;
    }
  }

  fc-text-area {
    height: auto;
    min-height: auto;
  }

  .item-width {
    box-sizing: border-box;
    padding: 0;

    md-input-container {
      width: 100%;
    }

  }

  .product-width {
    max-width: 41%;
    min-width: 41%;
    width: 41%;
  }

  .description-width {
    padding: 0 8px;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    margin-top: 12px;
  }

  .qtd-width {
    max-width: 13%;
    min-width: 13%;
    width: 13%;
    margin-right: 10px;
    margin-top: 2px;
  }

  .value-width {
    max-width: 19%;
    min-width: 19%;
    width: 19%;
    margin-right: 10px;
    margin-top: 2px;
  }

  .subtotal-width {
    max-width: 19%;
    min-width: 19%;
    width: 19%;
    margin-right: 3px;
    margin-top: 2px;
  }

  .ca-action-width {
    max-width: 3%;
    min-width: 3%;
    width: 3%;
    position: relative;
  }

  .trash-icon {
    display: flex;
    justify-content: center;
    position: absolute;
    right: -10%;
    top: -10%;

      button {
        border: none;
        width: 15px !important;
        background: none;
      }
  }
}
