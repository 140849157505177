.customer-dialog {
  md-tab-content {
    padding: 0 !important;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .leaflet-div-icon {
    background: none !important;
    border: none !important;
  }
}

.icon-customer-export svg {
  width: 19px;
  margin: 0 auto;
}

.tab-with-icon__warning {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -2px;
  top: 11px;

  svg {
    width: 18px !important;
    height: 18px !important;
  }
}

.md-icon-button:disabled .icon-customer-export {
  svg path {
    fill: rgba(0, 0, 0, 0.4);
  }
}
