body {
  &.ms-info-bar-active {
    #main {
      top: 64px;
    }
  }
}

ms-info-bar {
  display: block;
  position: relative;
  width: 100%;
  height: 64px;
  z-index: 99998;
  box-shadow: $whiteframe-shadow-2dp;

  #ms-info-bar {
    height: 64px;

    #ms-info-bar-message {
      font-size: 16px;

      a {
        overflow: hidden;
        height: 48px;
        color: #ffffff;
        text-decoration: underline;
      }
    }
  }
}
