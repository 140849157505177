.omie-task-export-dialog {
  position: relative;
  width: 860px !important;
  max-width: 100% !important;

  .fc-modal__button--left-button {
    background-color: blue !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input:hover {
    border: 1px solid #E0E0E0 !important;
  }

  .fc-select__select {
    &:not(.disabled):hover {
      &.with-divider::after {
        border-left: none;
      }
    }

    &.with-divider::after {
      filter: grayscale(1);
      border: none;
      background-color: transparent;
    }
  }

  .fc-datepicker {
    &::after {
      border-left: none;
    }

    .md-datepicker-input-container button .md-datepicker-expand-triangle {
      filter: grayscale(1);
    }

    input:hover {
      border: none !important;
    }

    .md-datepicker-input {
      pointer-events: none;
    }
  }

  .fc-input__input.disabled, .fc-input__input[disabled] {
    color: #000000 !important;
    cursor: text;
  }

  .fc-autocomplete__autocomplete {
    &__search-icon {
      opacity: 0;
    }

    &__clear-button {
      display: none;
    }
  }

  .dialog-title {
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  .omie-export-content {
    &__title {
      font-weight: 500;
      font-size: 16px;
    }

    &__mutiple-customers-warn {
      padding: 0 15px !important;
      font-size: 13px;
      height: 42px;
    }

    &__first-line-container {
      display: flex;
      gap: 12px;
      margin-top: 30px;

      &__customer {
        width: 351px !important;
      }

      &__category {
        width: 242px !important;
      }

      &__sales-forecast {
        width: 193px !important;
        height: 48px;
      }
    }

    &__second-line-container {
      display: flex;
      gap: 12px;
      margin-top: 32px;
      margin-bottom: 24px;

      &__city {
        width: 351px;
      }

      &__bank-account {
        width: 193px;
      }

      &__installment {
        width: 193px;
      }

      &__seller {
        width: 193px;
      }
    }

    &__services-container,&__products-container {
      display: flex;
      gap: 12px;
      justify-content: center;
      align-items: baseline;
      margin-top: 24px;

      &__service,&__product {
        width: 461px;
      }

      &__quantity {
        width: 100px;
      }

      &__measure {
        width: 139px;
      }

      &__unit-value {
        width: 193px;
      }

      &__trash {
        height: fit-content;

        button {
          border: none;
          width: 13px !important;
        }
      }
    }
  }

  details>summary {
    list-style: none;
  }

  details[open] summary:after {
    content: url('../assets/icons/icon-arrow-up.svg');
    position: absolute;
    right: 0;
    margin-right: 55px;
    cursor: pointer;
  }

  summary {
    font-weight: 500;
    font-size: 16px;

    &::-webkit-details-marker {
      display: none
    }

    &::after {
      content: url('../assets/icons/icon-arrow-down.svg');
      position: absolute;
      right: 0;
      margin-right: 55px;
      cursor: pointer;
    }
  }

  .collapsible {
    padding: 24px 25px;
    border: 1px solid #D4DEF1;
    border-radius: 8px;
    margin-bottom: 22px;
  }

  .account-items-table-body {
    border: 1px solid #D4DEF1;
    border-radius: 8px;
    padding: 2px 10px 17px;
    margin: 3px 0px 15px 0px;
    width: 100%;

    .md-ink-ripple {
      margin-top: -8px !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .omie-account-main {
    margin-top: 20px !important;
    pointer-events: none;
  }

  .omie-account-description {
    color: #757575;
    font-family: Roboto;
    font-size: 15px;
    margin-bottom: 20px;
  }

  .omie-account-select {
    margin-bottom: 8px;
  }

  .add-new-line {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 25px;

    &--paragraph {
      font-size: 14px;
      background-color: #F1F5FC;
      width: 100%;
      padding: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: flex-start;
    }

    button {
      border: 1px dashed #135AC0;
      color: #135AC0;
      font-weight: 500;
      font-size: 14px;
      padding: 16px;
      width: 100%;
      border-radius: 5px;
    }
  }
}
