.products-dialog {
  md-dialog-content {
    padding: 0;
    height: 100%;
  }

  md-tabs {
    padding: 0 !important;
    margin: 0 !important;

    &.md-dynamic-height md-tab-content.md-active {
      padding: 0 !important;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &__general-tab {
    padding: 0 !important;
    margin: 0 !important;

    &__content {
      padding: 20px 24px !important;
      height: 100% !important;
    }
  }

  &__footer {
    position: fixed !important;
  }
}
