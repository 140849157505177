.deadline-contracts-dialog {
  md-tab-content {
    padding: 0 !important;
    overflow-y: hidden;
    height: 100%;
  }

  md-switch {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .deadline-times {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  .deadline-top-row {
    margin-bottom: 20px;
  }

  .hours-message {
    font-size: smaller;
    color: red;
    margin-left: 50px;
    position: absolute;
    margin-top: 20px;
  }

  .fc-warning-container {
    padding: 0 20px !important;
  }

  .warning-icon {
    color: #135ac0;
  }

  .subtitle {
    color: #2e2e2e;
    font-weight: 500;
    font-size: 16px;
    margin: 0 !important;
    padding: 30px 0;
  }

  fieldset {
    border: 1.02618px solid #e0e0e0;
    border-radius: 4.10471px;
    margin-bottom: 12px;
    width: 100%;
  }

  .content {
    display: grid;
    border: 0;
    margin: 0;
    padding: 0 20px;

    h3 {
      margin-bottom: 0;
    }

    md-input-container {
      margin: 0 !important;
    }

    textarea {
      resize: none !important;
    }
  }

  .consider-holiday {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .office-hours-activated {
    height: 230px !important;
  }

  .office-hours {
    height: 60px;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .times {
    min-height: 60px;
    max-height: 250px;

    &__group {
      height: 60px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start
    }

    &__input--group {
      .checkbox-days {
        input[type="checkbox"] {
          margin: 0;
          width: 12.12px;
        }

        span {
          font-size: 14.0683px;
          margin-left: 10.29px;
        }

        margin-bottom: 5px;
      }

      .fc-input {
        margin-bottom: 10px;
        width: 100%;

        input {
          text-align: center;
        }
      }
    }
  }

  .h-flex {
    height: 100%;
  }
}
