.customers-dialog__locations-tab-edit {
  &__main-container {
    display: grid;
    grid-gap: 25px;
    padding: 14px 22px;
    margin: 10px 0 20px 0;

    &__search-bar {
      margin-bottom: 20px;

      .fc-input {
        width: 95%;

        &__input--search {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        &__button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
          width: 42px;

          md-icon {
            color: #135ac0;
          }
        }
      }

      &__hint {
        color: #7f7f7f;
        font-size: 12px;
      }
    }

    .height-48 {
      height: 48px;
    }

    .customer-locations {
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .md-button {
          padding: 0px 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-right: 0px;
          flex: 22%;
          height: 33px;
          border-radius: 5px;

          md-icon {
            margin: 0px 0px 2px 0px;
          }
        }
      }

      &__wrapper,
      &__loading,
      &__not-found {
        position: relative;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 20px;
        color: #757575;
        font-size: 13px;
        font-weight: 500;
        border: thin solid rgba(0, 0, 0, 0.12);
        border-bottom: none;
        border-radius: 6px 6px 0 0;
      }

      &__wrapper {
        border: thin solid rgba(0, 0, 0, 0.12);
      }

      &__loading,
      &__not-found {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: thin solid rgba(0, 0, 0, 0.12);
        border-bottom: none;
        border-top: none;


        md-icon {
          margin: initial;
        }
      }

      &__location {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 6px;
        border-bottom: thin solid rgba(0, 0, 0, 0.12);

        &.without-border {
          border-bottom: none !important;
        }

        &__info {
          padding: 12px;
          display: flex;
          align-items: center;

          md-icon {
            margin: 0px 14px 0px 0px;

            &.primary-icon  {
              color: #135ac0 !important;
            }
          }

          &__name {
            display: flex;
            flex-direction: column;
            margin: 0;
            font-size: 14px;
            font-weight: 500;

            &__primary {
              color: rgb(9, 87, 195);
              transition: color 250ms;
            }

            &__address {
              margin: 2px 0 0 0;
              max-width: 100%;
              color: #757575;
              font-size: 13px;
              font-weight: 500;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              max-width: 700px;
            }

            &--archived {
              font-style: italic;
              text-decoration: line-through;
              color: #757575;
            }
          }
        }

        &__duplicated {
          margin-top: 5px;
          color: #d50000;
        }

        &__actions {
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 10;
          transform: translateY(-50%);
        }

        &--primary {
          .customer-locations__location__name span {
            color: rgba(9, 87, 195, 1);
          }
        }
      }

      &__pagination {
        margin: 0;
        padding: 8px 16px;
        grid-gap: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: thin solid rgba(0, 0, 0, 0.12);
        border-radius: 0 0 6px 6px;
        border-top: none;

        &__indicator {
          padding: 0 8px;
          font-size: 13px;
          color: #757575;
          font-weight: 500;
        }

        &__button {
          padding: 9px 12px;
          user-select: none;
          box-sizing: border-box;
          display: inline-block;
          min-width: 1.5em;
          background: #FFFFFF;
          color: rgba(0, 0, 0, 0.87);
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-radius: 2px;
          font-size: 13px;
          text-align: center;
          text-decoration: none;
          cursor: pointer;

          &:hover {
            box-shadow: inset 999em 0 0 0 rgba(0, 0, 0, 0.05);
            background: none;
          }

          &:disabled {
            color: rgba(0, 0, 0, 0.54);
            border: 1px solid transparent;
            border-color: rgba(0, 0, 0, 0.12);
            background: #FFFFFF;
            box-shadow: none;
            cursor: default;
          }
        }
      }
    }

    .customer-location {
      position: relative;

      &__form {
        padding: 16px 16px 0px 16px;
        position: relative;
        min-height: 300px;
        border: thin solid rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        overflow: hidden;

        &__map {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
          height: 100%;
          width: 100%;
          background-color: orange;
          transform: translateY(101%);
          transition: transform 250ms;

          &--active {
            transform: translateY(0%);
          }
        }
      }

      &__buttons {
        margin-top: 5px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        &__wrapper {
          display: flex;
          align-items: center;
        }

        .md-button {
          &:first-child {
            margin-left: 0px;
          }

          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }

    md-checkbox.contact-checkbox-primary {
      margin: 5px 0 5px !important;
    }

    md-checkbox.contact-checkbox-primary,
    .md-label {
      margin-top: 3px !important;
    }
  }
  .customer-locations__location__info{
    max-width: 92%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    text-align: justify;
  }
}
