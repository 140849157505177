.filter-employees {
    md-list {
        md-list-item {
            .w-100-percent {
                width: 100%;
            }
        }
    }
}

#exports {
    a.export-link {
        padding: inherit;
    }

    a.link-expired {
        color: #939393;
    }

    .td-status {
        width: 50px !important;
    }
}
