.material-name{
    word-break: break-all;
}

.migration-container {
  position: relative;
  width: 100%;

  &__warning {
    display: block;
    padding: 18px;
    background: #FFFFFF;
    border: 1px solid #D4DEF1;
    border-radius: 8px;
    text-align: justify;
    height: fit-content;
    width: 100%;
    margin-bottom: 10px;

    &__button-go-to-products {
      display: flex;
      justify-content: flex-end;

      a {
        border: 1px solid #D4DEF1;
        padding: 8px 16px;
        border-radius: 4px;
        color: #135AC0;
        font-weight: 500;

        &:link {
          color: #135AC0;
        }
      }
    }

    &__paragraph {
      height: 60vh;

      &__done {
        height: auto !important;
      }

      span {
        font-size: 15px;
        color: #135AC0;
        font-weight: 500;
      }

      p {
        padding: 0px 30px;
        font-weight: 400;
        font-size: 15px;
        color: #262626;
        position: relative;
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      width: 100%;
      margin-top: 10px;
      z-index: 999;
      position: relative;

      &__minimize,&__expand {
        color: #135AC0;
        border: 0;
        background-color: transparent;
        font-weight: 500;
        font-size: 15px;
      }
      &__migrate {
        background-color: #FC3868;
        border-radius: 4px;
        border: 0;
        padding: 12px 16px;
        color: #ffffff;
        font-weight: 500;
      }
    }
  }
}

.disabled-checker {
  opacity: 0.4 !important;
  pointer-events: none;
}

.discontinued {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  padding: 4px 9px;
  background: #FC3868;
  color: #ffffff;
  margin-left: 10px;
  border-radius: 100px;
}

.gradient::after {
  position: absolute;
  bottom: 0;
  height: 190px;
  width: 95%;
  content: "";
  background: linear-gradient(to top, transparent 40%, rgba(255,255,255, 1) 30%,rgba(255,255,255, 0) 80%);
  z-index: 1;
  pointer-events: none;
}

.header {
  padding-bottom: 0px !important;
}

.content-card {
  position: relative;
}
