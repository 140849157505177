.fc-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  outline: 0;
  background: #595656;
  opacity: 0.5;

  &--invisible {
    opacity: 0;
  }
}