.attachments-tab {
  padding: 25px 24px;

  &__upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    border: 2px dashed #D7D7D7;
    padding: 16px;
    border-radius: 8px;
    background-color: #ffffff;

    &__drag-message {
      font-weight: 500;
      font-size: 15px;
      color: #135AC0;
    }

    &__drag-limit {
      font-weight: 400;
      font-size: 15px;
      color: #262626;
    }

    &__action-buttons {
      display: flex;

      &__upload-button,&__send-button {
        border-radius: 5px;
        height: 42px;
        font-size: 15px;
        width: 165px;

        &:disabled {
          background-color: transparent !important;
          color: #707070 !important;
        }
      }

      &__upload-button {
        border: #D6D6D6 1px solid;
        background-color: #ffffff;
        padding-left: 16px;
        padding-right: 16px;
        color: #4E4E4E;
        font-weight: 400;
        display: flex;
        gap: 10px;
        transition: ease-in-out 0.2s;
        align-items: center;

        &:hover {
          background-color: #F2F2F2;
        }
      }

      &__send-button {
        font-weight: 500;
        color: #ffffff;
        border: 0;
        background-color: #135AC0;
        padding: 10px 20px;
        position: absolute;
        right: 0;
        margin-right: 42px;
        align-self: center;
      }
    }
  }

  &__limit-reached {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 26px;
    border: 2px dashed #D7D7D7;
    border-radius: 8px;
    padding: 55px;

    font-weight: 400;
    font-size: 15px;
    color: #757575;
  }

  &__attachments-container {
    display: flex;
    gap: 24px;
    flex-direction: column;

    .only-one-attachment {
      margin-left: -30px;
    }

    &__single {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;

      &__arrows {
        button {
          border: none;
          background-color: transparent;

          &:hover {
            background-color: #F2F2F2;
            border-radius: 50%;
          }
        }

        display: flex;
        flex-direction: column;
        width: 14px;
        gap: 17px;
      }

      &__preview {
        width: 153px;
        height: 113px;
        border-radius: 3px;
        background-repeat: no-repeat;
        background-size: cover;

        &--file {
          width: 153px;
          height: 113px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #D4DEF1;
          border-radius: 3px;

          md-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 50px;
            color: #757575;
          }
        }
      }

      &__information {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        gap: 4px;

        &__title {
          font-weight: 500;
          font-size: 15px;
          color: #000000;
          text-overflow: ellipsis;
          overflow: hidden;
          line-break: auto;
          width: 400px;
          overflow-wrap: break-word;
        }

        &__date {
          font-weight: 400;
          font-size: 13px;
          color: #757575;
        }

        &__rename-button {
          background-color: transparent;
          border: none;
          font-weight: 500;
          font-size: 15px;
          color: #135AC0;
          text-align: left;
          width: fit-content;
        }

        &__archived-message {
          margin-top: 3px;
          display: flex;
          gap: 11px;
          align-items: center;
          font-weight: 500;
          font-size: 15px;
          color: #757575;
        }
      }

      &__action-buttons {
        position: absolute;
        right: 0;
        margin-right: 25px;
        display: flex;
        align-items: center;

        .md-button {
          border: none;
          background-color: transparent;
          min-width: 14px;
          border-radius: 50%;
          margin: 0;
          padding: 4px 12px;

          &:hover {
            background-color: #F2F2F2;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &__title {
    color: #262626;
    font-weight: 500;
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 25px;
    display: block;
  }

  &__no-attachments {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border: 1px solid #D4DEF1;
    border-radius: 8px;
    padding: 32px;
  }

  &__loading-attachments {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.has-images {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  height: 100%;

  &__drag-message {
    font-weight: 400;
    font-size: 15px;
    color: #757575;
  }

  &__files-preview {
    margin: 0px;
    padding: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;

    &__loading {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      padding: 24px 82px;
    }

    &__thumbnail {
      width: 86px;
      height: 65px;
      border-radius: 3px;
      border: thin solid #D7D7D7;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__remove {
      cursor: pointer;
      position: absolute;
      margin-left: 74px;
      margin-top: -6px;
    }
  }
}
