$quotation-status-colors: (
  approved: #12B886,
  canceled: #b197fc,
  expired: #FF7A00,
  pending: #0054ff,
  refused: #FF3465,
);

.fc-datepicker {
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999 !important;
}

.quotation-description-column {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
  text-overflow: ellipsis;
}

.table-text {
  word-break: break-word;
}

@each $status, $color in $quotation-status-colors {
  .quotation-status-#{$status} {
    &-bg {
      background-color: $color !important;
      color: #ffffff !important;

      .icon {
        color: #ffffff;
      }
    }

    &-fg {
      color: $color !important;
    }
  }
}
