.materials {
  &__migration {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #D4DEF1;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 13px;

    a {
      color: #135AC0;
      font-weight: 500;
    }
  }

  .button-add-material {
    margin-top: 17px;
  }

  .order-material {
    position: relative;

    &-name {
      word-break: break-all;
      padding-right: 3rem;
    }

    &-quantity {
      z-index: 2;
    }
  }

  .bottom-0 {
    bottom: 0 !important;
  }


  .remove-material {
    bottom: 0;
    position: absolute;
    right: 0;
    width: 100%;
    height: 95px;
    z-index: 1;

    &.md-is-open {
      z-index: 20;
    }

    &.md-fab-toolbar {
      .md-toolbar-tools {
        width: 100%;
        height: 95px;
        max-height: 95px;
      }

      .md-fab-toolbar-wrapper {
        height: 95px;
      }
    }
  }

}
