.fc-select {
  position: relative;

  &__select {
    position: relative;
    height: max-content;
    z-index: 2;
    background: none;

    &--without-border {
      .fc-select__select.with-divider::after {
        border-left: none;
      }
    }

    &--change-border-color {
      .fc-input__input {
        border-color: #D4DEF1 !important;
      }

      &.with-divider::after {
        border-color: #D4DEF1 !important;
      }
    }

    &.with-divider::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      content: '';
      display: block;
      height: 48px;
      width: 48px;
      cursor: pointer;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      background: white url('../assets/icons/icon-menu-down.svg') no-repeat;
      background-position: center;
      pointer-events: none;
      transition: border-color 250ms;
    }

    &.arrow {
      background: none;
    }

    &.disabled {
      input {
        cursor: default;
      }

      .fc-input__label {
        color: #9E9E9E !important;
      }

      &.with-divider::after {
        filter: grayscale(1);
      }
    }

    &:not(.disabled):hover {
      &.with-divider::after {
        border-left: 1.6px solid #777777;
      }
    }

    &.invalid {
      &.with-divider::after {
        border-color: #e20909 !important;
      }
    }

    input {
      cursor: pointer;
    }
  }

  .icon-arrow {
    top: 12px;
    right: 12px;
    z-index: -1;
    position: absolute;

    &.disabled {
      color: #9E9E9E !important;
    }
  }

  &__dropdown {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 6px;
    left: -2px;
    background-color: white;
    border: transparent;
    border-radius: 4px !important;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.439) !important;
    width: 102%;
    max-height: 172px;
    overflow-y: scroll;
    z-index: 99;

    &__searcher {
      width: 100%;
      font-size: 14px;
      padding: 12px 14px !important;
      border: transparent;
      border-bottom: 1px solid #e0e0e0 !important;
    }

    &__item {
      padding: 12px 14px;
      list-style-type: none;
      color: #3d3d3d;
      font-size: 14px;
      transition: color 200ms, background-color 200ms;
      cursor: pointer;

      &.activated {
        background-color: #e0e0e0;
        color: #000;
      }

      &:hover {
        background-color: #e0e0e0;
        color: #000;
      }
    }
  }

  &__select {
    &:not(.disabled):hover {
      &.with-divider::after {
        border-left: none;
      }
    }

    &.with-divider::after {
      filter: grayscale(1);
      border: none;
      background-color: transparent;
    }
  }
}