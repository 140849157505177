.notifications-sidenav {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &-header {
        .menu-title {
            font-size: 16px;
        }
    }

    &-body {
        overflow-y: auto;
        width: 100%;
        height: 90%;

        .notifications {
            width: 100%;
            height: 100%;
            background-color: #fff;

            .contact-item {
                margin: 0;
            }

            .unread {
                background-color: #eee !important;

                .md-list-item-text {
                    h4 {
                        font-weight: 600 !important;
                    }
                }
            }
        }

        .no-notifications {
            width: 100%;
            height: 100%;
            padding: 20px;

            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                md-icon {
                    width: 38px;
                    height: 38px;
                    font-size: 38px;
                }

                h5 {
                    margin: 8px 0;
                    color: gray;
                }
            }
        }

        .avatar-wrapper {
            position: relative;

            .icon {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                bottom: 0px;
                right: 8px;
                color: white;
                border-radius: 50%;

              &__pendency {
                svg {
                  width: 16px;
                  height: 16px;
                }

                &--created {
                  background-color: #0055ff;
                }

                &--transferred {
                  background-color: #e2772e;
                }

                &--replied {
                  background-color: #439be6;
                }

                &--completed {
                  background-color: #2fb688;
                }
              }
            }

            .ticket-created {
                background-color: #0055ff;
            }

            .commented {
                background-color: #858fa4;
            }

            .task-rated {
                background-color: #f9bb42;
            }

            .task-reported {
                background-color: #ff3365;
            }

            .quotation-approved {
                background-color: #12b886;
            }

            .quotation-refused {
                background-color: #b197fc;
            }

            .deadline-at-risk {
                background-color: #EE7210;
            }

            .deadline-out-of-schedule {
                background-color: #FC3868;
            }
        }
    }

    &-footer {
        height: 10%;
        width: 100%;
        padding: 24px;
        border-top: solid 1px #d3ddf2;
        background-color: #fff;

        .go-to-notifications-center {
            color: #0055ff;
            font-size: 14px;
            font-weight: 500;
            border: none;
            text-decoration: none;
            background-color: none;
        }
    }
}
