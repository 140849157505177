.fc-fixed-tooltip {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 120%;
  right: 3.2%;
  background: #fc3868;
  padding: 16px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  pointer-events: none;
  opacity: 0;
  animation:
    fadeIn 1s ease-in forwards,
    appearingTooltip 3s ease-out forwards;

  &--hidden {
    display: none;
  }

  &__triangle {
    position: absolute;
    top: -19%;
    height: 15px;
    width: 15px;
    background: #fc3868;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes appearingTooltip {
  0% {
    opacity: 0;
  }
  20%, 90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
