fc-card {
  margin: 0px 20px 20px 0px;

  .fc-card {
    width: 225px;
    height: 300px;
    border: thin solid #d3ddf2;
    border-radius: 10px;

    &.enabled {
      border-color: rgb(9,87,195);
    }

    &__badges {
      width: 100%;
      padding: 0px 10px;
      height: 12%;

      .badge {
        padding: 0px 12px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255,255,255,0.87);

        &.enabled {
          background-color: rgb(9,87,195);
        }

        &.new {
          background-color: #ff3365;
        }
      }
    }

    &__content {
      padding: 0px 15px 10px 15px;
      height: 88%;

      &__icon {
        margin: 10px 0px;
        height: 48px;

        svg {
          height: 48px;
          width: 48px;
        }

        .fill-color {
          fill: #d3ddf2;
        }

        &.enabled {

          .fill-color {
            fill: rgb(9,87,195) !important;
          }
        }
      }

      &__title {
        margin: 10px 0px 0px 0px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
      }

      &__description {
        margin: 10px 0px;
        font-size: 14px;
        text-align: center;
      }

      &__button {
        width: 90%;
        height: 40px;
        font-weight: 500;
        box-shadow: none !important;

        &.enabled {
          border: thin solid #d3ddf2;
          background-color: rgba(255,255,255,0.87) !important;
          color: rgb(9,87,195) !important;
        }

        &.disabled {
          background-color: rgb(9,87,195) !important;
          color: rgba(255,255,255,0.87) !important;
        }
      }
    }
  }
}
