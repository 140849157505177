.plan-type-dialog {

  .mt-25n {
    margin-top: -25px;
  }

  .mt-35n {
    margin-top: -35px;
  }

  .fs-17 {
    font-size: 17px;
  }

  .ml-13 {
    margin-left: 13px;
  }

  .ml-10n {
    margin-left: -10px;
  }

  .ml-3n {
    margin-left: -3px;
  }

  .mt-20 {
    margin-top: 20px !important;
  }

  .mb-15n {
    margin-top: -15px;
  }

  .color-grey {
    color: #bfbfbf;
  }

  .questions-count {
    color: #858585 !important;
  }

  .color-red {
    color: #D32F2F;
  }

  .color-green {
    color: #388E3C;
  }

  md-input-container {
    input.color-black {
      color: black !important;
    }
  }

  .tab-forms {
    .btn-remove-form {
      height: 20px;
      margin-top: 25px;
      cursor: pointer;
    }
  }
}