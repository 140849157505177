.omie-errors-dialog {
    font-size: 1.6rem;
    width: 500px !important;
    height: auto !important;
    .bold {
        font-weight: bold;
    }

    .info-box {
        padding: .5rem;
        background-color: lightblue;
        border: 1px thin blue;
        border-radius: 10px;
        font-size: 1.2rem;
    }

    md-toolbar {
        background-color: white !important;
        margin-top: 10px !important;

        div {
            padding: 32px;

            span {
            color: black;
            font-size: 16px;
            font-weight: 500 !important;
            }
        }

    }

    md-dialog-content {
        padding: 0px 32px !important;

        div {
            span {
                font-size: 14px;
            }
        }
    }

    md-dialog-actions {
        padding: 32px !important;
        border: 0 !important;
        background-color: white !important;
        justify-content: right !important;

        .md-accent {
            border-radius: 5px !important;
        }
    }
}
