.sidebar-dialog {
  position: fixed !important;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100vh;
  max-height: 100vh !important;
  border-radius: 0 !important;
  animation: slide-right-sidebar 0.4s;
  overflow-y: hidden;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #0957c3 !important;

    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__title {
      padding-left: 20px;

      span {
        font-size: 16px;
        color: rgba(255,255,255,0.87);
      }

      md-menu {
        margin-left: -14px;
      }
    }

    &__actions {

      button {
        color: rgba(255,255,255,0.87);
      }
    }
  }

  &__hint {
    height: 60px;
    width: 100%;

    top: 0;
    left: 0;

    background-color: #f1f5fc;
    font-size: 14px;

    display: flex;
    align-items: center;

    box-sizing: content-box;
    margin-top: -22px;
    margin-left: -22px;
    margin-bottom: 22px;
    padding: 0 22px;

    md-icon {
      margin-right: 12px;
      color: rgb(9,87,195);
    }
  }

  &__input-group {
    &[layout="row"] {
      grid-gap: 12px;
    }

    &[layout="column"] {
      grid-gap: 28px;
    }
  }

  md-dialog-content {
    width: 100% !important;
    height: 100vh !important;
    position: relative;
    overflow: hidden;

    md-tabs {
      md-tab-content {
        padding: 22px;
      }
    }
  }

  &--no-padding {
    md-dialog-content {
      md-tabs {
        md-tab-content {
          padding-bottom: 0;
        }
      }
    }
  }

  md-dialog-actions {
    bottom: 0;
    left: 0;
    position: relative;
    width: 100%;
    background-color: rgb(245,245,245);
  }

  &--small {
    max-width: 520px;
  }

  &--medium {
    max-width: 690px;
  }

  &--large {
    max-width: 860px;
  }

  @keyframes slide-right-sidebar {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    65% {
      opacity: 1;
      transform: translateX(0);
    }

    100% {
      transform: translateX(0);
    }
  }
}
