.fc-tutorial-dialog {
  &__description {
    font-weight: 400;
    font-size: 14px;
    color: #262626;
  }

  &__content {
    margin-top: 20px;
  }
}
