.fc-search {
  position: relative;
  z-index: 99;
  background-color: white;

  .fc-input__input--with-prefix__fc-search:not(.disabled):not(.invalid) {
    padding-left: 5.5% !important;

    &:hover {
      padding-left: 5.5% !important;
    }

    &:not(:placeholder-shown),
    &:focus {
      padding-left: 5.7% !important;

      & + .fc-input__label {
        left: 12px !important;
      }
    }
  }

  .fc-input__input--with-prefix__label__fc-search:not(.disabled):not(.invalid) {
    left: 5.5% !important;
  }

  &__search {
    position: relative;
    height: max-content;

    &__search-icon {
      position: absolute;
      top: 25%;
      left: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 14px;
        width: 14px;
      }
    }
  }

  &__dropdown {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-top: none;
    border-radius: 4px;
    width: 100%;
    max-height: 129px;

    &__item {
      padding: 12px 14px;
      list-style-type: none;
      color: #3d3d3d;
      font-size: 14px;
      transition: color 250ms, background-color 250ms;
      cursor: pointer;

      &.activated {
        background-color: #e0e0e0;
        color: #000;
      }

      &:hover {
        background-color: #e0e0e0;
        color: #000;
      }
    }
  }
}
