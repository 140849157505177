.customer-dialog__attachments-tab {
  padding: 22px;

  .drop-box {
    min-height: 30px;
    height: auto;
    padding: 20px;
    margin: 54px 0 16px 0;
    background-color: #fff !important;
    border: 2px dashed #DDD;
    text-align: center;
    color: #acacac;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      border: 2px dashed rgb(196, 196, 196);
      color: #6e6e6e;

      transition: all 0.3s ease;
    }

    .name-file-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100px;
      font-size: 10px;
      text-overflow: ellipsis;
    }
  }

  .wrapper-images-upload {
    flex-wrap: wrap;
    max-width: 100%;
    overflow-x: hidden;
  
    div {
      align-items: flex-end;
      justify-content: flex-start;
      height: 70px;
      display: flex;
      position: relative;
      width: 120px;
      margin-bottom: 10px;
      margin-left: 10px;
  
      .file-image-thumbnail{
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
  
      &:hover {
        cursor: pointer;
      }
  
      md-icon {
        position: absolute;
        right: 8px;
        top: 2px;
        z-index: 99;
  
        &:hover {
          svg path {
            fill: #bd0e0e;
          }
        }
      }
  
      img {
        border: thin solid #d5d5d5;
        border-radius: 5px;
        height: 60px;
        width: 100px;
        background-color: gray;
        object-fit: cover;
      }
    }
  }

  md-divider {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 16px;
  }

  .attachments-limit {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-top: 48px;
  }

  .delete-icon {
    color: #db0b0b;

    &:hover {
      color: #b30c0c;
    }
  }

  .attachments{
    margin-bottom: 62px !important;

    .attachment-name {
      font-size: 16px;
      color: #000;
    }

    &-content-metadata {
      margin-top: 12px !important;
      margin-bottom: 12px !important;
    }
  }

  .mt-22 {
    margin-top: 22px;
  }

  .action-buttons {
    margin-bottom: 62px;
    margin-left: 100%;

    &__button {
      width: 35px;
      min-width: 35px;
      max-width: 35px;
      margin: 0;
    }

  }

  .customer-attachment-delete-dialog {
    width: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    z-index: 10;
    background-color: #fafafa;
    white-space: nowrap;
    transition: width 0.5s ease-in-out;

    &.visible {
      border-color: #d3ddf2;
      width: 75%;
      height: 130px;
      border: 1px solid transparent;
      border-radius: 3px;
      @media (max-width: 925px) {
        width: 100%;
      }
    }
  }
}
