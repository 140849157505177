#fc-spreadsheet-reader {

    .drop-box {
        border-width: 2px;

        &[disabled="disabled"] {
            cursor: not-allowed !important;
        }
    }

    .file-loaded {
        border-radius: 3px;
        border-style: solid;
        border-color: #049be5;

        p {
            margin-bottom: 0;
            font-size: 15px;
            color: #049be5;
        }

        small {
            margin-top: 10px;
        }
    }
}